import React from "react";
import PureComponent from "../../pure";
import moment from "moment";
import Button from "../../formElements/Button";
import Select from '../../formElements/Select';
import TextField from "@material-ui/core/TextField";
import AcmDate from "../../formElements/AcmDate";

import  sAction  from "sAction";
import Relate from "../../formElements/Relate";

const InputT = React.forwardRef((props, ref) => (
    <TextField inputRef={ref} {...props} />
  ));

export default class CloseOpportunityPopup extends PureComponent {

    defaultSalesStage = "Closed Won";
    closeStates = ["Closed Won", "Closed Lost", "Closed canceled client", "Closed canceled me"];
    stageOptions = [];
    duvodOptions = [];

    constructor(){
        super();

        this.closeStates.forEach(state => {
            this.stageOptions.push({value : state, label : sAction.app_strings.sales_stage_dom[state]});
        });

        sAction.app_strings.duvod_uzavreni_list.forEachObject((lbl, key) => {
            this.duvodOptions.push({value: key, label: lbl});
        });
        
        this.state = {
            sales_stage : this.defaultSalesStage,
            duvod_uzavreni_c : "",
            date_closed: new Date(),
            selected_quote_id: "",
            selected_quote_name: ""
        }
    }

    handleCloseOpp(){
        const date = moment(this.state.date_closed).format("YYYY-MM-DD");
        var params = this.props.data.get("params").toJS();
        
        sAction.dsClear();
        sAction.dsAdd("set", params.prefix+"/fields/sales_stage/value", this.state.sales_stage);
        sAction.dsAdd("set", params.prefix+"/changes/fields/sales_stage", this.state.sales_stage);
        
        if(this.state.sales_stage != "Closed Won"){
            sAction.dsAdd("set", params.prefix+"/fields/duvod_uzavreni_c/value", this.state.duvod_uzavreni_c);
            sAction.dsAdd("set", params.prefix+"/changes/fields/duvod_uzavreni_c", this.state.duvod_uzavreni_c);
        }
        
        sAction.dsAdd("set", params.prefix+"/fields/date_closed/value", date);
        sAction.dsAdd("set", params.prefix+"/changes/fields/date_closed", date);
        sAction.dsProcess();
        params.canSave = true;
        sAction.saveRecord(params, () => {
            sAction.popupHide();
            sAction.unLoad();
        });

        //na vybrané Nabídce přiřazené k Příležitosti se změní Stav = podle Stavu Příležitosti.
        // Na ostatních Nabídkách, které mají Stav = Nová, Připravuje se, Aktivní se uzavřou Stavem = Zrušeno námi.
        const data = {
            opportunity_id: params.id,
            quote_id: this.state.selected_quote_id,
            sales_stage: this.state.sales_stage
        }
        sAction.rest.post("quotesMassStatusChange", data, returnData => {

        }, false);

    }

    handleQuoteSelection = (data) => {
        this.setState({ selected_quote_id: data.id, selected_quote_name: data.name });
    }

    render(){

        const data = this.props.data;
        var duvodLbl = null;
        var duvodField = null;
        var nabidkaLbl = null;
        var nabidkaField = null;
        
        if(this.state.sales_stage == "Closed Won") {
            //vyber nabidky, ktera se taky uzavre stejnym stavem - ostatni zruseno - nami
            nabidkaLbl = <div>{sAction.translate("LBL_QUOTE", "Quotes")}</div>;

            nabidkaField = <Relate
              key={this.state.selected_quote_id}
              newRecord={false}
              data={{
                  value: this.state.selected_quote_name,
              }}
              module={"Quotes"}
              callback={this.handleQuoteSelection}
              defaultFilter={{
                  subpanel: {
                      operandType: "relate",
                      type: "eq",
                      module: "Opportunities",
                      relName: "quotes",
                      relationship: ["opportunities"],
                      name: "opportunity_name",
                      parentId: data.getIn(["params", "id"])
                  }
              }}
              containerClassName={"closeOppRelate"}
            />;

        } else {
            //duvod prohry
            duvodLbl = <div>{sAction.translate("LBL_DUVOD_UZAVRENI", "Opportunities")}</div>;

            duvodField = <Select 
                open={false} 
                name="duvod_uzavreni_c"
                options={this.duvodOptions} 
                defaultValue=""
                onChange={(ev) => this.setState({duvod_uzavreni_c : ev.target.value})} 
            />;
        }

        return <div>
            <div className="viewActionPanelButtons">
                <Button className="hoverGreen" onClick={() => this.handleCloseOpp()}>
                    <div className={"icon-saveIcon calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CLOSE_OPPORTUNITY")}
                </Button>
                <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                    <div className={"icon-close calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CANCEL_ACTION")}
                </Button>
            </div>
            <div className="CloseOppContent">
                <div className="closeOppColumn closeOppColumnLbl">
                    <div>{sAction.translate("LBL_SALES_STAGE", "Opportunities")}</div>
                    <div>{sAction.translate("LBL_DATE_CLOSED", "Opportunities")}</div>
                    {duvodLbl}
                    {nabidkaLbl}
                </div>
                <div className="closeOppColumn closeOppColumnValue">
                <Select 
                    open={false} 
                    name="sales_stage"
                    options={this.stageOptions} 
                    defaultValue={this.defaultSalesStage}
                    onChange={(ev) => this.setState({sales_stage : ev.target.value})} 
                />
                <AcmDate
                    value={this.state.date_closed}
                    onChange={date => this.setState({ date_closed: date})}
                />
                {duvodField}
                {nabidkaField}
                </div>
            </div>
        </div>;
    }
}

