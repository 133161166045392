import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';
import sAction from 'sAction';
import ResetPassRequest from './ResetPassRequest';
import LoginForm from './LoginForm';
import ResetPass from './ResetPass';
import TwoFactorForm from './TwoFactorForm';

export default class Login extends PureComponent {
    constructor() {
        super();
        this.state = {
            backgroundImage: 'url("' + this.getImageWithFullPath() + '")',
            url: window.location.hash,
            prefilledUsername: '',
        };
        document.title = 'SPS';
    }

    /**
     * get random number for background image
     * @returns {number} 1 to 13
     */
    getBackgroundFileNumber() {
        return Math.floor(Math.random() * 13) + 1; // result 1 to 13
    }

    /**
     * get background image path
     * @returns {string} image path
     */
    getImageWithFullPath() {
        const imageFile = this.getBackgroundFileNumber() + '.jpg';

        return 'img/login-background/' + imageFile;
    }

    /**
     * go to login page
     */
    goToLogin() {
        window.location.hash = '#login';
        this.setState({
            url: '#login',
        });
    }

    /**
     * go to specified sub page
     * @param {string} subPage  sub page to switch to
     * @param {object} newState state changes
     */
    goToSubPage(subPage, newState = {}) {
        window.location.hash = `#login/${subPage}`;
        this.setState({
            url: `#login/${subPage}`,
            ...newState,
        });
    }

    render() {
        const hasBgImages = sAction.param.showLoginBackgroundImages;
        let backgroundImage;
        if (hasBgImages) {
            backgroundImage = (
                <div
                    id={'loginPageBackground'}
                    style={{backgroundImage: this.state.backgroundImage}}
                />
            );
        } else {
            backgroundImage = '';
        }

        let loginWindow;
        if (window.location.hash === '#login/twoFactor' && this.state.twoFactor) {
            loginWindow = <TwoFactorForm
                type={this.state.twoFactor}
                goToLogin={() => this.goToLogin()}
                token={this.state.token}
                tokenValidity={this.state.tokenValidity}
            />;
        } else if (
            window.location.hash === '#login/resetPassword' ||
            window.location.hash === '#login/resetPassword/'
        ) {
            loginWindow = <ResetPassRequest
                goToLogin={() => this.goToLogin()}
                
                prefilledUsername={this.state.prefilledUsername}
            />;
        } else if (window.location.hash.includes('#login/resetPassword/')) {
            const urlArray = window.location.hash.split('/');
            if (urlArray[1] === 'resetPassword' && urlArray[2]) {
                loginWindow = <ResetPass goToLogin={() => this.goToLogin()} />;
            }
        } else {
            loginWindow = (
                <LoginForm
                    logo={this.props.system?.logo}
                    switchCallback={(page, params) => this.goToSubPage(page, params)}
                    redirect={this.props.redirect}
                />
            );
        }

        const loginMessage = sAction.param.loginMessage;

        return (
            <div>
                {backgroundImage}
                <div id="loginPage">
                    <div id="loginContentContainer">
                        <div
                            id="loginContainer"
                            style={hasBgImages ? {boxShadow: 'none'} : {}}
                        >
                            <div className="loginContent">
                                {loginWindow}
                                {loginMessage && (
                                    <div className={'flexCenter loginMessage' + (loginMessage.color ?? 'blue')}>
                                        {loginMessage.type === 'href' ? (
                                            <a href={loginMessage.link ?? ''}>
                                                {loginMessage.text ?? ''}
                                            </a>
                                        ) : (
                                            loginMessage.text ?? ''
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="loginPageCreatedBy">
                            <div className="version">v{this.props.version}</div>
                            <div className="createdBy">
                                {sAction.translate('LBL_LOGIN_CREATED_BY')}
                                <img src={sAction.param.acmarkLogo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    system: PropTypes.object.isRequired,
    version: PropTypes.string.isRequired,
    redirect: PropTypes.string,
};
