import React from "react";
import PureComponent from "../../pure";
import Button from "../../formElements/Button";
import Relate from '../../formElements/Relate'

import sAction from "sAction";

export default class ItemRegReleasePopup extends PureComponent {

    constructor(props){
        super(props);

        const data = props.data.get('data');

        const account = data.get("account");
        const contact = data.get("contact");
        const acm_departments = data.get("acm_departments");
        const acm_storages =  data.get("acm_storages");

        let accountSt = {
            value: "",
            id_value: "",
        }
        let contactSt = {
            value: "",
            id_value: "",
        }
        let acm_departmentsSt = {
            value: "",
            id_value: "",
        }

        let acm_storagesSt = {
            value: "",
            id_value: "",
        }

        if(account){
            accountSt = {value: account.get("value"), id_value: account.get("id_value")}
        }
        if(contact){
            contactSt = {value: contact.get("value"), id_value: contact.get("id_value")}
        }
        if(acm_departments){
            acm_departmentsSt = {value: acm_departments.get("value"), id_value: acm_departments.get("id_value")}
        }
        if(acm_storages){
            acm_storagesSt = {value: acm_storages.get("value"), id_value: acm_storages.get("id_value")}
        }

        acm_storagesSt.searchFields = ["name", "kod_stredisko"];
        accountSt.searchFields = ["name", "kod_stredisko"];
        contactSt.searchFields = ["name", "kod_stredisko"];


        this.state = {
            kod_stredisko : "",
            account : accountSt,
            contact : contactSt,
            acm_departments : acm_departmentsSt,
            acm_storages : acm_storagesSt,
            accStatusClass : "",
            centralStorage : data.get("centralStorage"),
        }
    }


    handleSelectDepartment(item){
        if(item.id){
            sAction.rest.post('getReleaseStructInfo', {module: "acm_departments", rec: item.id}, returnData => {
                this.setState({
                    kod_stredisko : returnData.message.kod_stredisko,
                    account: returnData.message.account,
                    acm_storages : returnData.message.acm_storages,
                    acm_departments : {value: item.name, id_value: item.id},
                    contact: {value: "", id_value: ""},
                });
            });
            this.setState({acm_departments: {value: item.name, id_value: item.id}});
        }
        else{
            this.setState({
                acm_departments: {value: "", id_value: ""},
            });
        }
    }

    handleSelectAcc(item){
        if(item.id){
            sAction.rest.post('getReleaseStructInfo', {module: "accounts", rec: item.id}, returnData => {
                this.setState({
                    kod_stredisko : returnData.message.kod_stredisko,
                    account: {value: item.name, id_value: item.id},
                    acm_storages : returnData.message.acm_storages,
                    contact: {value: "", id_value: ""},
                    acm_departments : {value: "", id_value: ""},
                });
            });
        }
        else{
            this.setState({
                account: {value: "", id_value: ""},
            });
        }
        
    }

    handleSelectContact(item){
        if(item.id){
            sAction.rest.post('getReleaseStructInfo', {module: "contacts", rec: item.id}, returnData => {
                this.setState({
                    kod_stredisko : returnData.message.kod_stredisko,
                    account: returnData.message.account,
                    acm_storages : returnData.message.acm_storages,
                    contact: {value: item.name, id_value: item.id},
                    acm_departments : returnData.message.acm_departments,
                });
            });
        }
        else{
            this.setState({
                contact: {value: "", id_value: ""},
            });
        }
        
    }

    handleSelectStorage(item){
        if(item.id){
            this.setState({
                acm_storages: {value: item.name, id_value: item.id},
                account: {value: "", id_value: ""},
                contact: {value: "", id_value: ""},
                acm_departments : {value: "", id_value: ""},
            });

            sAction.rest.post('getStorageKodStredisko', {storageID: item.id}, returnData => {
               if(returnData.status){
                   let kod = returnData?.message?.data?.value;
                   this.setState({kod_stredisko: kod});
               }
            });
        }
        else{
            this.setState({
                acm_storages: {value: "", id_value: ""},
                kod_stredisko : "",
            });
        }

    }

    handleConfirm(paramsData){
        const account = this.state.account;
        const contact = this.state.contact;
        const acm_departments = this.state.acm_departments;
        const acm_storages = this.state.acm_storages;
        
        if(!account.id_value &&
            acm_storages.id_value !== this.state.centralStorage
        ){
            this.setState({accStatusClass : " error"});
            return;
        }
        else{
            this.setState({accStatusClass : ""});
        }
        
       let records = paramsData.get("records");

        let params = {
            records : records,
            account : account,
            contact : contact,
            acm_departments : acm_departments,
            acm_storages : acm_storages,
        }
        
        sAction.load();
        sAction.rest.post('itemRegRelease', params, returnData => {
            sAction.unLoad();

            if(returnData.status){
                if(returnData.message.itemStatus == "OK"){
                    const callback = this.props.data.get("data").get('callback');
                    if(callback){
                        callback();
                    }

                    location.reload();
                }
            }

        });
    }

    

    render() {
        const data = this.props.data.get("data");
        const account = this.state.account;
        const contact = this.state.contact;
        const acm_departments = this.state.acm_departments;
        const acm_storages = this.state.acm_storages;

        let contactFilter = {};
        let departmentFilter = {};
        if(account.id_value){
            contactFilter.account = {
                operandType: "relate",
                parentId: account.id_value,
                module: "Accounts",
                relationship: ["accounts"],
                name: "account_name",
                relName: "contacts",
                type: "eq"
                };

            departmentFilter.account = {
                operandType: "relate",
                parentId: account.id_value,
                module: "Accounts",
                relationship: ["acm_departments_accounts"],
                name: "acm_departments_accounts",
                relName: "acm_departments",
                type: "eq"
            };
        }

        if(acm_departments.id_value){
            contactFilter.department = {
                operandType: "relate",
                parentId: acm_departments.id_value,
                module: "acm_departments",
                relationship: ["acm_departments_contacts_1"],
                name: "acm_departments_contacts_1_name",
                relName: "acm_departments_contacts_1",
                type: "eq"
            };
        }


        let accountFilter = {};

        if(acm_storages.id_value){
            accountFilter.storage = {
                operandType: "relate",
                parentId: acm_storages.id_value,
                module: "acm_storages",
                relationship: ["acm_storages_accounts_1"],
                name: "acm_storages_accounts_1_name",
                relName: "acm_storages_accounts_1",
                type: "eq"
            };
        }

        let relDisabled = data.get('disabled');

        account.searchFields = ["name", "kod_stredisko"];
        acm_storages.searchFields = ["name", "kod_stredisko"];
        contact.searchFields = ["name", "kod_stredisko", "account_name"];

        return (
            <div className='itemRegReleaseBody'>
                <div className='itemRegReleaseRow'>
                    <div className="itemRegReleaseLabel">
                        {sAction.translate("LBL_ACM_ITEMS_REGISTRY_ACM_STORAGES_FROM_ACM_STORAGES_TITLE", "acm_items_registry")}
                    </div>
                    <div className="popupRelateContainer">
                        <Relate 
                            newRecord={false} 
                            module="acm_storages"
                            data={acm_storages}
                            buttons={[]}
                            callback={(item) => this.handleSelectStorage(item)}
                            searchFields={["name", "kod_stredisko"]}
                            key={acm_storages.id_value}
                            disabled={relDisabled}
                        />
                    </div>
                </div>
                <div className='itemRegReleaseRow'>
                    <div className="itemRegReleaseLabel">
                        {sAction.translate("LBL_ACM_ITEMS_REGISTRY_ACCOUNTS_FROM_ACCOUNTS_TITLE", "acm_items_registry")}
                        <span className="required">{acm_storages.id_value == this.state.centralStorage ? "" : "*"}</span>
                    </div>
                    <div className={"popupRelateContainer"+this.state.accStatusClass}>
                        <Relate 
                            newRecord={false} 
                            module="Accounts"
                            data={account}
                            buttons={[]}
                            callback={(item) => this.handleSelectAcc(item)}
                            key={account.id_value}
                            defaultFilter={accountFilter}
                            disabled={relDisabled}
                            additionalInfo="kod_stredisko"
                        />
                    </div>
                </div>   
                <div className='itemRegReleaseRow'>
                    <div className="itemRegReleaseLabel">
                        {sAction.translate("LBL_ACM_ITEMS_REGISTRY_ACM_DEPARTMENTS_FROM_ACM_DEPARTMENTS_TITLE", "acm_items_registry")}
                    </div>
                    <div className="popupRelateContainer">
                        <Relate 
                            newRecord={false} 
                            module="acm_departments"
                            data={acm_departments}
                            buttons={[]}
                            callback={(item) => this.handleSelectDepartment(item)}
                            key={acm_departments.id_value}
                            defaultFilter={departmentFilter}
                            disabled={relDisabled}
                        />
                    </div>
                </div>
                <div className='itemRegReleaseRow'>
                    <div className="itemRegReleaseLabel">
                        {sAction.translate("LBL_ACM_ITEMS_REGISTRY_CONTACTS_FROM_CONTACTS_TITLE", "acm_items_registry")}
                    </div>
                    <div className="popupRelateContainer">
                        <Relate 
                            newRecord={false} 
                            module="Contacts"
                            data={contact}
                            buttons={[]}
                            callback={(item) => this.handleSelectContact(item)}
                            key={contact.id_value}
                            defaultFilter={contactFilter}
                            disabled={relDisabled}
                            additionalInfo={(item) => { return " ("+ item.account_name + " - " + (item.kod_stredisko ? item.kod_stredisko : "")  + ")"}}
                        />
                    </div>
                </div>     
                <div className="sfButtonRow sfButtons viewActionPanelButtons">
                    <Button className="hoverGreen" onClick={() => this.handleConfirm(data)}>
                        <div className={"icon-saveIcon calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CONFIRM")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
            </div>
                
        );
    }
}
