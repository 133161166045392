import { redirect } from "../../exportConfig";

export default function loginAsRepresentative(params) {
    const id = this.dataGet('conf/user/id');
    const data = {
        type: 'relate',
        module: 'acm_portal_users',
        defaultFilter: [{
            field: 'representative',
            type: 'eq',
            value: id,
        }]
      };
    this.openRelatePopup(data, returnData => {
        const data = {
            id: returnData.id
        }
        this.rest.post("loginAsPortalRepresentative", data, res => {
            if(res.status){
                this.rest.setCookie("sID", res.sid, 1);
                localStorage.setItem('portalLoginAs', true);
                redirect({ url: '#home' });
                window.location.reload();
            }else{
                this.error(this.translate(res.message.text));
            }
        })
        this.popupHide();
    });
}