import React from "react";
import PureComponent from "../../pure";
import BoundModuleDetail from "./BoundModuleDetail";

export default class BoundModules extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const data = this.props.data;

        const props = this.props;
        console.log(props);
        let modules = [];

        data.toJS(false).forEachObject((view, index) => {
            modules.push(
                <BoundModuleDetail
                    prefix={props.prefix + '/' + index}
                    way={props.way}
                    data={data.get(index)}
                    index={index}
                    key={index}
                    module={view.name}
                />
            );
        });

        return modules;
    }
}
