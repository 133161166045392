import React, {useState, createContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import sAction from 'sAction';

export const AuthAdminContext = createContext({});

export default function AuthAdminContextProvider({children}) {
    const [passwordSettings, setPasswordSettings] = useState({});
    const [twoFactorSettings, setTwoFactorSettings] = useState({});
    const [IPSettings, setIPSettings] = useState({});
    const [emailTemplates, setEmailTemplates] = useState({});
    const [initialState, setInitialState] = useState(null);
    const [changes, setChanges] = useState({
        passwordSettings: {},
        twoFactorSettings: {},
        IPSettings: {},
        emailTemplates: {},
    });

    /**
     * update single value for password settings
     * @param {string} key setting to update
     * @param {string} value new value
     */
    const updatePasswordSettings = (key, value) => {
        setPasswordSettings({...passwordSettings, [key]: value});
        setChanges({...changes, passwordSettings: {...changes.passwordSettings, [key]: value}});
    };

    /**
     * update single value for two factor settings
     * @param {string} key setting to update
     * @param {string} value new value
     */
    const updateTwoFactorSettings = (key, value) => {
        setTwoFactorSettings({...twoFactorSettings, [key]: value});
        setChanges({...changes, twoFactorSettings: {...changes.twoFactorSettings, [key]: value}});
    };

    /**
     * update single value for IP settings
     * @param {string} key setting to update
     * @param {string} value new value
     */
    const updateIPSettings = (key, value) => {
        setIPSettings({...IPSettings, [key]: value});
        setChanges({...changes, IPSettings: {...changes.IPSettings, [key]: value}});
    };

    /**
     * update single value for email templates
     * @param {string} key template to update
     * @param {string} value new template
     */
    const updateEmailTemplate = (key, value) => {
        setEmailTemplates({...emailTemplates, [key]: value});
        setChanges({...changes, emailTemplates: {...changes.emailTemplates, [key]: value.id}});
    };

    /**
     * check if there are any changes
     * @returns {boolean} true if there are any changes
     */
    const hasChanges = () => Object.values(changes).some((value) => Object.values(value).length > 0);

    /**
     * save changes
     */
    const save = () => {
        if (!hasChanges()) return;

        sAction.load();
        sAction.rest.fetchData('admin/settings', 'POST', changes)
            .then(() => setInitialState(null))
            .catch((error) => sAction.error(error.text ?? error))
            .finally(() => sAction.unLoad());
    };

    /**
     * reset settings to default
     */
    const reset = () => {
        sAction.confrim(sAction.translate('LBL_AUTH_SETTINGS_CONFIRM_RESET', 'Administration'), () => {
            sAction.load();
            sAction.rest.fetchData('admin/settings', 'DELETE', {categories: Object.keys(initialState)})
                .then(() => setInitialState(null))
                .catch((error) => sAction.error(error.text ?? error))
                .finally(() => sAction.unLoad());
        });
    };

    const AuthAdminContextData = {
        changes: changes,
        hasChanges: hasChanges,

        passwordSettings: passwordSettings,
        updatePasswordSettings: updatePasswordSettings,

        twoFactorSettings: twoFactorSettings,
        updateTwoFactorSettings: updateTwoFactorSettings,

        ipSettings: IPSettings,
        updateIPSettings: updateIPSettings,

        emailTemplates: emailTemplates,
        updateEmailTemplates: updateEmailTemplate,

        save: save,
        reset: reset,
    };

    useEffect(() => {
        if (initialState) return;

        const categories = [
            'passwordSettings',
            'twoFactorSettings',
            'IPSettings',
            'emailTemplates',
        ];

        sAction.rest.fetchData('admin/settings', 'GET', {categories})
            .then(({data}) => {
                const newPasswordSettings = {...data.passwordSettings, ...changes.passwordSettings}
                const newTwoFactorSettings = {...data.twoFactorSettings, ...changes.twoFactorSettings}
                const newIPSettings = {...data.IPSettings, ...changes.IPSettings}
                const newEmailTemplates = {...data.emailTemplates}
                changes?.emailTemplates?.forEachObject((key) => {
                    newEmailTemplates[key] = emailTemplates[key];
                });
                setPasswordSettings(newPasswordSettings);
                setTwoFactorSettings(newTwoFactorSettings);
                setIPSettings(newIPSettings);
                setEmailTemplates(newEmailTemplates);
                setInitialState({
                    passwordSettings: newPasswordSettings,
                    twoFactorSettings: newTwoFactorSettings,
                    IPSettings: newIPSettings,
                    emailTemplates: newEmailTemplates,
                });
            });
    }, [initialState]);

    // Remove unchanged values from changes
    useEffect(() => {
        if (!initialState) return;

        Object.keys(changes).forEach((tab) => {
            Object.keys(changes[tab]).forEach((key) => {
                if (tab === 'emailTemplates' && changes[tab][key] === initialState[tab][key].id) {
                    delete changes[tab][key];
                } else if (changes[tab][key] === initialState[tab][key]) {
                    delete changes[tab][key];
                }
            });
        });
        setChanges(changes);
    }, [changes, initialState]);

    return (
        <AuthAdminContext.Provider value={AuthAdminContextData}>
            {children}
        </AuthAdminContext.Provider>
    );
}

AuthAdminContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
