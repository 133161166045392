import React from 'react';
import MaterialTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import sAction from 'sAction';
import Button from 'ROOT/src/components/formElements/Button';
import {AuthAdminContext} from 'ROOT/src/contexts/AuthAdminContext';
import PasswordSettings from './PasswordSettings';
import TwoFactorSettings from './TwoFactorSettings';
import IPAddressSettings from './IPSettings';

export default function AuthAdminContainer() {
    const {hasChanges, save, reset} = React.useContext(AuthAdminContext);
    const [tab, setTab] = React.useState(0);

    /**
     * cancel changes and return to admin page
     */
    const cancel = () => {
        if (hasChanges()) {
            sAction.confrim(sAction.translate('LBL_UNSAVED_CHANGES'), () => {
                window.location.hash = '#admin/';
            });
        } else {
            window.location.hash = '#admin/';
        }
    };

    return (
        <div className='reportViewContainer AuthAdmin'>
            <div className="viewActionPanel">
                <div className="viewActionPanelButtons">
                    <Button onClick={cancel} key={'returnChanges'}>
                        <div className={'actionPanelButtonIcon icon-undo'}/>
                        {sAction.translate('LBL_RETURN_BACK')}
                    </Button>
                    <Button onClick={reset} key={'restore'}>
                        <div className={'actionPanelButtonIcon icon-undo hoverGreen'}/>
                        {sAction.translate('LBL_RESTORE_DEFAULT', 'ModuleBuilder')}
                    </Button>
                    <Button onClick={save} key={'save'}>
                        <div className={'actionPanelButtonIcon icon-saveIcon hoverGreen'}/>
                        {sAction.translate('LBL_REPORT_SAVE')}
                    </Button>
                </div>
            </div>
            <div className="customReportContainer">
                <div className="customReport customReportContent">
                    <div className="titleContainer">
                        <h1 className="ReportTitle">
                            {sAction.translate('LBL_AUTHENTICATION_SETTINGS', 'Administration')}
                        </h1>
                    </div>
                    <div className="reportContent">
                        <div className="reportHeaderContainer">
                            <div className='detailViewTabHeader'>
                                <MaterialTabs
                                    value={tab}
                                    onChange={(event, newValue) => setTab(newValue)}
                                    variant={'scrollable'}
                                >
                                    <Tab label={sAction.translate('LBL_PASSWORD_SETTINGS', 'Administration')} />
                                    {<Tab label={sAction.translate('LBL_2FA_SETTINGS', 'Administration')} />}
                                    <Tab label={sAction.translate('LBL_IP_SETTINGS', 'Administration')} />
                                </MaterialTabs>
                            </div>
                            <div className='detailViewTabContent'>
                                {tab === 0 && <PasswordSettings />}
                                {tab === 1 && <TwoFactorSettings />}
                                {tab === 2 && <IPAddressSettings />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

AuthAdminContainer.propTypes = {
};
