export default function newSubRecord(data) {
  // console.log(data);
  let { module, parentModule, parentId, parentName, prefix,rel, relate_simple } = data;
  if (!module) {
    console.log("není zadán modul");
    return;
  }
  if (!parentModule) {
    console.log("není zadán parent modul");
    return;
  }
  if (!parentId) {
    console.log("není zadán parent id");
    return;
  }
  if (!parentName) {
    console.log("není zadán parent name");
    return;
  }
  if (!prefix) {
    console.log("není zadán prefix");
  }
  this.detailPredefinedFields = [];
  const activitiesModules = ["Calls", "Meetings", "Tasks", "Notes"];

  if (activitiesModules.includes(module)) {
    if (parentModule === 'Contacts') {
      let customData = {invitees:
          {
            relatedInv: {}
          }
      };

      customData.invitees.relatedInv = {Users: [], Contacts: [], Leads: []};
      let contact = {
        email: this.dataGet('view/fields/email1/value'),
        id: parentId,
        name: parentName,
        phone_mobile: this.dataGet('view/fields/phone_mobile/value'),
        phone_work: this.dataGet('view/fields/phone_work/value')
      };
      customData.invitees.relatedInv.Contacts.push(contact);
      this.detailPredefinedFields.push({type: "customData", value: customData})

      this.detailPredefinedFields.push({
        type: "relate_simple",
        fieldName: "contact_name",
        name: "contact_id",
        value: { id: parentId, "name": parentName }
      });

      const parentAccount = this.dataGet('view/fields/account_name')
      if(parentAccount) {
        const accountId = parentAccount.def.get('id_value');
        const accountName = parentAccount.get('value');
        if (accountId) {
          parentName = accountName;
          parentId = accountId;
          parentModule = "Accounts";
        }
      }
    }

    this.detailPredefinedFields.push({
      type: "parent",
      name: "parent_name",
      parentName,
      parentId,
      parentModule
    });
  } else if (relate_simple) {
    this.detailPredefinedFields.push({
      type:"relate_simple",
      ...relate_simple
    });
  }else {
    if(!rel){
        console.log("nejsou zadány inforamce o vazbě");
        return;
    }
    this.detailPredefinedFields.push({
        type:"relate",
        module:parentModule,
        id:parentId,
        value:parentName,
        rel
    })
  }


  this.popupDetail({
    module,
    record: "",
    saveCallback: data => {
      let params = { subpanelAction: "new_relate" }
      this.reloadSubpanelByWay(prefix, module, params);
    },
    exitCallback: data => {

    }
  });
}
