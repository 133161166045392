import React, {PureComponent} from "react";
import sAction from "sAction";
import SimpleFormFromArray from '../../../formElements/SimpleFormFromArray';

export default class CreatePortalUserPopup extends PureComponent {
    getButtons() {
        return {
            cancel: {
                label: 'LBL_CANCEL_ACTION',
                icon: 'icon-undo',
                className: 'hoverRed',
                onClick: () => {sAction.popupHide()}
            },
            createUser: {
                label: sAction.translate('LBL_CREATE_PORTAL_USER', 'Contacts'),
                icon: 'icon-acm_portal_users',
                onClick: (data) => {this.createPortalUser(data)}
            },
        };
    }

    createPortalUser(data) {
        sAction.load();
        sAction.rest.post("contactCreatePortalUser", {record: this.props.record, role: data.role}, (returnData) => {
            sAction.unLoad();
            if(returnData.status){
                sAction.confrim(sAction.translate(returnData.message?.text), () => {
                    sAction.popupHide();
                    sAction.route();
                });
            }
            else{
                if (returnData.errorMessage?.data?.record) {
                    sAction.popupHide();
                }
                sAction.error(sAction.translate(returnData.errorMessage?.text));
            }
        });
    }

    getFields() {
        return {
            role: {
                type: 'enum',
                options: 'portal_users_role_list',
                label: sAction.translate('LBL_ROLE', 'acm_portal_users'),
                required: true,
            },
        };
    }
    render() {
        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_ITEM_FOR_ITEM')}</div>
                </div>
                <div className='itemForItem'>
                    <SimpleFormFromArray
                        buttons={this.getButtons()}
                        fields={this.getFields()}
                    />
                </div>
            </>
        );
    }
}
