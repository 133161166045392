import React from "react"
import  sAction  from "sAction";

import Select from "../formElements/SelectAcm"
import Relate from "../formElements/Relate"
import EmailRelateField from "./EmailRelateField"

export default class EmailRelateFieldTable extends EmailRelateField {
  constructor(props) {
    super(props);
  }

  render() {
    const { parent_id, parent_type, parent_name } = this.props;

    return (
      <tr>
        <th>
          {/*Vztahující se k*/}
          {sAction.translate("LBL_EMAIL_RELATE", "Emails")}
        </th>
        <td className={"email__compose__table-form__relate"}>

          <Select
            options={this.optionsToSelect}
            name="parent_type"
            value={parent_type}
            onChange={this.handleChange}
          />

          <div className="email__compose__table-form__input">
            <Relate
              key={parent_id}
              newRecord={false}
              data={{
                value: parent_name
                // label: "Subjekt"
                // label: sAction.translate("LBL_EMAIL_OBJECT", "Emails")
              }}
              buttons={[]}
              module={parent_type}
              callback={this.update}
              InputProps={{ disableUnderline: true }}
              containerClassName={"relateField"}
            />

            <div className="email__compose__table-form__input__button">
              <a onClick={this.openPopupList} tabIndex={0}>
                {/*Vybrat*/}
                {sAction.translate("LBL_EMAIL_SELECT")}
              </a>
            </div>
          </div>
        </td>
      </tr>
    );
  }
}
