import React from "react";
import PureComponent from "../../pure";
import sAction from "../../../action"

import OrganizationChart from "@dabeng/react-orgchart";
import 'regenerator-runtime/runtime'
import PUNode from "../../orgChart/PUNode"
import {fromJSON} from "../../../action/exportConfig"

export default class PortalUsersHierarchy extends PureComponent {

    constructor(){
        super();
        this.state = {
            loaded : false,
            data : {},
            entryNode : "",
        }

    }

    componentDidMount() {

        this.setState({
            loaded: false,
        })

        var component = this;
        // sAction.load()

        sAction.rest.post("getPortalUserHierarchy", this.props.data.get('params'), function(resp) {
            if(resp.status === true){
                sAction.unLoad();

                component.setState({loaded:true});
                var d = fromJSON(resp.message.data)

                component.setState({data:d[0], entryNode: resp.message.entryNode});
                // this.state.data = resp.message.data;
            }
        });
    }

    render(){

        if (this.state.loaded){
            var ds = this.state.data;
            return <OrganizationChart datasource={ds} NodeTemplate={PUNode} chartClass="clickable" direction={"l2r"} collapsible={false}/>;

        }
        return <div> &nbsp;&nbsp;&nbsp;{sAction.translate("LBL_LOADING")} </div>;

    }

}