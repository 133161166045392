import React, { useState } from 'react';
import Button from '../../formElements/Button';
import sAction from '../../../action';
import Loader from '../../loader';

export default function CancelRepresentativePopup(params) {
    const [load, setLoad] = useState(false);
    const cancelRepresentative = () => {
        setLoad(true);
        const representativeId = sAction.dataGet('view/fields/representative/value');
        const userName = sAction.dataGet('view/name');
        const userId = sAction.dataGet('view/fields/id/value');
        sAction.rest.post('sendEmailCancelRepresentative', { representativeId, userName, userId }, res => {
            if (res.status) {
                sAction.route();
                sAction.popupHide();
            } else {
                setLoad(false);
                sAction.error(sAction.translate(res.message.text));
            }
        });
    }

    return (
        <div>
            {load ? <Loader fullscreen /> : null}
            <div className='acmPopupHeader'>Alert</div>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column' }}>
                <div style={{ marginTop: '30px', height: '80px', fontSize: '20px', textAlign: 'center' }}>{sAction.translate('LBL_REPRESENTATIVE_CANCEL_POPUP')}</div>
                <div style={{ display: 'flex' }}>
                    <Button
                        onClick={() => cancelRepresentative()}
                        key={"cancelRepresentative"}
                        disabled={false}
                        id={"cancelRepresentative"}
                    >
                        <p style={{ margin: 'auto 0', }}>{sAction.translate('LBL_CLOSE_ACTIVITY_HEADER')}</p>
                    </Button>
                    <Button
                        onClick={() => sAction.popupHide()}
                        key={"closeCancelRepresentativePopup"}
                        disabled={false}
                        id={"closeCancelRepresentativePopup"}
                        className={'hoverRed'}
                    >
                        <p style={{ margin: 'auto 0', }}>{sAction.translate('LBL_DATEPICKER_CANCEL')}</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}