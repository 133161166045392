import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import sAction from 'sAction';

import SimpleFormFromArray from '../../formElements/SimpleFormFromArray';

export default class ItemMoveFromPortalPopup extends PureComponent{
    constructor(props) {
        super(props);

        this.description = React.createRef();
        this.account = React.createRef();
        this.contact = React.createRef();

        this.state = {
            accountID: null,
        }
    }

    getFields() {
        const role = sAction.dataGet('conf/user/role');
        return {
            account: {
                type: 'relate',
                label: sAction.translate('LBL_ACM_LOAN_REQUIREMENT_ACCOUNTS_FROM_ACCOUNTS_TITLE', 'acm_loan_requirement'),
                myRef: this.account,
                module: 'Accounts',
                rowClass: 'accountRelate',
                required: true,
                defaultValue: {
                    id: this.account?.current?.value?.id,
                    name: this.account?.current?.value?.name,
                },
                additionalInfo: "kod_stredisko",
                readonly: !['area_manager', 'manager', 'partner'].includes(role),
                searchFields : ["name", "kod_stredisko"],
                onChange: (e => {this.setState({accountID: e.id});}),
            },
            contact: {
                type: 'relate',
                label: sAction.translate('LBL_ACM_REQUESTS_CONTACTS_FROM_CONTACTS_TITLE', 'acm_requests'),
                myRef: this.contact,
                defaultValue: {
                    id: this.contact?.current?.value?.id,
                    name: this.contact?.current?.value?.name,
                },
                module: 'Contacts',
                rowClass: 'accountRelate',
                required: true,
                searchFields : ["name"],
                defaultFilter: [{
                    module: "Accounts",
                    name: "account_name",
                    operandType: "relate",
                    parentId: this.state.accountID,
                    relName: "contacts",
                    relationship: ['accounts'],
                    type: "eq",
                }],
                readonly: !['area_manager', 'manager', 'partner'].includes(role),
                onChange: (e => {this.setAccount(e.id);}),
                style: {maxWidth: 'none', flex: '1 0 auto'},
            },
        };
    };

    setAccount(id) {
        if (this.state.accountID) {
            return;
        }
        const searchData = {
            module: 'Accounts',
            fields: ['name'],
            getFields: ['name', 'id'],
            value: '%',
            defaultFilter: [{
                operandType: 'relate',
                parentId: id,
                module: 'Contacts',
                relationship: ['contacts'],
                type: 'eq',
                name: 'contact_name',
                relName: 'accounts',
            }],
            limit: 10,
        };

        sAction.quickSearch(searchData, returnData => {
            if (this.account?.current?.value) {
                this.account.current.value = {
                    id: returnData?.[0]?.id,
                    name: returnData?.[0]?.name,
                };
            } else {
                this.account.current = {
                    value: {
                        id: returnData?.[0]?.id,
                        name: returnData?.[0]?.name,
                    },
                };
            }
            this.setState({accountID: returnData?.[0]?.id})
        });
    }

    getButtons() {
        return {
            back: {
                label: 'LBL_CANCEL',
                onClick: () => {
                    sAction.popupHide();
                },
                icon: 'icon-undo',
                checkRequired: false,
            },
            change: {
                label: 'LBL_PORTAL_MOVE_ITEM',
                onClick: (returnData) => {
                    this.parseDataAndCreatePostRequest(returnData);
                },
                icon: 'icon-forwardIcon',
            },
        }
    }

    parseDataAndCreatePostRequest(formData) {
        sAction.load();
        sAction.rest.post('PortalItemMove', {itemID: this.props.data.get('id'), accountID: formData.account.id, contactID: formData.contact.id}, (returnData) => {
            sAction.unLoad();
            if (returnData.status) {
                sAction.popupHide();
                sAction.route();
                const data = {
                    header: sAction.translate("LBL_PORTAL_ITEM_MOVED_HEADER"),
                    buttons: [
                        { label: sAction.translate("LBL_CONTINUE", "Home"), callback: () => {sAction.popupHide(); sAction.route();} },
                    ],
                };
                sAction.popup(sAction.translate(returnData.message.text), data);
            } else {
                if (returnData.errorMessage?.data?.popupHide !== false) {
                    sAction.popupHide();
                }
                sAction.error(sAction.translate(returnData.errorMessage.text))
            }
        });
    }

    render() {
        return (
            <>
                <SimpleFormFromArray
                    fields={this.getFields()}
                    buttons={this.getButtons()}
                />
            </>
        );
    }
}
