import React from "react";
import PureComponent from "../../pure";
import Button from "../../formElements/Button";
import Select from "../../formElements/Select";

import sAction from "sAction";

export default class ItemTransferPopup extends PureComponent {

    constructor(){
        super();

        this.state = {
            type: "lost",
        };
    }

    handleConfirm(){

        // if(this.state.type == "service"){
        //     //TODO - service formular???
        // }
        if(!this.state.reason){
            this.setState({'reasonError': true});
            return;
        }

        const id = this.props.data.get("data").get("params").get("id");

        sAction.load();
        sAction.rest.post('itemTransfer', {
            id : id,
            type: this.state.type,
            reason: this.state.reason,
            portal: this.props.data.get?.('data')?.get?.('params')?.get?.('portal'),
        }, returnData => {
            sAction.unLoad();

            if(returnData.status){
                sAction.route();
            }
            else{
                sAction.error(returnData.errorMessage)
            }
        });

    }

    render(){
        const stageList = sAction.app_strings.item_registry_stage_list;

        let typeOptions = [
            {
                value: "lost",
                label: stageList.lost,
            },
            {
                value: "steal",
                label: stageList.steal,
            },
        ];
        if (this.props.data.get?.('data')?.get?.('params')?.get?.('portal') !== true) {
            typeOptions.push(
                {
                    value: "discarded",
                    label: stageList.discarded
                }
            );
        }

        return <div>
                <div className="acmPopupContent">
                    <table className="tranferTable">
                        <tbody>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_ACTION_TYPE", "acm_items_registry")}
                                </td>
                                <td>
                                    <Select
                                        open={false}
                                        name="transferType"
                                        options={typeOptions}
                                        value={this.state.type}
                                        onChange={(ev) => this.setState({type : ev.target.value})}
                                    />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_REASON", "acm_items_registry")}
                                </td>
                                <td>
                                    <textarea
                                        style={{height: '60px', width: '100%'}}
                                        onChange={(ev) => this.setState({reason : ev.target.value})}
                                        className={this.state.reasonError ? 'error' : null}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="sfButtonRow sfButtons viewActionPanelButtons viewActionPopuBorromButtons">
                    <Button className="hoverGreen" onClick={() => this.handleConfirm()}>
                        <div className={"icon-saveIcon calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CONFIRM")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
        </div>;
    }
}
