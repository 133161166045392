import React, { PureComponent } from "react";
import  sAction  from "sAction";
import EmailRelateFieldTable from "./EmailRelateFieldTable";
import Select from "../formElements/SelectAcm";
import TickBox from "../formElements/TickBox";

export class EmailComposeForm extends PureComponent {
  handleChange = e => {
    sAction.dataSet(this.props.way + "/" + e.target.name, e.target.value);
  };

  expand = ccOrBcc => {
    const bool = !this.props.data[ccOrBcc + "Expanded"];

    sAction.dsClear();

    let q = true;

    if (!bool && this.props.data[ccOrBcc + "_addrs"]) {
      //q = confirm("Opravdu chcete smazat obsah pole?");
      if (q)
        sAction.dsAdd("set", this.props.way + "/" + ccOrBcc + "_addrs", "");
    }
    if (q) {
      sAction.dsAdd("set", this.props.way + "/" + ccOrBcc + "Expanded", bool);
      sAction.dsProcess();
    }
  };

  expandCC = () => {
    this.expand("cc");
  };

  expandBCC = () => {
    this.expand("bcc");
  };

  sendIndividually = checked => {
    sAction.dataSet(this.props.way + "/send_individually", checked);
  }

  openPopupAddContact = () => {
    let {
      data: { to_addrs, cc_addrs, bcc_addrs }
    } = this.props;
    sAction.popup("emailAddContact", {
      to_addrs,
      cc_addrs,
      bcc_addrs,
      callback: this.addContacts
    });
  };

  addContacts = emails => {
    sAction.emailAddContacts(emails, this.props);
  };

  getType = () => {
    let ret = [];
    const options = sAction.app_strings[this.props.data.complaint_form ? 'complaint_form_options' : 'contact_form_options'];
    if (!options) {
      return ret;
    }
    options.forEachObject((label, value) => {
      ret.push({label, value});
    });
    return ret;
  }

  render() {
    let { handleChange, expandCC, expandBCC, openPopupAddContact } = this;
    let {
      way,

      //vars
      data: {
        name,
        cc_addrs,
        bcc_addrs,
        to_addrs,
        ccExpanded,
        bccExpanded,
        fromAccounts,
        account,
        send_individually,
        disable_recipient_change,

        parent_id,
        parent_type,
        parent_name,

        contact_form,
        type,
      }
    } = this.props;

    return (
      <table className={"email__compose__table-form"}>
        <tbody>
        {!contact_form && (<React.Fragment><tr>
            <th>
              {/*Odesílatel*/}
              {sAction.translate("LBL_FROM", "Emails")}
            </th>
            <td>
              <Select
                options={fromAccounts}
                name="account"
                value={account}
                onChange={handleChange}
              />
            </td>
          </tr>

          <EmailRelateFieldTable
            way={way}
            parent_id={parent_id}
            parent_type={parent_type}
            parent_name={parent_name}
          />

          <tr>
            <th>
              {/*Komu*/}
              {sAction.translate("LBL_EMAIL_TO")}
            </th>
            <td>
              <div className="email__compose__table-form__input">
                <input
                  type="text"
                  id="to_addrs"
                  name="to_addrs"
                  value={to_addrs}
                  onChange={handleChange}
                  disabled={disable_recipient_change || false}
                />

                {!disable_recipient_change && <React.Fragment>
                  <div className="email__compose__table-form__add-cc__button">
                    {/*<input type="checkbox" id="send_individually" onChange={this.sendIndividually} checked={send_individually} />*/}
                    <TickBox id="send_individually" onChange={this.sendIndividually} checked={send_individually}/>
                    <label htmlFor="send_individually">
                      {/* Zaslat jednotlivě */}
                      {sAction.translate("LBL_EMAIL_SEND_INDIVIDUALLY", "Emails")}
                    </label>
                  </div>

                  <div className="email__compose__table-form__add-cc__button">
                    <a onClick={expandCC} tabIndex={0}>
                      {/*Kopie (cc)*/}
                      {!ccExpanded ? "+" : "-"} CC
                    </a>
                  </div>

                  <div className="email__compose__table-form__add-cc__button">
                    <a onClick={expandBCC} tabIndex={0}>
                      {!bccExpanded ? "+" : "-"} BCC
                    </a>
                  </div>

                  <div className="email__compose__table-form__input__button">
                    <a onClick={openPopupAddContact} tabIndex={0}>
                      {/*Vybrat*/}
                      {sAction.translate("LBL_EMAIL_SELECT")}
                    </a>
                  </div>
                </React.Fragment>
                }
              </div>
            </td>
          </tr>
          <tr className={ccExpanded ? "" : " hidden"}>
            <th>
              {/*Kopie (cc)*/}
              {sAction.translate("LBL_CC", "Emails")}
            </th>
            <td>
              <div className="email__compose__table-form__input">
                <input
                  type="text"
                  name="cc_addrs"
                  value={cc_addrs}
                  onChange={handleChange}
                />
                <div className="email__compose__table-form__input__button">
                  <a onClick={openPopupAddContact} tabIndex={0}>
                    {/*Vybrat*/}
                    {sAction.translate("LBL_EMAIL_SELECT")}
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr className={bccExpanded ? "" : " hidden"}>
            <th>
              {/*Skrytá kopie (bcc)*/}
              {sAction.translate("LBL_BCC", "Emails")}
            </th>
            <td>
              <div className="email__compose__table-form__input">
                <input
                  name="bcc_addrs"
                  value={bcc_addrs}
                  onChange={handleChange}
                />
                <div className="email__compose__table-form__input__button">
                  <a onClick={openPopupAddContact} tabIndex={0}>
                    {/*Vybrat*/}
                    {sAction.translate("LBL_EMAIL_SELECT")}
                  </a>
                </div>
              </div>
            </td>
          </tr></React.Fragment>)}
          {contact_form && (<tr>
            <th>
              {/*Typ*/}
              {sAction.translate("LBL_CONTACT_FORM_TYPE")} *
            </th>
            <td>
              <Select
                options={this.getType()}
                name="type"
                value={type}
                onChange={handleChange}
              />
            </td>
          </tr>)}
          <tr>
            <th>
              {/*Předmět*/}
              {sAction.translate("LBL_SUBJECT", "Emails")} *
            </th>
            <td>
              <div className="email__compose__table-form__input">
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
