import React from 'react';
import PropTypes from 'prop-types';
import PasswordStrengthBar from 'react-password-strength-bar';

import PureComponent from '../pure';
import Button from '../formElements/Button';
import Input from '../formElements/InputText';
import sAction from 'sAction';
import Loader from '../loader';
import PasswordRequirementsPopover from './PasswordRequirementsPopover';
import PasswordRequirements from './PasswordRequirements';

export default class ResetPass extends PureComponent {
    constructor() {
        super();

        this.state = {
            token: false,
            error: false,
            message: '',
            pass: true,
            passAgain: true,
            passMessage: '',
            passAgainMessage: '',
            minLength: 0,
            maxLength: 0,
            extraRequirements: [],
            showRequirements: false,
            password: '',
        };
        this.pass = React.createRef();
        this.passAgain = React.createRef();
    }

    /**
     * handle send on enter
     * @param {KeyboardEvent} e event
     */
    onKeyDown(e) {
        if (e.keyCode === 13 && this.state.token === true && !this.state.message) {
            this.resetPassword();
        }
    }

    /**
     * handle reset password
     * @param {boolean} force
     */
    resetPassword(force = false) {
        const pass = this.pass.current.value;
        const passAgain = this.passAgain.current.value;

        this.setState({
            pass: Boolean(pass),
            passAgain: Boolean(passAgain),
            passMessage: '',
            passAgainMessage: '',
        });

        if (!pass) {
            return;
        }

        if (!sAction.checkPassword(pass, this.state.minLength, this.state.maxLength, this.state.extraRequirements)) {
            this.setState({
                pass: false,
                passMessage: <PasswordRequirements {...this.state}/>,
            });
            return;
        }

        if (pass !== passAgain) {
            this.setState({
                passAgain: false,
                passAgainMessage: 'LBL_PASSWORDS_DO_NOT_MATCH',
            });
            return;
        }

        const urlArray = window.location.hash.split('/');
        const self = this;
        sAction.resetPassword(pass, urlArray[2], (data) => {
            if (data.status) {
                self.setState({message: data.message, error: false, token: false});
            } else if (data.ask) {
                sAction.confrim(data.message, () => {
                    self.resetPassword(true);
                    sAction.popupHide();
                }, 'red');
            } else if (data.errors) {
                self.setState({
                    pass: false,
                    passMessage: data.errors.map((error) => sAction.translate(error)).join('\n'),
                    error: true});
                sAction.error(sAction.translate(data.message));
            } else {
                self.setState({
                    pass: false,
                    passMessage: data.message,
                    error: true
                });
                sAction.error(sAction.translate(data.message));
            }
        }, force);
    }

    render() {
        let ret;
        if (this.state.message) {
            ret = this.getMessage();
        } else {
            if (!this.state.token) {
                ret = this.getCheckingTokenValidity();
                const self = this;
                const urlArray = window.location.hash.split('/');
                sAction.rest.fetchData('token/confirmToken', 'POST', {
                    token: urlArray[2],
                    type: sAction.param.is_portal? 'resetPasswordPortalUser' : 'resetPassword',
                }).then(() => {
                    self.setState({token: true, error: false});
                    sAction.rest.fetchData('passwordRequirements', 'GET').then((res) => {
                        this.setState({
                            minLength: res.data.length.min,
                            maxLength: res.data.length.max,
                            extraRequirements: res.data.extra,
                        });
                    });
                }).catch((err) => {
                    self.setState({error: true, message: err.text});
                });
            } else {
                ret = this.getForm();
            }
        }

        return (
            <table className="loginTable" onKeyDown={(e) => this.onKeyDown(e)}>
                <tbody>
                    {!sAction.param.is_portal && <tr>
                        <td colSpan="2" className="login PageLogoContainer">
                            <img src={sAction.param.companyLogo} />
                        </td>
                    </tr>}
                    {ret}
                </tbody>
            </table>
        );
    }

    /**
     * make form for password reset
     * @returns {JSX.Element}
     */
    getForm() {
        const {password, minLength, maxLength, extraRequirements} = this.state;
        return (
            <React.Fragment>
                <tr style={{height: '0px'}}>
                    <td colSpan="2" style={{position: 'relative', padding: '0px'}}>
                        <div style={{position: 'absolute', right: '16px', bottom: '0px'}}>
                            <PasswordRequirementsPopover {...{minLength, maxLength, extraRequirements}}/>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {this.state.passMessage ?
                            this.getErrorMessage(this.state.passMessage) :
                            null}
                        <Input
                            type="password"
                            error={!this.state.pass}
                            myRef={this.pass}
                            label={sAction.translate('LBL_NEW_PASSWORD')}
                            variant="outlined"
                            onFocus={() => {this.setState({showRequirements: true})}}
                            onBlur={() => {this.setState({showRequirements: false})}}
                            onChange={(e) => {this.setState({password: e.target.value})}}
                        />
                        {this.state.showRequirements && !sAction.checkPassword(password, minLength, maxLength, extraRequirements) &&
                            <div className='passwordRequirements' style={{width: '334px'}}>
                                <PasswordRequirements {...{minLength, maxLength, extraRequirements, password}}/>
                            </div>
                        }
                        <PasswordStrengthBar
                            password={password}
                            minLength={minLength}
                            scoreWords={
                                [
                                    'LBL_PASSWORD_VULNERABLE',
                                    'LBL_PASSWORD_WEAK',
                                    'LBL_PASSWORD_OKAY',
                                    'LBL_PASSWORD_GOOD',
                                    'LBL_PASSWORD_STRONG',
                                ].map((word) => sAction.translate(word))
                            }
                            shortScoreWord={sAction.translate('LBL_PASSWORD_TOO_SHORT')}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        {this.state.passAgainMessage ?
                            this.getErrorMessage(this.state.passAgainMessage) :
                            null}
                        <Input
                            type="password"
                            error={!this.state.passAgain}
                            myRef={this.passAgain}
                            label={sAction.translate('LBL_NEW_PASSWORD_AGAIN')}
                            variant="outlined"
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <Button
                                className="loginButton"
                                onClick={() => this.resetPassword()}
                            >
                                {sAction.translate('LBL_CONFIRM_NEW_PASSWORD')}
                            </Button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            {sAction.translate('LBL_FORGOTTEN_PASSWORD_RETURN')}
              &nbsp;
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate('LBL_FORGOTTEN_PASSWORD_RETURN_BUTTON')}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    /**
     * get error message
     * @returns {JSX.Element}
     */
    getMessage() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className={this.state.error ? 'center error' : 'center'}>
                            {sAction.translate(this.state.message)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate('LBL_BACK_TO_LOGIN')}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    /**
     * token checking validity
     * @returns {JSX.Element}
     */
    getCheckingTokenValidity() {
    // sAction.load();
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className="center">
                            {sAction.translate('LBL_TOKEN_CHECKING_VALIDITY')}
                        </div>
                        <Loader key={'zero'} />
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    /**
     * format backend error message
     * @param {string} message
     * @returns {JSX.Element}
     */
    getErrorMessage(message) {
        return <div className="center error">{sAction.translate(message)}</div>;
    }
}

ResetPass.propTypes = {
    goToLogin: PropTypes.func.isRequired,
};
