import React from 'react';
import ReportCheckbox from 'ROOT/src/customReports/reportCommon/fields/ReportCheckbox';
import ReportTextField from 'ROOT/src/customReports/reportCommon/fields/ReportTextField';

import sAction from 'sAction';
import {AuthAdminContext} from 'ROOT/src/contexts/AuthAdminContext';

export default function IPAddressSettings() {
    const {ipSettings, updateIPSettings} = React.useContext(AuthAdminContext);

    /**
     * update single boolean value for IP settings
     * @param {string} key setting to update
     * @param {string} value new value
     * @returns {void}
     */
    const checkboxConvertor = (key, value) => updateIPSettings(key, !!value);

    /**
     * update single array value for IP settings
     * @param {string} key setting to update
     * @param {string} value new value
     * @returns {void}
     */
    const arrayConvertor = (key, value) => updateIPSettings(key, value.split('\n'));

    return <div className="detailViewTabContentBlok select">
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_IP_RESTRICTIONS', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_IP_ADDRESS_WHITELIST_ENABLE', 'Administration')}
                name={'whitelistEnable'}
                value={ipSettings.whitelistEnable? 1: 0}
                onChange={checkboxConvertor}
            />
            <ReportCheckbox
                vname={sAction.translate('LBL_IP_ADDRESS_BLACKLIST_ENABLE', 'Administration')}
                name={'blacklistEnable'}
                value={ipSettings.blacklistEnable? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_IP_ADDRESS_WHITELIST', 'Administration')}
                name={'whitelist'}
                value={ipSettings.whitelist?.join('\n')}
                onChange={arrayConvertor}
                type={'text'}
                width={100}
                readonly={!ipSettings.whitelistEnable}
            />
            <ReportTextField
                vname={sAction.translate('LBL_IP_ADDRESS_BLACKLIST', 'Administration')}
                name={'blacklist'}
                value={ipSettings.blacklist?.join('\n')}
                onChange={arrayConvertor}
                type={'text'}
                width={100}
                readonly={!ipSettings.blacklistEnable}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_2FA_WHITELIST_TITLE', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_2FA_WHITELIST_ENABLE', 'Administration')}
                name={'twoFactorWhitelistEnable'}
                value={ipSettings.twoFactorWhitelistEnable? 1: 0}
                onChange={checkboxConvertor}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_2FA_WHITELIST', 'Administration')}
                name={'twoFactorWhitelist'}
                value={ipSettings.twoFactorWhitelist?.join('\n')}
                onChange={arrayConvertor}
                type={'text'}
                width={100}
                readonly={!ipSettings.twoFactorWhitelistEnable}
            />
        </div>
    </div>;
}
