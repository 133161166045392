export default function rowResizeMove(e){

    if(this.activeResizeRow){
        var actX = e.clientX;
        var vysledekX = actX - this.resizeStart;
        
        this.resizeRows.forEach(element => {
            element.style.maxWidth = this.resizeWidthStart+vysledekX+"px";
            let minWidth = this.resizeWidthStart+vysledekX;
            if (minWidth < 100) {
              minWidth = 100;
            }
            element.style.minWidth = minWidth+"px";
        });
    }
}
