import React from "react";
import SimpleFormFromArray from "../../formElements/SimpleFormFromArray";
import sAction from "../../../action";

// Last change in issue https://gitlab.acmark.cz/coripo/spsinventar/-/issues/282
export default function assignToContact (params) {
    const prefix = params.data?.toJS()?.prefix;
    const accId = sAction.dataGet(`${prefix}/fields/acm_items_registry_accountsaccounts_ida/value`);
    const accName = sAction.dataGet(`${prefix}/fields/acm_items_registry_accounts_name/value`);
    const contactId = sAction.dataGet(`${prefix}/fields/acm_items_registry_contactscontacts_ida/value`);
    const contactName = sAction.dataGet(`${prefix}/fields/acm_items_registry_contacts_name/value`);
    const fields = {
        account: {
            type: 'relate',
            label: sAction.translate('LBL_DUPCHECK_COMPANY_NAME', 'Accounts'),
            // readonly: true,
            module: 'Accounts',
            // defaultValue: {
            //     id: accId,
            //     name: accName
            // },
        },
        contact: {
            type: 'relate',
            label: sAction.translate('LBL_CONTACT_NAME', 'Contacts'),
            module: 'Contacts',
            // defaultValue: {
            //     id: contactId,
            //     name: contactName
            // },
            // defaultFilter: [{
            //     operandType: 'relate',
            //     parentId: accId,
            //     module: 'Accounts',
            //     relationship: ['accounts'],
            //     type: 'eq',
            //     name: 'account_name',
            //     relName: 'contacts',
            // }],
        },
    };
    const buttons = {
        cancel: {
            label: 'LBL_CANCEL_ACTION',
            icon: 'icon-undo',
            className: 'hoverRed',
            onClick: () => {sAction.popupHide()}
        },
        createUser: {
            label: sAction.translate('LBL_ASSIGN_TO_CONTACT', 'acm_cars'),
            icon: 'icon-addLineIcon',
            onClick: (data) => {assignToContact(data)}
        },
    }

    const assignToContact = (data) => {
        sAction.dsClear();
        // Contact
        if (data.contact) {
            sAction.dsAdd('set', `${prefix}/fields/acm_items_registry_contactscontacts_ida/value`, data.contact.id);
            sAction.dsAdd('set', `${prefix}/fields/acm_items_registry_contacts_name/value`, data.contact.name);
            sAction.dsAdd('set', `${prefix}/changes/fields/acm_items_registry_contactscontacts_ida`, data.contact.id);
        }

        // Account
        if (data.account) {
            sAction.dsAdd('set', `${prefix}/fields/acm_items_registry_accountsaccounts_ida/value`, data.account.id);
            sAction.dsAdd('set', `${prefix}/fields/acm_items_registry_accounts_name/value`, data.account.name);
            sAction.dsAdd('set', `${prefix}/changes/fields/acm_items_registry_accountsaccounts_ida`, data.account.id);
        }
        sAction.dsProcess();
        sAction.popupHide();
        const paramData = params.data.toJS();
        sAction.saveRecord(paramData);
    }

    return (
        <div className='importPopupContainer'>
            <div className='acmPopupHeader'>{sAction.translate('LBL_ASSIGN_TO_CONTACT', 'acm_cars')}</div>
            <SimpleFormFromArray fields={fields} buttons={buttons} />
        </div>
    )
}
