import detailDefault from "../../detailDefault";
export default class detailacm_orders_lines extends detailDefault{
    load(sAction,data){
        sAction.dsClear();
        if (!sAction.dataGet(data.prefix + '/customData')) {
            sAction.dsAdd('set', data.prefix + '/customData', {});
        }
        sAction.dsAdd('set', data.prefix + '/customData/canSave', true);
        sAction.dsProcess();
        this.setApproverVisibility(sAction, data, sAction.dataGet(`${data.prefix}/fields/role/value`));
        this.hideRepresentativeButton(sAction, data);
    }

    update(sAction, data){
        switch (data.field) {
            case 'user_name':
                this.checkUniqueUserName(sAction, data, data.value);
                break;
            case 'active':
                if (data.value == 1) {
                    let userName = sAction.dataGet(data.prefix + '/fields/user_name/value');
                    this.checkUniqueUserName(sAction, data, userName);
                } else {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/user_name/customClass', '');
                    sAction.dsAdd('set', data.prefix + '/canSave', true);
                    sAction.dsProcess();
                }
                break;
            case 'email1':
                this.changeUserName(sAction, data);
                break;
            case 'role':
                this.setApproverVisibility(sAction, data, data.value);
                break;
        }
    }

    // beforeSave(sAction, data){
    // }
    checkUniqueUserName(sAction, data, userName) {
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/canSave', false);
        sAction.dsAdd('set', data.prefix + '/customData/canSave', false);
        sAction.dsProcess();

        let id = sAction.dataGet(data.prefix + '/id');
        sAction.rest.post('isUserNameUnique', {userName: userName, userID: id}, returnData => {
            if (returnData.status === true) {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/user_name/customClass', '');
                sAction.dsAdd('set', data.prefix + '/canSave', true);
                sAction.dsAdd('set', data.prefix + '/customData/canSave', true);
                sAction.dsProcess();
            } else {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/user_name/customClass', 'error');
                sAction.dsAdd('set', data.prefix + '/customData/canSave', false);
                sAction.dsProcess();
                sAction.error(sAction.translate(returnData.errorMessage.text));
            }
        });
    }

    changeUserName(sAction, data) {
        let email = sAction.dataGet(data.prefix + '/def/emails/0/email_address');
        let prefix = data.prefix.split('/fields/email1')[0];
        sAction.dsClear();
        sAction.dsAdd('set', prefix + '/fields/user_name/value', email);
        sAction.dsAdd('set', prefix + '/changes/fields/user_name', email);
        sAction.dsProcess();
        let custData = data;
        custData.field = 'user_name';
        this.update(sAction, custData);
    }

    beforeSave(sAction, data) {
        if (sAction.dataGet(data.prefix + '/customData/canSave') === false) {
            sAction.error(sAction.translate('LBL_USERNAME_ALREADY_USED'));
            return false;
        } else {
            return true;
        }
    }

    /**
     * @param {object} sAction
     * @param {object} data
     * @param {string} value
     */
    setApproverVisibility(sAction, data, value) {
        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/approver/def/visible`, value === 'area_manager');
    }

    hideRepresentativeButton(sAction, data) {
        console.log('active');
        const representative = sAction.dataGet(data.prefix+'/fields/representative/value');
        const buttons = sAction.dataGet(data.prefix+'/buttons');
        sAction.dsClear();
        let i = 0;
        for (const element of buttons) {
            if (element.id === 'choose_representative' && representative) {
                sAction.dsAdd("delete", data.prefix + "/buttons", i);
                break;
            }
            if (element.id === 'cancel_representative' && !representative) {
                sAction.dsAdd("delete", data.prefix + "/buttons", i);
                break;
            }
        i++;
        }
        sAction.dsProcess();
    }
}
