import React from "react";
import PureComponent from "../../../pure";
import Button from "../../../formElements/Button";
import  sAction  from "sAction";

export default class RequestItemRejectPopup extends PureComponent {

    constructor(){
        super();

        this.state = {
            reason : "",
            customClass: "",
        };
    }

    handleConfirm(){
        let id = this.props.data.get("id");   

        const reject_reason = this.state.reason;
        if(!reject_reason){
            this.setState({customClass: "error"});
        }
        else{
            let params = this.props.data.get("params").toJS();
            //let prefix = this.props.data.get("prefix");   
            console.log("pre", params);

            
            sAction.dsClear();
            sAction.dsAdd("set", params.prefix+"/fields/stage/value", "declined");
            sAction.dsAdd("set", params.prefix+"/changes/fields/stage", "declined");

            sAction.dsAdd("set", params.prefix+"/fields/reject_reason/value", reject_reason);
            sAction.dsAdd("set", params.prefix+"/changes/fields/reject_reason", reject_reason);
            sAction.dsProcess();

            params.canSave = sAction.dataGet(params.prefix+"/canSave");

            sAction.popupHide();
            sAction.saveRecord(params);
            

        }
    }

    render(){
        return <div>
            <div className="itemRejectContent">
                <div className="itemRejectRow">
                    <div className="itemRejectLbl">
                        {sAction.translate("LBL_REJECT_REASON", "acm_requests_items")}
                        <span className="required">*</span>
                    </div>
                    <textarea 
                        className={this.state.customClass}
                        style={{height: '150px', width: '200px'}}
                        onBlur={(ev) => this.setState({"reason" : ev.target.value})}
                    />
                </div>
            </div>
            <div className="sfButtonRow sfButtons viewActionPanelButtons viewActionPopuBorromButtons">
                    <Button className="hoverGreen" onClick={() => this.handleConfirm()}>
                        <div className={"icon-saveIcon calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CONFIRM")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
        </div>;
    }

}
