import React from 'react';
import PropTypes from 'prop-types';

import sAction from 'sAction';

export default function PasswordRequirements(props) {
    const password = props.password || '';
    return (
        <React.Fragment>
            {(password.length < props.minLength || password.length > props.maxLength) && <div>
                {sAction.translateTemplate('LBL_PASSWORD_LENGTH_HINT', '', {min: props.minLength, max: props.maxLength})}
            </div>}
            {props.extraRequirements.filter((item) => !password.match(item.regex)).map((item, index) => (
                <div key={index}>{sAction.translate(item.hint)}</div>
            ))}
        </React.Fragment>
    );
}

PasswordRequirements.propTypes = {
    minLength: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired,
    extraRequirements: PropTypes.array.isRequired,
    password: PropTypes.string,
};
