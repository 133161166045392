import React from 'react';
import PureComponent from '../pure'

import TickBox from '../formElements/TickBox'

import  sAction  from "sAction";

class CalFilters extends PureComponent{

    updateFilters(name, value){
      sAction.dataSet("calendar/filters/"+name, value);
      this.applyFilters();

      //propsani do nastaveni
      sAction.dataSet("calendar/setting/"+name, value);
      let calSettings = sAction.dataGet("calendar/setting").toJS();
      calSettings[name] = value;
      sAction.rest.post("saveCalSettings", calSettings, (returnData) => {
        if (!returnData) {
          sAction.error(sAction.translate("LBL_ERROR_SAVING", "Calendar"));
        }
      });
  }

    applyFilters(){
      sAction.applyCalFilters();
    }

    componentDidUpdate(){
    }

    updateStateFilters(field, key, val){
      let pomStates = sAction.dataGet("calendar/filters/"+field).toJS();
      if(val){
        pomStates.push(key);
      }
      else{
        let pomInd = pomStates.indexOf(key);
        pomStates.splice(pomInd, 1);
      }

      this.updateFilters(field, pomStates);
     
    }

    getEventClass(val){
      switch (val){
        case 'Planned':
        case 'Not Started':
          return 'eventNotStarted';
        case 'Pending Input':
          return 'eventPending';
        case 'Held':
        case 'Completed':
          return 'eventSuccess';
        case 'Not Held':
        case 'Deferred':
          return 'eventFailed';
        default:
          return 'eventInProgress';
      }
    }

    render(){
      let classPom = "calFilters";
      let visibClass = "";

      const filt = localStorage.getItem('acmCalFiltersVisibility');
      /*
      if(filt == undefined || filt == 'visible'){
        classPom += " showFilters";
      }
      else{
        visibClass = " calHidden";
      }
      */
      
      if(this.props.data.filtersDisplayed){
        classPom += " showFilters";
      }
      else{
        classPom += " hideFilters";
        visibClass = " calHidden";
      }
      
      var meetOptions = [];
      sAction.app_strings.meeting_status_dom.forEachObject((lbl, key) => {
          meetOptions.push({value: key, label: lbl});
      });

      var callOptions = [];
      sAction.app_strings.call_status_dom.forEachObject((lbl, key) => {
          callOptions.push({value: key, label: lbl});
      });

      var taskOptions = [];
      sAction.app_strings.task_status_dom.forEachObject((lbl, key) => {
          taskOptions.push({value: key, label: lbl});
      });
      /*
      <MultiSelect open="false" name="meetStates" options={meetOptions} defaultValue={data.meetStates} onBlur={(value) => this.updateFilters("meetStates", value)} />
      <MultiSelect open="false" name="callStates" options={callOptions} defaultValue={data.callStates} onBlur={(value) => this.updateFilters("callStates", value)} />
      <MultiSelect open="false" name="taskStates" options={taskOptions} defaultValue={data.taskStates} onBlur={(value) => this.updateFilters("taskStates", value)} />
      */
      let data = this.props.data.toJS();

      let meetStateClass = "filterRowState";
      let callStateClass = "filterRowState";
      let taskStateClass = "filterRowState";

      if(!data.showMeetings){
        meetStateClass += " calNoDisplay"
      }

      if(!data.showCalls){
        callStateClass += " calNoDisplay"
      }

      if(!data.showTasks){
        taskStateClass += " calNoDisplay"
      }
      var pomMeetRender = [];
      meetOptions.forEachObject(opt => {
        let selVal = false;
        let itemClass = " notSelected";
        if(data.meetStates != null && data.meetStates.indexOf(opt.value) >= 0){
          selVal = true;
          itemClass = " selected";
        }
        pomMeetRender.push(<div key={"meetStates"+opt.value} className="calFiltersRow">
          <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("meetStates", opt.value, value)} />
          <span className={"calFilterLbl "+this.getEventClass(opt.value)+itemClass}>{opt.label}</span>
        </div>)

      });

      var pomCallRender = [];
      callOptions.forEachObject(opt => {
        let selVal = false;
        let itemClass = " notSelected";
        if(data.callStates != null && data.callStates.indexOf(opt.value) >= 0){
          selVal = true;
          itemClass = " selected";
        }
        pomCallRender.push(<div key={"callStates"+opt.value} className="calFiltersRow">
          <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("callStates", opt.value, value)} />
          <span className={"calFilterLbl "+this.getEventClass(opt.value)+itemClass}>{opt.label}</span>
        </div>)

      });

      var pomTaskRender = [];
      taskOptions.forEachObject(opt => {
        let selVal = false;
        let itemClass = " notSelected";
        if(data.taskStates != null && data.taskStates.indexOf(opt.value) >= 0){
          selVal = true;
          itemClass = " selected";
        }
        pomTaskRender.push(<div key={"taskStates"+opt.value} className="calFiltersRow">
          <TickBox className="calFiltersStateTick" checked={!!selVal} onChange={(value) => this.updateStateFilters("taskStates", opt.value, value)} />
          <span className={"calFilterLbl "+this.getEventClass(opt.value)+itemClass}>{opt.label}</span>
        </div>)

      });

      var openButtonClass = "navFiltToogle icon-arrowLeft";
      if (this.props.data.filtersDisplayed) {
        openButtonClass = "navFiltToogle navToogle icon-arrowRight";
      }

      /*
      <StandartMultiSelect className="calFiltersSelect" options={meetOptions} selected={data.meetStates} onChange={(value) => this.updateFilters("meetStates", value)} />
      <StandartMultiSelect className="calFiltersSelect" options={callOptions} selected={data.callStates} onChange={(value) => this.updateFilters("callStates", value)} />
      <StandartMultiSelect className="calFiltersSelect" options={taskOptions} selected={data.taskStates} onChange={(value) => this.updateFilters("taskStates", value)} />
      */
        let secComplRowClass = "";
        if(!+data.showCompleted){
          secComplRowClass = " notSelected";
        }

        let secMeetRowClass = "";
        if(!+data.showMeetings){
          secMeetRowClass = " notSelected";
        }

        let secCallRowClass = "";
        if(!+data.showCalls){
          secCallRowClass = " notSelected";
        }

        let secTaskRowClass = "";
        if(!+data.showTasks){
          secTaskRowClass = " notSelected";
        }

        return(
          <div className={classPom}>
            <div className={"calBoardHeader"+visibClass}>
              <div className="icon-CalFilterIcon calHeaderIcon"></div>
              <span className="calFilterTitle">{sAction.translate("LBL_FILTERS_TITLE", "Calendar")}</span>
            </div>
            <div className={"calFiltersContainer"+visibClass}>
            <div className="calFiltersSection firstSection">
              <div className={"calFiltersRow sectionRow"+secComplRowClass}>
              
                <TickBox className="calFiltersTick" name="showCompleted" checked={!!data.showCompleted} onChange={(value) => this.updateFilters("showCompleted", value)} />
                <span className="">{sAction.translate("LBL_SETTINGS_COMPLETED_SHOW", "Calendar")}</span>
              </div>
            </div>
            <div className="calFiltersSection">
              <div  className={"calFiltersRow sectionRow"+secMeetRowClass}>
                <TickBox className="calFiltersTick" name="showMeetings" checked={!!data.showMeetings} onChange={(value) => this.updateFilters("showMeetings", value)} />
                <span className="">{sAction.translate("LBL_SETTINGS_MEETINGS_SHOW", "Calendar")}</span>
              </div>
              <div  className={meetStateClass}>
                {pomMeetRender}
              </div>
            </div>
            <div className="calFiltersSection">
              <div  className={"calFiltersRow sectionRow"+secCallRowClass}>
              <TickBox className="calFiltersTick" name="showCalls" checked={!!data.showCalls} onChange={(value) => this.updateFilters("showCalls", value)} />
                <span className="">{sAction.translate("LBL_SETTINGS_CALLS_SHOW", "Calendar")}</span>
              </div>
              <div  className={callStateClass}>
                {pomCallRender}
              </div>
            </div>
            <div className="calFiltersSection lastSection">
              <div  className={"calFiltersRow sectionRow"+secTaskRowClass}>
                <TickBox className="calFiltersTick" name="showTasks" checked={!!data.showTasks} onChange={(value) => this.updateFilters("showTasks", value)} />
                <span className="">{sAction.translate("LBL_SETTINGS_TASKS_SHOW", "Calendar")}</span>
              </div>
              <div  className={taskStateClass}>
                {pomTaskRender}
              </div>
            </div>
            </div>
            <div className={openButtonClass} onClick={() => sAction.toggleCalcFilter()} />
          </div>
        )
    }
  }
  export default CalFilters
