import React from "react";
import PureComponent from "../../pure";
import  sAction  from "sAction";
import SimpleFormFromArray from "../../formElements/SimpleFormFromArray";

export default class ItemForItemPopup extends PureComponent {
    constructor(props) {
        super(props);
    }

    getFieldsArray() {
        return {
            name: {
                type: 'input',
                label: sAction.translate('LBL_NAME', 'acm_items_registry'),
                required: true,
            },
            amount: {
                type: 'number',
                label: sAction.translate('LBL_ITEMS_AMOUNT', 'acm_items_registry'),
                defaultValue: '1',
                required: true,
            },
            price: {
                type: 'number',
                label: sAction.translate('LBL_PRICE_PER_PIECE', 'acm_items_registry'),
                required: false,
            },
            date_recieved: {
                type: 'date',
                label: sAction.translate('LBL_DATE_RECIEVED', 'acm_items_registry'),
                required: true,
            },
            whoInserted: {
                type: 'relate',
                module: 'Users',
                label: sAction.translate('LBL_WHO_INSERTED', 'acm_items_registry'),
                // defaultValue: '',
                required: true,
                defaultFilter: [
                    {
                        field: 'status',
                        footer: false,
                        type: 'eq',
                        value: 'Active',
                    },
                    {
                        field: 'show_on_employees',
                        footer: false,
                        type: 'eq',
                        value: '1',
                    },
                ],
            },
        };
    }

    getButtonsArray() {
        return {
            back: {
                label: 'LBL_CANCEL',
                onClick: (returnData) => {
                    sAction.popupHide();
                },
                icon: 'icon-undo',
            },
            create: {
                label: 'LBL_CREATE_ITEM_RECIEVMENT',
                onClick: (returnData) => {
                    sAction.load();
                    let paramData = this.props.params;
                    sAction.rest.post('recieveVirtualItems', {itemID: this.props?.params?.id, ...returnData}, postData => {
                        sAction.unLoad();
                        sAction.routeDetailView(paramData, {});
                        sAction.popupHide();
                    });
                },
                icon: 'icon-addIcon',
                returnButtonName: false,
            },
        }
    }

    render() {
        let form = (
            <SimpleFormFromArray
                fields={this.getFieldsArray()}
                buttons={this.getButtonsArray()}
            />
        );

        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_RECEIVE_VIRTUAL_ITEMS')}</div>
                </div>
                <div className='receiveVirtualItems'>
                    {form}
                </div>
            </>
        );
    }
}
