import React from "react";
import PureComponent from "../../../pure";
import sAction from "sAction";
import SimpleFormFromArray from "../../../formElements/SimpleFormFromArray";

export default class RejectLoanRequestPopup extends PureComponent {
    constructor(props) {
        super(props);
    }

    getFieldsArray() {
        return {
            reason: {
                type: 'textarea',
                label: sAction.translate('LBL_REASON', 'acm_loan_requirement'),
                required: true,
            },
        };
    }

    getButtonsArray() {
        return {
            back: {
                label: 'LBL_CANCEL',
                checkRequired: false,
                onClick: (returnData) => {
                    sAction.popupHide();
                },
                icon: 'icon-undo',
            },
            change: {
                label: 'LBL_REJECT_REQUEST',
                onClick: (returnData) => {
                    sAction.load();
                    let paramData = this.props.params;
                    sAction.rest.post('rejectRequest', {id: paramData.id, reason: returnData.reason}, postData => {
                        sAction.unLoad();
                        sAction.route();
                        sAction.popupHide();
                    });
                },
                icon: 'icon-closeIconLight',
            },
        }
    }

    render() {

        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_REJECT_REQUEST')}</div>
                </div>
                <div className='massInsertItems'>
                    <SimpleFormFromArray
                        fields={this.getFieldsArray()}
                        buttons={this.getButtonsArray()}
                    />
                </div>
            </>
        );
    }
}
