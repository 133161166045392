import rest from "./rest";

export default function init(paramData, callback) {

  if(window.location.search != ""){
    var urlParams = new URLSearchParams(window.location.search);
    var urlData = {};
    urlParams.forEach((val,key) => {
      urlData[key] = val;
    });
    urlData = this.toBase64(urlData);
    var r = urlParams.get("r");
    if(r != null){
      window.location.href = window.location.origin+"/#"+r+"/"+urlData;
    }
  }


  this.dataReducer = "app";
  this.param = paramData;

  this.notificationInterval = null;
  this.currencies = null;
  this.customClass = null;
  this.colorTemplate = this.param.is_portal ? new Array(
    "#93D811",
    "#FFB100",
    "#F63D5E",
    "#C4438C",
    "#7D5398",
    "#405481",
    "#2F4858",
    "#64BAAA",
    "#00E9FF",
    "#00B3AA",
    "#18C25B",
    "#00A680",
    "#00878B",
    "#00677C",
    "#2F4858",
  ) : new Array(
    "#898CFF",
    "#71E096",
    "#5AD0E5",
    "#BB96FF",
    "#FF89B5",
    "#F5A26F",
    "#DA97E0",
    "#EFCE7E",
    "#668DE5",
    "#90D4F7",
    "#ED6D79",
    "#FF96E3",
    "#93BFDC",
    "#E09399",
    "#B6D96E",
    "#FF9393",
    "#79CB9F",
    "#BDCCAA",
    "#5AD0E5",
    "#65D8CC"
  );
  this.detailTempData = {};
  this.rest = new rest(this);
  this.objectLoopInit();
  this.overwriteStyle();

  if (window.location.hash.startsWith("#custom/")) {
    this.routeCustom();
    return;
  }

  this.hashChangeEvent();
    this.rest.get("checksid", data => {
        this.initData(data);
        if (this.getViewName() === 'login') {
            this.illegallyChangeHash('#home');
        }
        this.route(true, data);
        this.renderReact();
        this.checkNewNotifications();
        if (data.acm_messages) {
            this.messages = () => {
                if (data.acm_messages) {
                    this.displayAcmMessages(data.acm_messages);
                }
            };
        }
    });
}
