import React from "react";
import PureComponent from "../../pure";

import Button from "../../formElements/Button";
import InputText from "../../../components/formElements/InputText";

import sAction from "sAction";
import SimpleFormFromArray from '../../formElements/SimpleFormFromArray';

export default class ItemReturnPopup extends PureComponent {
    handleConfirm(data){
        const id = this.props.data.get("data").get("params").get("id");

        sAction.load();
        sAction.rest.post('itemReturn', {
            id : id,
            ship_num: data.shipmentNum,
            reasonForReturn: data.reasonForReturn
            // storage: data.storage.id,
        }, returnData => {
            sAction.unLoad();

            if(returnData.status){
                sAction.hrefModuleList({relModule : 'acm_items_registry'});
                //location.reload();
            }
            else{
                sAction.error(returnData.errorMessage)
            }
        });
    }

    getButtons() {
        return {
            cancel: {
                onClick: () => sAction.popupHide(),
                className: 'hoverRed',
                label: 'LBL_CANCEL_ACTION',
                checkRequired: false,
                icon: 'icon-close',
            },
            return: {
                onClick: (data) => this.handleConfirm(data),
                className: 'hoverGreen',
                label: 'LBL_CONFIRM',
                icon: 'icon-saveIcon',
            },
        }
    }

    getFields() {
        return {
            shipmentNum: {
                type: 'varchar',
                required: true,
                label: sAction.translate('LBL_SHIPMENT_NUM', 'acm_items_registry'),
            },
            reasonForReturn: {
                type: 'text',
                required: true,
                label: sAction.translate('LBL_REASON_FOR_RETURN', 'acm_items_registry'),
            }
        }
    }

    render(){
        return <div>
                <div className="acmPopupContent">
                    <SimpleFormFromArray
                        buttons={this.getButtons()}
                        fields={this.getFields()}
                        style={{width: '100%'}}
                    />
                </div>
        </div>;
    }
} 
