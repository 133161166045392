import React, {PureComponent} from 'react';
import propTypes from 'prop-types';
import {Map} from 'immutable';
import DetailViewFieldsManager from 'ROOT/src/components/detailView/DetailViewFieldsManager/DetailViewFieldsManager';

export default class ReportRelate extends PureComponent {
    render() {
        const def = new Map({
            id_name: this.props.value?.name ?? '',
            id_value: this.props.value?.id ?? '',
            name: this.props.name,
            vname: this.props.vname,
            type: 'relate',
            module: this.props.module,
            table: this.props.module.toLowerCase(),
            required: this.props.required ?? false,
        });

        return (
            <DetailViewFieldsManager
                way={'report'}
                index={0}
                mode={'report'}
                percent={this.props.width}
                onChange={this.props.onChange}
                name={this.props.name}
                value={this.props?.value?.name ?? ''}
                def={def}
                module
                readonly={this.props.readonly}
            />
        );
    }
}

ReportRelate.propTypes = {
    onChange: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    vname: propTypes.string.isRequired,
    value: propTypes.object.isRequired,
    module: propTypes.string.isRequired,
    width: propTypes.number,
    required: propTypes.bool,
    readonly: propTypes.bool,
};
