import sAction from "sAction";
import React, {PureComponent} from "react";

/**
 * Componenta renderuje stav pole pred kliknutim tudiz s proklikavatelnymi linky a ocickami pro kazdou hodnotu,
 * pokud jsou v poli vic jak 3 hodnoty zobrazi sipka a moznost zobrazit vsechny moznosti
 * @parent components/detailView/DetailViewSpanValue
 * @param props module: momentalni modul, prefix:, data: Record obsahujici jmeno honodonu a definici pole, def je mapa
 * takze potreba prisupovat pres .get(),
 * @return {JSX.Element}
 * @constructor
 */
export default class MultirelateView extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showAll: props.data.def.get("showAll") ? props.data.def.get("showAll") : false
        }
    }

    showAllOptions = (event) => {
        event.stopPropagation()
        this.setState(prevState => ({
            showAll: !prevState.showAll
        }), () => {
            sAction.dataSet(`${this.props.prefix}/fields/${this.props.data.name}/def/showAll`, this.state.showAll)
        })
    }

    fillValues(options) {
        const value = []
        const ids = this.props.data.def.get("options")?.get("id")
        const rel_type = this.props.data.def.get("options")?.get("rel_type")

        options.forEach((element, index) => {
            value.push(
                <div key={index} className="multiRelateItem">
                    <div onClick={(e) => this.props.show(e, {
                        module: this.props.data.def.get("targetModule"),
                        id: ids.get(index),
                    })} className="icon-eye detailViewFieldIconEye"/>
                    <a href={`#detail/Accounts/${ids.get(index)}`}>{element}</a>
                    { this.props.data.def?.get("displayRelType") && rel_type?.get(index)
                        ? <div className="relTypeField">{`(${sAction.translate(rel_type?.get(index))})`}</div> : null}
                </div>)
        })

        return value
    }

    render() {
        let value = []
        let dropdownArrow = null

        if (this.props.data.def.get("options")) {
            if (this.state.showAll) {
                value = this.fillValues(this.props.data.def.get("options")?.get("names"))
            } else {
                value = this.fillValues(this.props.data.def.get("options")?.get("names").slice(0, 3))
            }

            if (this.props.data.def.get("options")?.get("names").size > 3) {
                let iconClass = 'icon-arrowDownLight'
                let showOptionsTranslation = 'LBL_SHOW_MORE_OPTIONS'
                if (this.state.showAll) {
                    iconClass = 'icon-arrowUpLight'
                    showOptionsTranslation = 'LBL_SHOW_LESS_OPTIONS'
                }

                dropdownArrow =
                    <div key={iconClass} className="multiRelateItem showMultirelateOptions" onClick={this.showAllOptions} >
                        <div className={`detailViewFieldIconEye ${iconClass}`}/>
                        <span>{sAction.translate(showOptionsTranslation)}</span>
                    </div>

                value.push(dropdownArrow)
            }
        }

        return (
            <div className="multirelateContainer">
                {value ? value : null}
            </div>
        )
    }

}