import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';
import sAction from 'sAction';
import Button from '../formElements/Button';
import Input from '../formElements/InputText';
import Select from '../formElements/Select';

export default class LoginForm extends PureComponent {
    constructor() {
        super();
        this.state = {
            name: true,
            pass: true,
            language: sAction.getLanguage(),
        };
        this.name = React.createRef();
        this.pass = React.createRef();
    }

    /**
     * submit login form
     */
    submit() {
        const name = this.name.current.value;
        const pass = this.pass.current.value;
        const language = this.state.language;
        let nameState = true;
        if (name === '' || name == null) {
            nameState = false;
        }
        let passState = true;
        if (pass === '' || pass == null) {
            passState = false;
        }

        if (passState && nameState) {
            sAction.logIn(name, pass, language, this.props.switchCallback);
            this.setState({name: true, pass: true});
        } else {
            this.setState({name: nameState, pass: passState});
        }
    }

    /**
     * submit on enter
     * @param {KeyboardEvent} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.submit();
        }
    }

    /**
     * handle language select
     * @param {string} lang language code
     */
    setlanguage(lang) {
        sAction.rest.setCookie('language', lang);
        window.location.reload();
    }

  render() {
    var options = [];
    try {
      sAction.app_strings.languageList.forEachObject((value,key) => {
        options.push({ value: key, label: value });
      });
    } catch (e) {
      options.push({
        value: "cs_CZ",
        label: sAction.translate("LBL_LOGIN_LANGUAGE_CZECH")
      });
      options.push({
        value: "en_us",
        label: sAction.translate("LBL_LOGIN_LANGUAGE_ENGLISH")
      });
      options.push({
        value: "sk_SK",
        label: sAction.translate("LBL_LOGIN_LANGUAGE_SLOVAK")
      });
    }
    return (
      <table className="loginTable" onKeyDown={e => this.onKeyDown(e)}>
        <tbody>
          <tr>
            <td colSpan="2" className="login PageLogoContainer">
              <img src={sAction.param.companyLogo} />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Input
                autoFocus={true}
                error={!this.state.name}
                myRef={this.name}
                label={sAction.translate("LBL_LOGIN_NAME")}
                variant="outlined"
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <Input
                type="password"
                error={!this.state.pass}
                myRef={this.pass}
                label={sAction.translate("LBL_LOGIN_PASSWORD")}
                variant="outlined"
              />
            </td>
          </tr>
          <tr>
            <td>
              <Select
                value={this.state.language}
                containerClassName={"withBorder"}
                onChange={(e) => this.setlanguage(e.target.value)}
                label={sAction.translate("LBL_LOGIN_LANGUAGE")}
                options={options}
                variant="outlined"
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className="loginButtonContainer">
                <Button className="loginButton" onClick={() => this.submit()}>
                  {sAction.translate("LBL_LOGIN","default")}
                </Button>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="2">
              <div className="loginButtonContainer">
                <a onClick={() => this.props.switchCallback('resetPassword', {prefilledUsername: this.name.current.value})}>
                  {sAction.translate("LBL_FORGOTTEN_PASSWORD")}
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

LoginForm.propTypes = {
    logo: PropTypes.string,
    redirect: PropTypes.string,
    switchCallback: PropTypes.func.isRequired,
};
