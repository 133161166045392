export default function portalModuleSetVisibility(params){
console.log("HOHO", params);



    let paramData = {
        defaultFilter: [],
        module: "Accounts",
    }

    this.openRelatePopup(paramData, (returnData) => {
        let records = returnData.id;
        const filterData = records !== "all" ? null : this.getListviewFilter("conf/popup/data/listView");
        
        this.popupHide();
        this.load();

        if(!Array.isArray(records) && records != "all"){
            records = [records];
        }

        const sendRestData = {
            records : records,
            filterData : filterData,
            id: params.id,
            module: params.module,
        }

        this.rest.post("portalSetVisibility", sendRestData, (returnRestData) => {
            this.unLoad();
            console.log("returnRestData", returnRestData);
            if(returnRestData.status === false){
                this.error(returnRestData.errorMessage);
            }

            
        });
        
    });

}