export default function acmLegalDocEmail(params){
    const data = {
        parent_type: params.module,
        parent_id: params.id,
        parent_name: params.name,
        to_addrs: "",
        emailTemplate : "",
        keepSelf: true,
    };
    
    this.openEmailPopup(data);
}