import React from "react";
import PureComponent from "../pure";
import MenuGroup from "./menuGroup";
import sAction from "sAction";
class Menu extends PureComponent {
  hideLabel(e) {
    const data = this.props.data;
    if (data.open == false) {
      var label = document.querySelector(".menuHoverLabel");
      label.style.display = "none";
    }
  }
  constructor(props){
    super(props)
    this.coripoVersion = document.querySelector("#coripoVersion").value
    this.clientVersion = document.querySelector("#clientVersion").value
  }
  render() {
    const data = this.props.data;
    const openGroups = data.openGroups;
    const alertMessage = this.props.alertMessage;
    let groupsRender = [];
    if (data.groups.size > 1) {
      data.groups.forEach((group, groupIndex) => {
        groupsRender.push(
          <MenuGroup
            data={group}
            key={groupIndex}
            groupIndex={groupIndex}
            activeModule={data.activeModule}
            open={openGroups.get(groupIndex) === true}
            menuOpen={data.open}
          />
        );
      });
    } else if (data.groups.size == 1) {
      groupsRender = (
        <MenuGroup
          data={data.groups.get(0)}
          groupIndex={0}
          activeModule={data.activeModule}
          oneGroup={true}
          open={true}
          menuOpen={data.open}
        />
      );
    }
    var openButtonClass = "navToogle icon-arrowLeft";
    if (data.open == false) {
      openButtonClass = "navToogle icon-arrowRight";
    }

    var navClass = "";
    if (data.open == false) {
      navClass += " min";
    }
    if (alertMessage) {
      navClass += " alertMessageActive";
    }

    return (
      <nav key="nav" className={navClass}>
        <div
          className={
            data.groups.size > 1 ? "buttonsHolder withGroups" : "buttonsHolder"
          }
          onMouseLeave={() => this.hideLabel()}
        >
          {groupsRender}
        </div>
        <div className="menuCreatedBy"
        title={"CORIPO: "+this.coripoVersion+" \nBUILD: " +this.clientVersion}
        >
          {"Created by"}
          <a href="https://coripo.cz/" target="_blank">
            <img src={sAction.param.acmarkLogo} />
          </a>
        </div>
        <div className={openButtonClass} onClick={() => sAction.menuOpen()} />
      </nav>
    );
  }
}

export default Menu;
