import React, { PureComponent } from "react";
import InputText from "../../../components/formElements/InputText";
import  sAction  from "sAction";
// import DialogContent from "@material-ui/core/DialogContent"
import Button from "ROOT/src/components/formElements/Button";
import Loader from "../../../components/loader";

export default class ItemReceptionPopup extends React.Component {

  serNumArr = ['tablets', 'sensors', 'pay_terminal', 'phones', 'omv', 'sim']

  constructor(props) {
    super(props);

    const records = this.props.data.get('records');
    let recPom = {};
    records.forEach((item, rec) => {
      recPom[rec] = 
        {
          id : rec,
          name : item.get("name"),
          item_type : item.get("item_type"),
          serial_no : item.get("serial_no"),
          num : "",
          description: "",
          errorClass: "",
        };
      
    })


    this.state = {
      records : recPom,
    }
    
  }
  
  
  savePopup = () => {
  
    let records = this.state.records;
    let err = false;

    records.forEachObject(item => {
      if(this.serNumArr.indexOf(item.item_type) != -1){
        //povinne seriove cislo
        if(!item.num){
          err = true;
          item.errorClass = "error";
          //return;
        }
        else{
          item.errorClass = "";
        }
      }
      else{
        //povinny popis
        if(!item.description){
          err = true;
          item.errorClass = "error";
          //return;
        }
        else{
          item.errorClass = "";
        }
      }
    })

    
       
    records.forEachObject(item => {
        if(this.serNumArr.indexOf(item.item_type) != -1){
        //kontrola serioveho cisla
            let lastSix = item.serial_no.substring(item.serial_no.length - 6);
            if(lastSix === item.num){
                item.errorClass = "";
            }
            else{
                //seriove cislo neodpovida
                err = true;
                item.errorClass = "error";
            }
        }
    });

    if(err){
      this.setState({
        records : records
      })
      //sAction.error(sAction.translate("ERR_FILL_REQUIRED_ITEMS", "Emails"));
      return;
  }



    sAction.load();
    
    sAction.rest.post("itemReception", {
      records: records,
    }, (data) => {
      sAction.popupHide();
      sAction.unLoad();
      if (data.status == true) {
        let callback = this.props.data.get('callback');
        if(callback){
          callback();
        }
      } else {
        sAction.error(sAction.translate(data.message));
      }
    });

  };

  setVal(id, field, val){
    let records = this.state.records;
    records[id][field] = val;
    this.setState({
      records : records
    })
  }
  
  render() {
    const records = this.state.records;

    let serComp = [];

    
    let ser_num_lbl = sAction.translate("LBL_ITEM_SERIAL_NUMBER","acm_items_registry");
    let desc_lbl = sAction.translate("LBL_ITEM_NOTE","acm_items_registry");

    records.forEachObject((item) => {
        let descReq = false;
        if(this.serNumArr.indexOf(item.item_type) != -1){
            serComp.push(
                <tr key={item.id+"_num2"} className="realeaseTableRow">
                    <td colSpan={2} className='releaseTableItemName'>
                        {item.name}
                    </td>
                </tr>
            );

          serComp.push(
          <tr key={item.id+"_num"} className="realeaseTableRow">
            <td>{sAction.translate('LBL_SERIAL_NO','acm_items_registry')}<span className='required'>*</span></td>
            <td>
                <div style={{display: 'flex'}}>
                    <div style={{width: 'fit-content', paddingTop: !item.errorClass ? '8px' : '23px', textAlign: 'right', paddingRight: '4px', flex: '1 0 auto'}}>
                        {item.serial_no.substring(0, item.serial_no.length - 6)}
                    </div>
                    <div style={{width: '50%', flex: '10 0 auto'}}>
                        <InputText
                            defaultValue={item.num}
                            id="num"
                            className={""+item.errorClass}
                            type="text"
                            placeholder={ser_num_lbl}
                            onChange={(ev) => this.setVal(item.id, "num", ev.target.value)}
                            fullWidth
                            error={!!item.errorClass}
                            label={item.errorClass ? sAction.translate('LBL_INVALID_LAST_6_DIGITS', 'acm_items_registry') : ''}

                        />
                    </div>
                </div>
            </td>
          </tr>
          );
        }
        else{
          descReq = true;
          serComp.push(
            <tr key={item.id+"_num"} className="realeaseTableRow">
              <td className='releaseTableItemName'>{item.name}</td>
              <td></td>
            </tr>
            );
        }

        let inputClass = "withBorder";
        if(descReq){
          inputClass +=  " "+item.errorClass;
        }

        serComp.push(
          <tr key={item.id+"_desc"} className="realeaseTableRow descRow">
            <td>{desc_lbl}{descReq && <span className='required'>*</span>}</td>
            <td>
            <InputText
                defaultValue={item.description}
                id="num"
                className={inputClass}
                type="text"
                onChange={(ev) => this.setVal(item.id, "description", ev.target.value)}
                fullWidth
              />
            </td>
          </tr>
        );


    });

    return (<React.Fragment>
      <div className="acmPopupHeader">{sAction.translate("LBL_CONFIRM")}</div>
      <div className="itemRegReleaseBody">
      <table cellPadding="0" cellSpacing="0" className="w100">
        <tbody>
        {serComp}
        </tbody>
      </table>
        
      </div>
      <div className="sfButtonRow sfButtons viewActionPanelButtons viewActionPopuBorromButtons">
          <Button className="hoverGreen" onClick={() => this.savePopup()}>
            <div className={"icon-saveIcon calHeaderIcon"}/>
            {sAction.translate("LBL_CONTINUE")}
          </Button>
          <Button className="hoverRed" onClick={() => sAction.popupHide()}>
            <div className={"icon-close calHeaderIcon"}/>
            {sAction.translate("LBL_CANCEL_ACTION")}
          </Button>
        </div>
      </React.Fragment>);
  }
}

