import detailDefault from "../../detailDefault";

export default class detailacm_testovaci_modul extends detailDefault {
    load(sAction, data){
        const defaultFilter = [
            {
                module: "Accounts",
                field: "name",
                value: "test",
                type: "cont",
            },
        ];

        sAction.dsClear()
        sAction.dsAdd(
            "set",
            data.prefix +
            "/fields/vazba/def/defaultFilter",
            defaultFilter
        );
        sAction.dsProcess();
    }
}