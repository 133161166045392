import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';
import Button from '../formElements/Button';
import Input from '../formElements/InputText';
import sAction from 'sAction';

export default class ResetPassRequest extends PureComponent {
    constructor() {
        super();

        this.state = {
            errorMessage: '',
            error: false,
            message: '',
        };
        this.name = React.createRef();
    }

    /**
     * submit on enter
     * @param {KeyboardEvent} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.createResetPassRequest();
        }
    }

    /**
     * submit pasword reset request
     */
    createResetPassRequest() {
        const login = this.name.current.value;
        if (!login) {
            this.setState({error: true, errorMessage: '', message: ''});

            return;
        }
        const self = this;
        sAction.createPasswordResetToken(login, (data) => {
            if (!data['status']) {
                self.setState({error: true, errorMessage: data['message'], message: ''});

                return;
            }
            self.setState({error: false, message: data['message'], errorMessage: ''});
        });
    }

    render() {
        const ret = !this.state.error && this.state.message ? this.getMessage() : this.getForm();

        return (
            <table className="loginTable" onKeyDown={(e) => this.onKeyDown(e)}>
                <tbody>
                    <tr>
                        <td colSpan="2" className="login PageLogoContainer companyLogoResetPass"><img src={sAction.param.companyLogo} /></td>
                    </tr>
                    {ret}
                </tbody>
            </table>
        );
    }

    /**
     * render reset password request form
     * @returns {JSX.Element}
     */
    getForm() {
        const errorMessage = this.state.errorMessage ? sAction.translate(this.state.errorMessage) : "";
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className='center error'>
                            {errorMessage}
                        </div>
                        <Input
                            autoFocus={true}
                            error={this.state.error}
                            myRef={this.name}
                            label={sAction.translate('LBL_LOGIN_NAME')}
                            variant="outlined"
                            defaultValue={this.props.prefilledUsername}
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <Button
                                className="loginButton"
                                onClick={() => this.createResetPassRequest()}
                            >
                                {sAction.translate('LBL_RESET_PASSWORD')}
                            </Button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            {sAction.translate('LBL_FORGOTTEN_PASSWORD_RETURN')}
                            &nbsp;
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate('LBL_FORGOTTEN_PASSWORD_RETURN_BUTTON')}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    /**
     * render message
     * @returns {JSX.Element}
     */
    getMessage() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className={'center'}>
                            {sAction.translate(this.state.message)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate('LBL_BACK_TO_LOGIN')}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

ResetPassRequest.defaultProps = {
    prefilledUsername: '',
};

ResetPassRequest.propTypes = {
    goToLogin: PropTypes.func.isRequired,
    prefilledUsername: PropTypes.string,
};
