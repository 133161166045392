import React from 'react';

import sAction from 'sAction';
import {AuthAdminContext} from 'ROOT/src/contexts/AuthAdminContext';
import ReportCheckbox from 'ROOT/src/customReports/reportCommon/fields/ReportCheckbox';
import ReportTextField from 'ROOT/src/customReports/reportCommon/fields/ReportTextField';
import ReportRelate from 'ROOT/src/customReports/reportCommon/fields/ReportRelate';

export default function TwoFactorSettings() {
    const {twoFactorSettings, updateTwoFactorSettings, emailTemplates, updateEmailTemplates} = React.useContext(AuthAdminContext);

    /**
     * update single boolean value for 2FA settings
     * @param {string} key setting to update
     * @param {string} value new value
     * @returns {void}
     */
    const checkboxConvertor = (key, value) => updateTwoFactorSettings(key, !!value);

    /**
     * update single numeric value for 2FA settings
     * @param {string} key setting to update
     * @param {string} value new value
     * @returns {void}
     */
    const numberConvertor = (key, value) => updateTwoFactorSettings(key, parseInt(value) || 0);

    return <div className="detailViewTabContentBlok select">
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_2FA_SETTINGS_GENERAL', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_2FA_ENABLE', 'Administration')}
                name={'enable'}
                value={twoFactorSettings.enable? 1: 0}
                onChange={checkboxConvertor}
            />
            <ReportCheckbox
                vname={sAction.translate('LBL_2FA_REQUIRED', 'Administration')}
                name={'required'}
                value={twoFactorSettings.required? 1: 0}
                onChange={checkboxConvertor}
                width={100}
                readonly={!twoFactorSettings.enable}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_2FA_CODE_EXPIRE', 'Administration')}
                name={'codeExpire'}
                value={twoFactorSettings.codeExpire}
                onChange={numberConvertor}
                width={100}
                readonly={!twoFactorSettings.smsApiEnable || !twoFactorSettings.enable}
            />
            <ReportTextField
                vname={sAction.translate('LBL_2FA_CODE_LENGTH', 'Administration')}
                name={'codeLength'}
                value={twoFactorSettings.codeLength}
                onChange={numberConvertor}
                width={100}
                readonly={!twoFactorSettings.smsApiEnable || !twoFactorSettings.enable}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_2FA_EMAIL_SETTINGS', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_2FA_EMAIL_ENABLE', 'Administration')}
                name={'emailEnable'}
                value={twoFactorSettings.emailEnable? 1: 0}
                onChange={checkboxConvertor}
                readonly={!twoFactorSettings.enable}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportRelate
                vname={sAction.translate('LBL_2FA_EMAIL_TEMPLATE', 'Administration')}
                name={'twoFactorAuth'}
                value={emailTemplates.twoFactorAuth}
                onChange={updateEmailTemplates}
                module={'EmailTemplates'}
                width={100}
                readonly={!twoFactorSettings.emailEnable || !twoFactorSettings.enable}
            />
            <ReportRelate
                vname={sAction.translate('LBL_2FA_EMAIL_TEMPLATE_PORTAL', 'Administration')}
                name={'twoFactorAuthPortal'}
                value={emailTemplates.twoFactorAuthPortal}
                onChange={updateEmailTemplates}
                module={'EmailTemplates'}
                width={100}
                readonly={!twoFactorSettings.emailEnable || !twoFactorSettings.enable}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_2FA_SMS_API_SETTINGS', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_2FA_SMS_API_ENABLE', 'Administration')}
                name={'smsApiEnable'}
                value={twoFactorSettings.smsApiEnable? 1: 0}
                onChange={checkboxConvertor}
                readonly={!twoFactorSettings.enable}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_2FA_SMS_TEXT_TEMPLATE', 'Administration')}
                name={'smsTextTemplate'}
                value={twoFactorSettings.smsTextTemplate}
                onChange={updateTwoFactorSettings}
                width={100}
                readonly={!twoFactorSettings.smsApiEnable || !twoFactorSettings.enable}
                help={sAction.translate('LBL_2FA_SMS_TEXT_TEMPLATE_HELP', 'Administration')}
            />
            <ReportTextField
                vname={sAction.translate('LBL_2FA_SMS_TEXT_TEMPLATE_PORTAL', 'Administration')}
                name={'smsTextTemplatePortal'}
                value={twoFactorSettings.smsTextTemplatePortal}
                onChange={updateTwoFactorSettings}
                width={100}
                readonly={!twoFactorSettings.smsApiEnable || !twoFactorSettings.enable}
                help={sAction.translate('LBL_2FA_SMS_TEXT_TEMPLATE_HELP', 'Administration')}
            />
        </div>
    </div>;
}
