import React from "react";
import PureComponent from "../../pure";
import Button from "../../formElements/Button";

import sAction from "sAction";

export default class PortalMessageContainer extends PureComponent {

  handleConfirm(data){

    sAction.rest.post("portalMessageConfirmRead", {id: data.get("id")},(retData) => {
      let callBack= data.get('onClose');
      if(callBack){
        callBack();
      }
    });

    sAction.popupHide();

    

  }

  render() {
    const data = this.props.data.get("data");

    return (
      <div className="acmPortalMessageContainer">
            <div className='acmPortalMessageBody' dangerouslySetInnerHTML={{__html: data.get("body")}} />
            <div className='acmPortalMessageFooter'>
                <Button className="hoverGreen" onClick={() => this.handleConfirm(data)}>
                  <div className={"icon-saveIcon calHeaderIcon"} ></div>
                    {sAction.translate("LBL_CONFIRM")}
                  </Button>
            </div>
      </div>
    );
  }
}
