import React from "react";

import Button from "../../formElements/Button";
import sAction from "sAction";

export default class File extends React.PureComponent {
  constructor(props) {
    super(props);

    this.canDownload = true;
  }
  newFile = () => {
    sAction.processFileInput({ multiple: false, way: this.props.way }, file => {

      this.deleteFile();

      sAction.uploadCacheFile(file, files => {
        this.canDownload = false;
        const file = files.ids[0];
        sAction.saveField({
          way: this.props.way,
          name: this.props.data.name,
          type: this.props.data.def.get("type"),
          value: file
        });
      });
    });
  };

  deleteFile = () => {
    const data = this.props.data;

    let fileId;
    if (data.value && data.value.indexOf("::") !== -1) {
      [fileId] = data.value.split("::");
    } else {
      fileId = data.def.getIn(["file", "id"]);
    }

    sAction.saveField({
      way: this.props.way,
      name: this.props.data.name,
      type: this.props.data.def.get("type"),
      value: {
        file: {
          deleteFile: true,
          id: fileId
        }
      }
    });
  }

  render() {
    let value = "";
    let data = this.props.data;
    const module = this.props.module;
    const readonly = data.def.get("readonly") || this.props.readonly;
    if (data.value) {
      let fileId, fileName;
      if (data.value.indexOf("::") !== -1) {
        [fileId, fileName] = data.value.split("::");
      } else {
        fileId = data.def.getIn(["file", "id"]);
        fileName = data.value;
      }
      if (this.canDownload) {
        value = (
          <a
            className="fileLink"
            onClick={() =>
              sAction.downloadFile({
                id: fileId,
                module: "FieldFile",
                realModule: module,
                name: fileName,
                params: sAction.objectToFormdata({ forceDownload: true })
              })
            }
            title={fileName}
          >
            {fileName}
          </a>
        );
      } else {
        value = <span className="fileName" title={fileName}>{fileName}</span>;
      }
    }

    const deleteFile = <Button className="onlyIconButton blueTextButton"
      onClick={this.deleteFile}
      title={"Smazat"}
    >
      <div className="icon-deleteIcon" />
    </Button>

    return (
      <div className="detailViewFieldFile">
        {value || <span className="fileName">{"žádná příloha"}</span>}
        {readonly !== true &&
        <div className="buttons">
          {value && deleteFile}
          <Button className="onlyIconButton blueTextButton"
            onClick={this.newFile}
            title={sAction.translate("LBL_ADD_FILE", "Emails")}
          >
            <div className="icon-upload" />
          </Button>
        </div>
        }
      </div>
    );
  }
}
