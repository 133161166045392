import React from "react";
import sAction from "sAction";

export default function FileName(props) {
  var lineRender = null;
    lineRender = <a title={props.value} href={"#detail/" + props.module + "/" + props.id}>{props.value}</a>;
 

  var className = "newListViewLine";
  if(props.extraClass !== undefined){
      className += " "+props.extraClass
  }

  let showImage = <div className="icon-eye inactive notAllowedClass">&nbsp;</div>;
  if (/\.(bmp|jpg|jpeg|png|gif|svg|webp|ico)$/i.test(props.value)) {
    showImage = <div className="icon-eye pointerClass" onClick={() => sAction.openRightPanel("image", {name: props.value, url: sAction.param.files+"image/"+ props.id})}>&nbsp;</div>;
  }

  return (
    <div className={className}><div onClick={(e) => {props.onGetFile({id:props.id,module:props.module})}} className="icon-download listDownLoadIcon"></div>{showImage}{lineRender}</div>
  );
}
