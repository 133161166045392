import  sAction  from "sAction";

export default function clearCalDetailStore(prefix = null){

    sAction.dataSet("calendar/activeTab", "detailMeeting");

      var action = {
        type: "CALENDARCLEARDATA",
        content: {prefix: prefix},
        function: sAction
      };

      sAction.dsClear();
      sAction.dsAddCustom(action);
      sAction.dsProcess();
}