import React, { useState } from 'react';
import sAction from '../../../action';
import SimpleFormFromArray from '../../formElements/SimpleFormFromArray';
import Loader from '../../loader';

export default function ChooseRepresentativePopup(params) {
    const [load, setLoad] = useState(false);
    const accId = sAction.dataGet('view/fields/acm_portal_users_accountsaccounts_ida/value');
    const fields = {
        portalUser: {
            type: 'relate',
            label: sAction.translate('LBL_REPRESENTATIVE', 'acm_portal_users'),
            module: 'acm_portal_users',
            required: true,
            defaultFilter: [{
                operandType: "relate",
                parentId: accId,
                module: "Accounts",
                relationship: ["acm_portal_users_accounts"],
                name: "acm_portal_users_accounts",
                relName: "acm_portal_users",
                type: "eq",
            },
        ],
        },
        expirationDate: {
            type: 'date',
            label: sAction.translate('LBL_EXPIRATION_DATE', 'acm_portal_users'),
        },
    };
    const buttons = {
        cancel: {
            label: 'LBL_CANCEL_ACTION',
            icon: 'icon-undo',
            className: 'hoverRed',
            onClick: () => {sAction.popupHide()},
            checkRequired: false,
        },
        createUser: {
            label: sAction.translate('LBL_CHOOSE_REPRESENTATIVE', 'acm_portal_users'),
            icon: 'icon-addLineIcon',
            onClick: (data) => {createRepresentative(data)},
        },
    }
    const createRepresentative = (data) => {
        setLoad(true);
        const representativeId = data.portalUser.id;
        const expirationDate = data.expirationDate;
        const userName = sAction.dataGet('view/name');
        const userId = sAction.dataGet('view/fields/id/value');
        sAction.rest.post('sendEmailChooseRepresentative', { representativeId, userName, expirationDate, userId }, res => {
            if (res.status) {
                sAction.route();
                sAction.popupHide();
            } else {
                setLoad(false);
                sAction.error(sAction.translate(res.message.text));
            }
        });
    }

    return (
        <div className='importPopupContainer'>
            {load ? <Loader fullscreen /> : null}
            <div className='acmPopupHeader'>{sAction.translate('LBL_CHOOSE_REPRESENTATIVE', 'acm_portal_users')}</div>
            <SimpleFormFromArray fields={fields} buttons={buttons} />
        </div>
    )
}
