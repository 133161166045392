import React from "react";
import PureComponent from "../../../pure";
import sAction from "sAction";
import SimpleFormFromArray from "../../../formElements/SimpleFormFromArray";

export default class newRequestPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.defaultValues = this.props.defaultValues || [
            {
                amount: 1,
                type: 'sim',
                description: '',
            },
        ];

        this.description = React.createRef();
        this.account = React.createRef();
        this.contact = React.createRef();
        this.state = {
            renderStage: 1,
            limits: {
                sim: 0,
                tablets: 0,
                sensors: 0,
                pay_terminal: 0,
                phones: 0,
                omv: 0,
                cars: 0,
                uniforms: 0,
                work_clothes: 0,
            },
        };

        if (props.params?.origin !== 'backoffice') {
            this.setLimits(sAction.dataGet(`conf/user/company_id`));
            this.setAccountAndContact(props);
        }
    }

    setLimits(accID) {
        sAction.rest.post('getAccountLimits', {accID: accID}, returnData => {
            this.setState({
                limits: {
                    sim: returnData?.message?.data?.['sim'],
                    tablets: returnData?.message?.data?.['tablets'],
                    sensors: returnData?.message?.data?.['sensors'],
                    pay_terminal: returnData?.message?.data?.['pay_terminal'],
                    phones: returnData?.message?.data?.['phones'],
                    omv: returnData?.message?.data?.['omv'],
                    cars: returnData?.message?.data?.['cars'],
                    uniforms: returnData?.message?.data?.['uniforms'],
                    work_clothes: returnData?.message?.data?.['work_clothes'],
                }
            });
        });
    }

    addLine() {
        this.defaultValues.push({
            amount: 1,
            type: 'sim',
            description: '',
        });
        this.setState({
            renderStage: this.state.renderStage + 1,
        });
    }

    removeLine(key) {
        this.defaultValues.splice(key, 1);
        this.setState({
            renderStage: this.state.renderStage + 1,
        });
    }

    setAccount(id) {
        if (!id) {
            return;
        }

        const searchData = {
            module: 'Accounts',
            fields: ['name'],
            getFields: ['name', 'id'],
            value: '%',
            defaultFilter: [{
                operandType: 'relate',
                parentId: id,
                module: 'Contacts',
                relationship: ['contacts'],
                type: 'eq',
                name: 'contact_name',
                relName: 'accounts',
            }],
            limit: 10,
        };

        sAction.quickSearch(searchData, returnData => {
            if (this.account?.current?.value) {
                this.account.current.value = {
                    id: returnData?.[0]?.id,
                    name: returnData?.[0]?.name,
                };
            } else {
                this.account.current = {
                    value: {
                        id: returnData?.[0]?.id,
                        name: returnData?.[0]?.name,
                    },
                };
            }
            this.setState({accountID: returnData?.[0]?.id})
        });
    }
    
    getFieldsLine(key) {
        return {
            ['type' + key + this.state.renderStage]: {
                type: 'enum',
                customOptions: this.getOptionsList(),
                defaultValue: this.defaultValues?.[key]?.type,
                required: true,
                onChange: (value) => {
                    this.defaultValues[key].type = value;
                    this.forceUpdate();
                },
            },
            ['amount' + key + this.state.renderStage]: {
                type: 'number',
                defaultValue: this.defaultValues?.[key]?.amount,
                required: true,
                min: 1,
                onChange: (value) => {
                    this.defaultValues[key].amount = value;
                },
            },
            ['description' + key + this.state.renderStage]: {
                type: 'textarea',
                defaultValue: this.defaultValues?.[key]?.description,
                onChange: (value) => {
                    this.defaultValues[key].description = value;
                },
            },
            ['removeLine' + key + this.state.renderStage]: {
                type: 'button',
                className: 'icon-removeLineIcon newLineButton',
                label: '',
                onClick: (returnData) => {
                    this.removeLine(key);
                },
            },
        };
    }

    getFieldsArray() {
        let fields = [
            {
                globalDescription: {
                    type: 'textarea',
                    myRef: this.description,
                    defaultValue: this.description?.current?.value,
                    placeholder: sAction.translate('GLOBAL_DESCRIPTION', 'acm_loan_requirement'),
                },
            }
        ];

        let role = 'backoffice';
        if (this.props.params?.origin !== 'backoffice') {
            role = sAction.dataGet('conf/user/role');
        }

        fields.push({
            account: {
                type: 'relate',
                placeholder: sAction.translate('LBL_ACM_LOAN_REQUIREMENT_ACCOUNTS_FROM_ACCOUNTS_TITLE', 'acm_loan_requirement') + ' *',
                myRef: this.account,
                module: 'Accounts',
                rowClass: 'accountRelate',
                required: true,
                defaultValue: {
                    id: this.account?.current?.value?.id,
                    name: this.account?.current?.value?.name,
                },
                additionalInfo: "kod_stredisko",
                readonly: !['backoffice', 'area_manager'].includes(role),
                searchFields : ["name", "kod_stredisko"],
                onChange: ((e) => {
                    this.setState({accountID: e.id});
                    this.setLimits(e.id);
                    this.contact.current.value = '';
                }),
            },
            contact: {
                type: 'relate',
                placeholder: sAction.translate('LBL_ACM_REQUESTS_CONTACTS_FROM_CONTACTS_TITLE', 'acm_requests'),
                myRef: this.contact,
                defaultValue: {
                    id: this.contact?.current?.value?.id,
                    name: this.contact?.current?.value?.name,
                },
                module: 'Contacts',
                rowClass: 'accountRelate',
                required: false,
                searchFields : ["name"],
                defaultFilter: [{
                    module: "Accounts",
                    name: "account_name",
                    operandType: "relate",
                    parentId: this.state.accountID,
                    relName: "contacts",
                    relationship: ['accounts'],
                    type: "eq",
                }],
                readonly: !['backoffice', 'area_manager', 'manager', 'partner'].includes(role),
                onChange: (e => {this.setAccount(e.id);}),
                style: {maxWidth: 'none', flex: '1 0 auto'},
            },
        });

        return fields;
    }

    getButtonsArray() {
        return {
            back: {
                label: 'LBL_CANCEL',
                onClick: (returnData) => {
                    sAction.popupHide();
                },
                icon: 'icon-undo',
            },
            change: {
                label: 'LBL_CREATE_LOAN_REQUEST',
                onClick: (returnData) => {
                    this.parseDataAndCreatePostRequest(returnData);
                },
                icon: 'icon-undo',
            },
        }
    }

    parseDataAndCreatePostRequest(data) {
        let restData = {
            lines: [],
            globalDescription: data.globalDescription,
        };
        restData.origin = this.props.params?.origin === 'backoffice' ? 'backoffice' : 'portal';
        restData.account = data.account?.id;
        restData.contact = data.contact?.id;

        for (let key = 0; key < this.defaultValues.length; key++) {
            restData.lines.push({
                amount: data['amount' + key + this.state.renderStage],
                type: data['type' + key + this.state.renderStage],
                description: data['description' + key + this.state.renderStage],
            });
        }

        if (!this.isLimitsValid(restData.lines)) {
            sAction.error(sAction.translate('LBL_LIMITS_EXCEEDED'));
            return;
        }

        sAction.load();
        sAction.rest.post('createNewRequest', restData, (returnData) => {
            sAction.unLoad();
            if (returnData.status) {
                sAction.popupHide();
                sAction.route();
                const data = {
                    header: sAction.translate("LBL_REQUEST_CREATED_HEADER"),
                    buttons: [
                        { label: sAction.translate("LBL_CONTINUE", "Home"), callback: () => {sAction.route();} },
                    ],
                };
                sAction.popup(sAction.translate(returnData.message.text), data);
            } else {
                if (returnData.errorMessage?.data?.popupHide !== false) {
                    sAction.popupHide();
                }
                sAction.error(sAction.translate(returnData.errorMessage.text))
            }
        });
    }

    isLimitsValid(lines) {
        // return true; // TODO DM Odstranit
        let limits = {...this.state.limits};
        return lines.every(line => {
            limits[line.type] -= line.amount;
            return limits[line.type] >= 0;
        });
    }

    createFieldArray() {

        let fieldArray = [];
        fieldArray.push(...this.getFieldsArray());
        fieldArray.push(...this.getHeaderFields());
        for (let lineKey = 0; lineKey < this.defaultValues.length; lineKey++) {
            fieldArray.push(this.getFieldsLine(lineKey));
        }
        fieldArray.push(this.newLineButton());

        return fieldArray;
    }

    getOptionsList() {
        return sAction.getEnumForSelect('item_type_list').filter((value) => {
            return !['cars_subprovider', 'cars', 'uniforms', 'work_clothes', 'omv'].includes(value.value);
        });
    }

    getHeaderFields() {
        let key = 0;
        let descLabel = sAction.translate('LBL_DESCRIPTION', 'acm_requests_items');
        while (this.defaultValues[key]) {
            if (this.defaultValues[key].type === 'tablets') {
                descLabel += " \u00A0 (Ak výmena Tabletu povinnost doplniť do popisu SN sériové číslo a IMEI)";
                break;
            }
            key++;
        }
        return [[
            {
                type: 'header',
                label: sAction.translate('LBL_ITEM_TYPE', 'acm_requests_items'),
            },
            {
                type: 'header',
                label: sAction.translate('LBL_AMOUNT', 'acm_loan_requirement'),
            },
            {
                type: 'header',
                label: descLabel,
            },
            {
                type: 'header',
                label: '',
            },
        ]];
    }

    render() {
        let limitNames = [];
        let limitValues = [];
        this.getOptionsList().forEachObject((val) => {
            if(['cars_subprovider', 'cars', 'uniforms', 'work_clothes'].includes(val.value)) {
                return;
            }
            limitNames.push(<td key={val.value}>{val.label}</td>);
            limitValues.push(<td key={val.value}>{this.state.limits?.[val.value] || 0}</td>)
        });

        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_NEW_REQUEST_HEADER', 'acm_requests')}</div>
                </div>
                <>
                    <div className='requestLimits'>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={limitNames.length}>{sAction.translate('LBL_LIMITS', 'acm_requests')}</td>
                                </tr>
                                <tr>
                                    {limitNames}
                                </tr>
                                <tr>
                                    {limitValues}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <SimpleFormFromArray
                        fields={this.createFieldArray()}
                        buttons={this.getButtonsArray()}
                        className='newLoanRequirement newRequest'
                    />
                </>
            </>
        );
    }

    newLineButton() {
        return {
            newLine: {
                type: 'button',
                className: 'addLine newLineButton',
                label: (
                    <>
                        <span className='icon-addLineIcon' style={{marginRight: '8px'}}/>
                        {sAction.translate('LBL_ADD_NEW_LINE') }
                    </>
                ),
                onClick: (returnData) => {
                    this.addLine();
                },
            },
        };
    }

    setAccountAndContact() {
        this.account.current = {value: {
                id: sAction.dataGet('conf/user/company_id'),
                name: sAction.dataGet('conf/user/company'),
            }};
        this.contact.current = {value: {
                id: sAction.dataGet('conf/user/contact_id'),
                name: sAction.dataGet('conf/user/contact'),
            }};
    }
}
