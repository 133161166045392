import React from "react";
import NewRequestPopup from "../../../components/popup/DetailView/acm_request/NewRequestPopup";

export default function duplicateRequirement(params) {
    this.load();
    this.rest.get(`getRequestDuplicate/${params.id}`, returnData => {
        this.unLoad();
        if (returnData?.status) {
            this.popup(<NewRequestPopup prefix={params.prefix} defaultValues={returnData?.message?.data} itemID={params.id} params={params} />, {customClass: 'newLoanRequirement', preventClose: true});
        } else {
            this.error(this.translate(returnData?.errorMessage?.text));
        }
    });
}
