export default function itemRegReleaseBulk(params){
    
    let filterData = null;
    if(params.selected === 'all'){
        const list = this.dataGet(params.prefix);
        const savedSearch = list.actSavedSearch;
        filterData = this.reloadListParams({
            list,
            prefix: params.prefix,
            savedSearch,
            columns: false,
        });
        filterData["selected"] = list.selected;
        filterData["module"] = list.modul;
        filterData["type"] = list.type;
    }

    this.rest.post("itemRegReleaseCheckState", {selected: params.selected, filter: filterData}, returnData => {
        if(returnData.status){
            this.popup("itemRegReleasePopup", 
        {
            header: this.translate("LBL_ITEM_REALEASE_BTN", "acm_items_registry"),
            data: { records:  returnData.records}
        });
        }
        else{
            this.error(returnData.errorMessage);
        }
    });
    

}