import detailDefault from "../../detailDefault";
export default class detailacm_requests extends detailDefault{
    load(sAction,data) {
        this.setVisibleFields(sAction, data, {decline_reason: sAction.dataGet(`${data.prefix}/fields/stage/value`) === 'declined'});
    }

    update(sAction,data) {
        switch (data.field) {
            case 'stage':
                this.setVisibleFields(sAction, data, {decline_reason: data.value === 'declined'});
        }
    }

    setVisibleFields (sAction, data, fields) {
        sAction.dsClear();
        fields.forEachObject((visible, fieldName) => {
            sAction.dsAdd('set', `${data.prefix}/fields/${fieldName}/def/visible`, visible);
        });
        sAction.dsProcess();
    }
}
