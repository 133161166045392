import detailDefault from "../../detailDefault";

export default class detailacm_departments extends detailDefault {
  load(sAction, data) {
  }

  update(sAction, data) {
    if(data.field == "acm_departments_contactscontacts_ida" && data.value.id){
        
        
      sAction.rest.post("getConAcc", {id: data.value.id}, function(resp) {
        if(resp.status === true){
            
          if(resp.message.data.id){
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix+"/fields/acm_departments_accounts_name/value", resp.message.data.name);
            sAction.dsAdd("set", data.prefix+"/fields/acm_departments_accounts_name/def/id_value", resp.message.data.id);

            sAction.dsAdd("set", data.prefix+"/changes/fields/acm_departments_accountsaccounts_ida", resp.message.data.id);
            sAction.dsProcess();
            
          }
          
        }
      });
      
    }

  }

}

