import detailDefault from "../../detailDefault";

export default class detailacm_contracts extends detailDefault {
  load(sAction, data) {

  }

  update(sAction, data) {
    if(data.field == "acm_contracts_accountsaccounts_ida" && data.value.id){
      sAction.rest.post("getAccUser", {id: data.value.id}, function(resp) {
        if(resp.status === true){
          if(resp.message.data.id){
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix+"/fields/assigned_user_name/value", resp.message.data.name);
            sAction.dsAdd("set", data.prefix+"/fields/assigned_user_name/def/id_value", resp.message.data.id);

            sAction.dsAdd("set", data.prefix+"/changes/fields/assigned_user_id", resp.message.data.id);
            sAction.dsProcess();
          }
          
        }
      });
    }

  }

}

