export default function reqItemHandleItem(params){
    let relData = {
      module: "acm_items_registry",
      selectedActive: false,
      defaultFilter: {
        stageFilter: {
          operandType: "field",
          field: "stage",
          value: "free", 
          type: "eq",
        }
      }
    };

    let item_type = this.dataGet(params.prefix+"/fields/item_type/value");
    if(item_type){
        relData.defaultFilter.itemTypeFilter = {
            operandType: "field",
            field: "item_type",
            value: item_type, 
            type: "eq",
        }
    }
    
    this.openRelatePopup(relData, (returnData) => {
      //self.selectItem(returnData);
        this.popupHide();
        if(returnData.id){
            this.dsClear();
            
            this.dsAdd("set", params.prefix+"/fields/acm_requests_items_acm_items_registry_name/value", returnData.name);
            this.dsAdd("set", params.prefix+"/fields/acm_requests_items_acm_items_registry_name/def/id_value", returnData.id);
            this.dsAdd("set", params.prefix+"/changes/fields/acm_requests_items_acm_items_registryacm_items_registry_ida", returnData.id);

            this.dsAdd("set", params.prefix+"/fields/stage/value", "done");
            this.dsAdd("set", params.prefix+"/changes/fields/stage", "done");
            
            this.dsProcess();

            params.canSave = this.dataGet(params.prefix+"/canSave");

            this.saveRecord(params);
        }
    });
}
