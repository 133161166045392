export default function openItemReceptionPopup(params) {
  


  let item_type = this.dataGet(params.prefix+"/fields/item_type/value");


  if(item_type){
    let records = {};
    records[params.id] = {
        "item_type" : item_type,
        "name" : params.name,
        "description" : "",
        "serial_no" : params.serial_no,
    };
    
    this.popup("itemReceptionPopup", {records, callback : () => {location.reload()}});
  }
  else{
    //v parametrech neprisel item_type, je treba se doptat
    this.rest.post('getItemInfoForReception', {record: params.id}, returnData => {
      if(returnData.status){
        let records = {};
        records[params.id] = {
          "item_type" : returnData.message.data.item_type,
          "name" : returnData.message.data.name,
          "description" : "",
          'serial_no': returnData.message.data.serial_no ?? '',
        };

        this.popup("itemReceptionPopup", {records, callback : () => {location.reload()}});
      }
    });
  }
  
};
