import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';
import Button from '../formElements/Button';
import Input from '../formElements/InputText';
import sAction from 'sAction';

export default class TwoFactorForm extends PureComponent {
    constructor() {
        super();

        this.state = {
            errorMessage: '',
            error: false,
            message: '',
        };
        this.code = React.createRef();
    }

    /**
     * submit on enter
     * @param {KeyboardEvent} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13) {
            this.submitCode();
        }
    }

    /**
     * submit two factor authentication code
     */
    submitCode() {
        const code = this.code.current.value;
        if (!code) {
            this.setState({error: true, errorMessage: '', message: ''});

            return;
        }

        this.setState({error: false, errorMessage: '', message: ''});
        sAction.twoFactorAuth(code, this.props.token);
    }

    render() {
        const ret = !this.state.error && this.state.message ? this.getMessage() : this.getForm();

        return (
            <table className="loginTable" onKeyDown={(e) => this.onKeyDown(e)}>
                <tbody>
                    {!sAction.param.is_portal && <tr>
                        <td colSpan="2" className="login PageLogoContainer"><img src={sAction.param.companyLogo}/></td>
                    </tr>}
                    {ret}
                </tbody>
            </table>
        );
    }

    /**
     * render two factor authentication form
     * @returns {JSX.Element}
     */
    getForm() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className='center error'>
                            {sAction.translate(this.state.errorMessage)}
                        </div>
                        <Input
                            autoFocus={true}
                            error={this.state.error}
                            myRef={this.code}
                            label={sAction.translate('LBL_LOGIN_2FA_CODE')}
                            variant="outlined"
                        />
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <Button
                                className="loginButton"
                                onClick={() => this.submitCode()}
                            >
                                {sAction.translate('LBL_LOGIN')}
                            </Button>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            {sAction.translate('LBL_BACK_TO_LOGIN')}
                            &nbsp;
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate('LBL_FORGOTTEN_PASSWORD_RETURN_BUTTON')}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }

    /**
     * render message
     * @returns {JSX.Element}
     */
    getMessage() {
        return (
            <React.Fragment>
                <tr>
                    <td colSpan="2">
                        <div className={'center'}>
                            {sAction.translate(this.state.message)}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2">
                        <div className="loginButtonContainer">
                            <a onClick={() => this.props.goToLogin()}>
                                {sAction.translate('LBL_BACK_TO_LOGIN')}
                            </a>
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

TwoFactorForm.propTypes = {
    goToLogin: PropTypes.func.isRequired,
    type: PropTypes.string,
    token: PropTypes.string,
    tokenValidity: PropTypes.number,
};
