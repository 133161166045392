import React from 'react';
import sAction from 'sAction';
import AdminIframe from './AdminIframe';
import Button from '../formElements/Button';
import Loader from 'ROOT/src/components/loader';
import AuthAdminContextProvider from 'ROOT/src/contexts/AuthAdminContext';
import AuthAdminContainer from './Authentication/AuthAdminContainer';

export default class Admin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const data = this.props.data;
    const hash = window.location.hash.replace("#", "");
    const uri = hash.split("/");
    var content = null;
    if (uri[1] == "custom") {
      const name = uri[2];
      content = (
        <div className="reportViewContainer">
          <div className="viewActionPanel">
            <div className="viewActionPanelButtons">
              <Button onClick={() => window.history.back()}>
                <div className={"actionPanelButtonIcon icon-undo"} />
                {sAction.app_strings["LBL_RETURN_BACK"]}
              </Button>
            </div>
          </div>
          <AdminIframe name={name} way={"view"} data={data.customData} />
        </div>);
    } else if (uri[1] === 'AuthSettings') {
        content = (
            <React.Suspense fallback={<Loader/>}>
                <AuthAdminContextProvider>
                    <AuthAdminContainer/>
                </AuthAdminContextProvider>
            </React.Suspense>
        );
    } else {
        content = (
            <div className="pageContainer DetailViewContainer">
                <div className="detailViewContainer">
                    <div className="detailCard">
                        <div className="header">
                            <div className="title">
                                <span className="detailViewHeaderIcon icon-admin" />
                                <span className="detailViewName">
                                    {sAction.translate('LBL_MODULE_NAME', 'Administration')}
                                </span>
                            </div>
                            <div
                                className="appVersion"
                                onClick={() => sAction.showChannelLog()}>
                                <b title={ sAction.translate('LBL_BACKEND_VERSION') + this.props.back_version}>B: {this.props.back_version}</b>
                                {' '}|
                                <b title={sAction.translate('LBL_FRONTEND_VERSION') + this.props.front_version}>F: {this.props.front_version} </b>
                            </div>
                        </div>
                        <div className="detailView admin">
                            {// sekce
                                this.props.data.sections.map((s) => {
                                    return (
                                        <div className="adminSection" key={s.title}>
                                            <div className="admin__section__title">
                                                <div className={'icon-' + s.icon + ' sectionIcon'} />
                                                {sAction.translate(s.title, 'Administration')}
                                            </div>
                                            <div className="sectionContent">
                                                <div className="">{sAction.translate(s.description, 'Administration')}</div>
                                                <table className="w100">
                                                    <tbody>
                                                        {// sloupce
                                                            s.rows.map((r) => {
                                                                return (
                                                                    <tr
                                                                        key={r.title}
                                                                        onClick={() =>
                                                                            (window.location.href = r.link)
                                                                        }>
                                                                        <td className="icon">
                                                                            <span className={'icon-EditField'} />
                                                                        </td>
                                                                        <td className="name">
                                                                            <a href={r.link}>
                                                                                {sAction.translate(r.title, 'Administration')}
                                                                            </a>
                                                                        </td>
                                                                        <td className="description">
                                                                            {sAction.translate(r.description, 'Administration')}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return content;
  }
}
