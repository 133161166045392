import React, {PureComponent} from "react";
import PropTypes from 'prop-types';
import sAction from 'sAction';
import SimpleFormFromArray from "../../../formElements/SimpleFormFromArray";

export default class PortalChangeLimitsPopup extends PureComponent {
    constructor() {
        super();

        this.state = {
            reasonError: false,
        };
    }

    getFields() {
        return {
            reason: {
                type: 'text',
                label: 'LBL_CHANGE_LIMITS_REASON',
                required: true,
                className: this.state.reasonError ? 'error' : '',
                errorMessage: this.state.reasonError ? sAction.translate('Je potřeba zadat alespoň 10 znaků') : null,
            },
            limitSim: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_SIM', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_sim/value`)
            },
            limitTablets: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_TABLET', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_tablet/value`)
            },
            limitSensors: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_SENSOR', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_sensor/value`)
            },
            limitPayTerminals: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_PAY_TERMINAL', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_pay_terminal/value`)
            },
            limitPhones: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_PHONE', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_phone/value`)
            },
            limitOMV: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_OMV', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_omv/value`)
            },
            limitCars: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_CAR', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_car/value`)
            },
            limitCarSubprovider: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_CAR_SUBPROVIDER', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_car_subprovider/value`)
            },
            limitUniforms: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_UNIFORM', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_uniform/value`)
            },
            limitWorkClothes: {
                type: 'int',
                label: sAction.translate('LBL_LIMIT_WORK_CLOTH', 'Accounts'),
                defaultValue: sAction.dataGet(`${this.props.data.get('params').get('prefix')}/fields/limit_work_cloth/value`)
            },
        };
    }

    getButtons() {
        return {
            button: {
                label: 'LBL_CHANGE_LIMITS',
                onClick: (formData) => {
                    this.validateLimits(formData)
                },
            },
        };
    }

    validateLimits(limitsData) {
        if (limitsData.reason?.length < 10) {
            this.setState({reasonError: true});

            return;
        }

        sAction.load();
        sAction.rest.post(
            'changeLimits/' + this.props.data?.get?.('params')?.get?.('module'),
            {recordID: this.props.data?.get?.('params')?.get?.('id'), ...limitsData},
            (returnData) => {
                sAction.unLoad();
                if (returnData.status === true) {
                    sAction.popupHide();
                    sAction.confrim(sAction.translate(returnData.message.text), () => {
                        sAction.route();
                    });
                } else {
                    sAction.error(sAction.translate(returnData.errorMessage.text));
                }
            }
        );
    }

    render() {
        return (
            <>
                <div className='acmPopupHeader'>{sAction.translate('LBL_CHANGE_LIMITS')}</div>
                <div className=''>
                    <SimpleFormFromArray
                        fields={this.getFields()}
                        buttons={this.getButtons()}
                    />
                </div>
            </>
        );
    }
}

PortalChangeLimitsPopup.propTypes = {
    data: PropTypes.object.isRequired,
    way: PropTypes.string.isRequired,
};
