import React, {PureComponent} from 'react';
import propTypes from 'prop-types';
import {Map} from 'immutable';
import DetailViewFieldsManager from 'ROOT/src/components/detailView/DetailViewFieldsManager/DetailViewFieldsManager';

export default class ReportTextField extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            actEdit: false,
        };
    }
    onBlur = (value) =>{
        this.setState((prevState) => ({actEdit: !prevState.actEdit}));
        this.props.onChange(this.props.name, value);
    };
    render() {
        const def = new Map({
            name: this.props.name,
            vname: this.props.vname,
            type: this.props.type ?? 'varchar',
            help: this.props.help ?? '',
            required: this.props.required ?? false,
        });

        return (
            <DetailViewFieldsManager
                // data={data}
                way={'report'}
                index={0}
                mode={'report'}
                percent={this.props.width ?? 100}
                onChange={this.props.onChange}
                name={this.props.name}
                value={this.props.value}
                def={def}
                actEdit={this.state.actEdit}
                readonly={this.props.readonly}
            />
        );
    }
}

ReportTextField.propTypes = {
    vname: propTypes.string.isRequired,
    onChange: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    value: propTypes.string.isRequired,
    width: propTypes.number,
    required: propTypes.bool,
    readonly: propTypes.bool,
    type: propTypes.string,
    group: propTypes.bool,
    customClass: propTypes.string,
    help: propTypes.string,
};
