import  sAction  from "sAction";
import ChangePasswordPopup from "../Users/ChangePasswordPopup";

export default class ChangePasswordPortalUserPopup extends ChangePasswordPopup {
    changePassword(force = false) {
        const passNew = this.passNew.current.value;
        const passAgain = this.passAgain.current.value;
        const passOld = this.passOld.current.value;

        this.setState({
            passOld: Boolean(passOld),
            passNew: Boolean(passNew),
            passAgain: Boolean(passAgain),
            passMessage: '',
            passAgainMessage: '',
        });

        if (!passOld || !passNew) {
            return;
        }

        if (!sAction.checkPassword(passNew, this.state.minLength, this.state.maxLength, this.state.extraRequirements)) {
            this.setState({
                passNew: false,
                passNewMessage: <PasswordRequirements {...this.state}/>,
            });
            return;
        }

        if (passNew !== passAgain) {
            this.setState({
                passAgain: false,
                passAgainMessage: 'LBL_PASSWORDS_DO_NOT_MATCH',
            });
            return;
        }

        self = this;
        sAction.load();
        const userID = sAction.dataGet('conf/user/id');
        sAction.rest.post("changePasswordPortalUser", {oldPassword: passOld, newPassword: passNew, userID, force}, data => {
            sAction.unLoad();
            if (data.status) {
                self.setState({message: data.message, error: false});
            } else if (data.field) {
                const returnData = {
                    passNew: Boolean(passNew),
                    passNewMessage: '',
                    passAgain: Boolean(passAgain),
                    passAgainMessage: '',
                    passOld: Boolean(passOld),
                    passOldMessage: '',
                };
                returnData[data.field] = false;
                returnData[data.field + 'Message'] = data.message;
                self.setState(returnData);
            } else if (data.errors) {
                self.setState({error: true, passNewMessage: data.errors.map((error) => sAction.translate(error)).join('\n')});
            } else if (data.ask) {
                sAction.confrim(data.message, () => {
                    self.changePassword(true);
                    sAction.popupHide();
                }, 'red');
            } else {
                self.setState({error: true, message: data.message});
            }
        });
    }
}
