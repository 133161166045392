import React from 'react';
import ReportCheckbox from 'ROOT/src/customReports/reportCommon/fields/ReportCheckbox';
import ReportTextField from 'ROOT/src/customReports/reportCommon/fields/ReportTextField';
import ReportRelate from 'ROOT/src/customReports/reportCommon/fields/ReportRelate';

import sAction from 'sAction';
import {AuthAdminContext} from 'ROOT/src/contexts/AuthAdminContext';

export default function PasswordSettings() {
    const {passwordSettings, updatePasswordSettings, emailTemplates, updateEmailTemplates} = React.useContext(AuthAdminContext);

    /**
     * update single boolean value for password settings
     * @param {string} key setting to update
     * @param {string} value new value
     * @returns {void}
     */
    const checkboxConvertor = (key, value) => updatePasswordSettings(key, !!value);

    /**
     * update single numeric value for password settings
     * @param {string} key setting to update
     * @param {string} value new value
     * @returns {void}
     */
    const numberConvertor = (key, value) => updatePasswordSettings(key, parseInt(value) || 0);

    return <div className="detailViewTabContentBlok select">
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_PASSWORD_POLICY', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_MIN_LENGTH', 'Administration')}
                name={'minLength'}
                value={passwordSettings.minLength}
                onChange={numberConvertor}
                width={100}
            />
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_MAX_LENGTH', 'Administration')}
                name={'maxLength'}
                value={passwordSettings.maxLength}
                onChange={numberConvertor}
                width={100}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_REQUIRE_UPPERCASE', 'Administration')}
                name={'requireUpper'}
                value={passwordSettings.requireUpper? 1: 0}
                onChange={checkboxConvertor}
            />
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_REQUIRE_LOWERCASE', 'Administration')}
                name={'requireLower'}
                value={passwordSettings.requireLower? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_REQUIRE_NUMBER', 'Administration')}
                name={'requireNumeral'}
                value={passwordSettings.requireNumeral? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_REQUIRE_SPECIAL', 'Administration')}
                name={'requireSpecial'}
                value={passwordSettings.requireSpecial? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_PASSWORD_EXPIRATION', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_ENABLED', 'Administration')}
                name={'expirationEnabled'}
                value={passwordSettings.expirationEnabled? 1: 0}
                onChange={checkboxConvertor}
            />
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_DURATION', 'Administration')}
                name={'expiration'}
                value={passwordSettings.expiration}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.expirationEnabled}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_NOTIFY', 'Administration')}
                name={'expirationNotify'}
                value={passwordSettings.expirationNotify? 1: 0}
                onChange={checkboxConvertor}
                width={100}
                readonly={!passwordSettings.expirationEnabled}
            />
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_LOCKOUT', 'Administration')}
                name={'expirationLockoutEnabled'}
                value={passwordSettings.expirationLockoutEnabled? 1: 0}
                onChange={checkboxConvertor}
                width={100}
                readonly={!passwordSettings.expirationEnabled}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_NOTIFICATION', 'Administration')}
                name={'expirationNotification'}
                value={passwordSettings.expirationNotification}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.expirationNotify || !passwordSettings.expirationEnabled}
            />
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_LOCKOUT_TIME', 'Administration')}
                name={'expirationLockout'}
                value={passwordSettings.expirationLockout}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.expirationLockoutEnabled || !passwordSettings.expirationEnabled}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportRelate
                vname={sAction.translate('LBL_PASSWORD_EXPIRATION_EMAIL_TEMPLATE', 'Administration')}
                name={'passwordExpired'}
                value={emailTemplates.passwordExpired}
                onChange={updateEmailTemplates}
                width={100}
                module={'EmailTemplates'}
                readonly={!passwordSettings.expirationNotify || !passwordSettings.expirationEnabled}
            />
            <ReportRelate
                vname={sAction.translate('LBL_PASSWORD_LOCKOUT_EMAIL_TEMPLATE', 'Administration')}
                name={'passwordLockout'}
                value={emailTemplates.passwordLockout}
                onChange={updateEmailTemplates}
                width={100}
                module={'EmailTemplates'}
                readonly={!passwordSettings.expirationLockoutEnabled || !passwordSettings.expirationEnabled}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_LOGIN_LOCKOUT', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_LOGIN_LOCKOUT_ENABLED', 'Administration')}
                name={'lockoutEnabled'}
                value={passwordSettings.lockoutEnabled? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_LOGIN_LOCKOUT_ATTEMPTS', 'Administration')}
                name={'maxAttempts'}
                value={passwordSettings.maxAttempts}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.lockoutEnabled}
            />
            <ReportTextField
                vname={sAction.translate('LBL_LOGIN_LOCKOUT_DURATION', 'Administration')}
                name={'lockoutDuration'}
                value={passwordSettings.lockoutDuration}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.lockoutEnabled}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_PASSWORD_RESET', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_ALLOW_USER_RESET', 'Administration')}
                name={'userReset'}
                value={passwordSettings.userReset? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
            <ReportRelate
                vname={sAction.translate('LBL_NEW_USER_EMAIL_TEMPLATE', 'Administration')}
                name={'newUser'}
                value={emailTemplates.newUser}
                onChange={updateEmailTemplates}
                width={100}
                module={'EmailTemplates'}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_RESET_LINK_VALIDITY', 'Administration')}
                name={'resetLinkValidity'}
                value={passwordSettings.resetLinkValidity}
                onChange={numberConvertor}
                width={100}
            />
            <ReportRelate
                vname={sAction.translate('LBL_PASSWORD_RESET_EMAIL_TEMPLATE', 'Administration')}
                name={'passwordReset'}
                value={emailTemplates.passwordReset}
                onChange={updateEmailTemplates}
                width={100}
                module={'EmailTemplates'}
            />
        </div>
        <div className="detailViewPanelHeader">
            <div className="detailViewPanelText">
                {sAction.translate('LBL_SYSTEM_GENERATED_PASSWORD', 'Administration')}
            </div>
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_ALLOW_GENERATED', 'Administration')}
                name={'allowGenerated'}
                value={passwordSettings.allowGenerated? 1: 0}
                onChange={checkboxConvertor}
                width={100}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportCheckbox
                vname={sAction.translate('LBL_PASSWORD_GENERATED_EXPIRE_AFTER_LOGIN', 'Administration')}
                name={'generatedExpireAfterLogin'}
                value={passwordSettings.generatedExpireAfterLogin? 1: 0}
                onChange={checkboxConvertor}
                width={100}
                readonly={!passwordSettings.allowGenerated}
            />
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_GENERATED_EXPIRATION', 'Administration')}
                name={'generatedExpiration'}
                value={passwordSettings.generatedExpiration}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.allowGenerated}
            />
        </div>
        <div className={'detailViewTabContentRow'}>
            <ReportTextField
                vname={sAction.translate('LBL_PASSWORD_GENERATED_LENGTH', 'Administration')}
                name={'generatedLength'}
                value={passwordSettings.generatedLength}
                onChange={numberConvertor}
                width={100}
                readonly={!passwordSettings.allowGenerated}
            />
            <ReportRelate
                vname={sAction.translate('LBL_PASSWORD_GENERATED_EMAIL_TEMPLATE', 'Administration')}
                name={'passwordGenerated'}
                value={emailTemplates.passwordGenerated}
                onChange={updateEmailTemplates}
                width={100}
                module={'EmailTemplates'}
                readonly={!passwordSettings.allowGenerated}
            />
        </div>
    </div>;
}
