import React from 'react';
import PropTypes from 'prop-types';

import sAction from 'sAction';
import PasswordRequirements from './PasswordRequirements';

export default function PasswordRequirementsPopover(props) {
    /**
     * show password requirements
     */
    const showRequirements = () => {
        setTimeout(() => {
            sAction.popper({
                header: sAction.translate('LBL_PASSWORD_REQUIREMENTS'),
                content: <PasswordRequirements {...props}/>,
                selector: '.passwordRequirements',
                className: 'passwordRequirementsPopper',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            });
        }, 30);
    };

    return <div className="icon-info passwordRequirementsIcon" onClick={showRequirements}/>;
}

PasswordRequirements.propTypes = {
    minLength: PropTypes.number.isRequired,
    maxLength: PropTypes.number.isRequired,
    extraRequirements: PropTypes.array.isRequired,
};
