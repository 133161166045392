import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import SimpleFormFromArray from "../../formElements/SimpleFormFromArray";

export default class MassInsertItemsPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.fieldsArray = {};
        this.state = {
            scanMode: true,
            stage: 0,
            itemType: '',
            values: {
                stage: 'not_available',
                card_type: 'physical',
                storage: {name: this.props.centralStorage?.name, id: this.props.centralStorage?.id,},
            },
            lineValues: {},
            errorMessage: null,
        };
        this.state.fieldsArray = this.getAllRequiredFieldsDefinition();
        this.type = '';
    }

    // Kliknuti na dalsi v prvnim kroku
    onFirstStepConfirm(data) {
        // let dataValid = true;
        // data.forEachObject((value, key) => {
        //     if (!value) {
        //         dataValid = false;
        //     }
        // });

        let fieldConfig = sAction.dataGet(this.props.prefix + '/customData/config');
        if (fieldConfig[this.type]?.fields_unique) {
            this.setState({
                stage: 1,
                values: data,
                errorMessage: null,
            });
        } else {
            this.createNewItems();
        }
    }

    // Kliknuti na "vytvorit" v druhem kroku
    onSecondStepConfirm(data) {
        let validData = true;

        let max = null;

        let fieldConfig = sAction.dataGet(this.props.prefix + '/customData/config');
        const reqFields = fieldConfig[this.state.values?.item_type]?.
            [this.state.values.stage === 'not_available' ? 'fields_required' : 'fields_required_release'];
        // Masakr validace v callbacku - zajistuje, aby byly vyplneny radky + odpovidal jejiich pocet
        if (fieldConfig[this.state.values?.item_type]?.fields_unique.every((fieldName) => {
            let temp = data[fieldName]?.trimEnd().split("\n");
            if (reqFields.includes(fieldName)) {
                if (!temp.every(e => !!e)) {
                    this.setState({
                        errorMessage: 'LBL_EMPTY_LINES_NOT_ALLOWED_IN_REQUIRED_FIELDS',
                    });
                    return false;
                }

                if (max === null) {
                    max = temp.length;
                } else if (temp.length !== max) {
                    this.setState({
                        errorMessage: 'LBL_LINE_COUNT_DOES_NOT_MATCH',
                    });
                    return false;
                }
            }

            return true;
        })) {
            if (validData === true) {
                delete data.buttonName;
                this.state.lineValues = data;
                this.setState({
                    errorMessage: null,
                    lineValues: data,
                });

                this.createNewItems();
            }
        }

    }

    createNewItems() {
        sAction.load();
        let data = {
            values: this.state.values,
            lineValues: this.state.lineValues,
        }
        sAction.rest.post('massInsertItems', data, returnData => {
            sAction.unLoad();
            if (returnData.status) {
                sAction.popupHide();
                sAction.reloadList(0, 1, this.props.prefix);
            } else {
                sAction.error(sAction.translate(returnData?.errorMessage?.text));
            }
        });
    }

    render() {
        let form = null;
        let buttonsArray = this.getButtonsArray(this.state.stage);
        if (this.state.stage === 0) {
            form = (
                <SimpleFormFromArray
                    fields={this.state.fieldsArray}
                    buttons={buttonsArray}
                />
            );
        } else if (this.state.stage === 1) {
            let array = this.getOptionalFieldsArray();
            form = (
                <SimpleFormFromArray
                    fields={array}
                    buttons={buttonsArray}
                />
            );
        }

        let errorMessage = null;
        if (this.state.errorMessage) {
            errorMessage = (
                <div className='massInsertItemsErrorMessage'>
                    {sAction.translate(this.state.errorMessage, 'acm_items_registry')}
                </div>
            );
        }

        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_NEW_MASS_INSERT_ITEMS')}</div>
                </div>
                <div className='massInsertItems'>
                    {errorMessage}
                    {form}
                </div>
            </>
        );
    }


    getAllRequiredFieldsDefinition(type = null) {
        let optionsList = sAction.app_strings?.item_registry_stage_list;
        let stagesOptions = [
            {
                value: 'not_available',
                label: optionsList['not_available'],
            },
            {
                value: 'free',
                label: optionsList['free'],
            },
        ];
        let itemTypeList = [];
        const config = sAction.dataGet(this.props.prefix + '/customData/config');
        config?.forEachObject((typeConfig, typeName) => {
            if (typeConfig?.mass_insert) {
                itemTypeList.push({
                    label: sAction.app_strings?.item_type_list?.[typeName],
                    value: typeName,
                });
            }
        });
        const fields = {
            item_type: {
                type: 'enum',
                label: sAction.translate('LBL_ITEM_TYPE', 'acm_items_registry'),
                options: 'item_type_list',
                customOptions: itemTypeList,
                onChange: (e) => {
                    this.type = e;
                    this.setState({fieldsArray: this.getAllRequiredFieldsDefinition(e), itemType: this.type});
                },
                defaultValue: this.state?.values?.item_type,
                required: true,
            },
            stage: {
                type: 'enum',
                label: sAction.translate('LBL_STAGE', 'acm_items_registry'),
                options: 'item_registry_stage_list',
                customOptions: stagesOptions,
                defaultValue: this.state?.values?.stage,
                required: true,
                onChange: (e => {
                    this.state.values.stage = e;
                    this.setState({fieldsArray: this.getAllRequiredFieldsDefinition(this.type)});
                })
            },
            card_type: {
                type: 'enum',
                label: sAction.translate('LBL_CARD_TYPE', 'acm_items_registry'),
                options: 'card_type_list',
                defaultValue: this.state?.values?.card_type,
                required: true,
            },
            amount: {
                type: 'number',
                label: sAction.translate('LBL_QTY', 'acm_items_registry'),
                defaultValue: this.state?.values?.amount,
                required: true,
            },
            storage: {
                type: 'relate',
                module: 'acm_storages',
                label: sAction.translate('LBL_ACM_ITEMS_REGISTRY_ACM_STORAGES_FROM_ACM_STORAGES_TITLE', 'acm_items_registry'),
                defaultValue: this.state?.values?.storage,
                required: true,
            },
            date_obtained: {
                type: 'date',
                label: sAction.translate('LBL_DATE_OBTAINED', 'acm_items_registry'),
                defaultValue: this.state?.values?.date_obtained,
                required: true,
            },
        }

        const defs = sAction.dataGet(this.props.prefix + '/customData/fields')
        let fieldConfig = sAction.dataGet(this.props.prefix + '/customData/config');
        let requiredByStage = this.state.values.stage === 'not_available' ? 'fields_required' : 'fields_required_release'

        // Existuje alespon jedno povinne viceradkove pole
        if  (fieldConfig[type]?.fields_unique?.length > 0) {
            fieldConfig[type]?.fields_unique.some(el => {
                if (requiredByStage.includes) {
                    delete fields.amount;
                    return true;
                }
                return false;
            });
        }

        fieldConfig[type]?.fields.forEachObject((fieldName) => {
            if (fieldConfig[type]?.fields_unique?.includes(fieldName)) {
                return;
            }

            fields[fieldName] = {
                type: 'input',
                label: sAction.translate(defs[fieldName]?.vname, 'acm_items_registry'),
                required: fieldConfig[type]?.[requiredByStage]?.includes(fieldName),
                defaultValue: this.state?.values?.[fieldName],
            };

            if (['enum', 'multienum', 'AcmDynamicEnum'].includes(defs[fieldName]?.type)) {
                fields[fieldName].type = defs[fieldName]?.type;
                fields[fieldName].options = defs[fieldName].options;
            } else if (['date', 'datetime', 'text'].includes(defs[fieldName]?.type)) {
                fields[fieldName].type = defs[fieldName].type;
            }
        });

        return fields;
    }

    handleNextInput(e) {
        if (e.key === 'Enter') {
            const nextInput = e.target.id === '0' ? document.getElementById(parseInt(e.target.id)+1) : document.getElementById(parseInt(e.target.id)-1);
            if(nextInput !== null){
                nextInput.focus();
            }
        }
    }

    getOptionalFieldsArray() {
        let fields = {};
        const defs = sAction.dataGet(this.props.prefix + '/customData/fields')
        let fieldConfig = sAction.dataGet(this.props.prefix + '/customData/config');
        let requiredByStage = this.state.values.stage === 'not_available' ? 'fields_required' : 'fields_required_release'

        fieldConfig[this.state.values?.item_type]?.fields_unique.forEachObject((fieldName, index) => {
            fields[fieldName] = {
                type: 'textarea',
                label: sAction.translate(defs[fieldName]?.vname, 'acm_items_registry'),
                required: fieldConfig[this.state.values?.item_type]?.[requiredByStage]?.includes(fieldName),
                index: index,
                onKeyUp: this.state.scanMode ? this.handleNextInput : null,
            };
        });
        if (this.state.itemType === 'tablets' || this.state.itemType === 'pay_terminal' ||this.state.itemType === 'phones') {
            fields['scan_mode'] = {
                type: 'checkbox',
                label: sAction.translate('LBL_SCAN_MODE', 'acm_items_registry'),
                required: false,
                style: {marginRight: "auto"},
                onChange: (value) => {this.setState({scanMode: value});},
                defaultValue: this.state.scanMode,
            }
        }
        return fields;
    }

    getButtonsArray(stage) {
        if (stage === 0) {
            return {
                button: {
                    // type: 'button',
                    label: 'LBL_NEXT',
                    onClick: (returnData) => {
                        this.onFirstStepConfirm(returnData);
                    },
                    // className: 'className',
                    // style: {style: 'property'},
                    icon: 'icon-addIcon',
                },
            };
        } else if (stage === 1) {
            return {
                buttonBack: {
                    // type: 'button',
                    label: 'LBL_BACK',
                    onClick: (returnData) => {
                        this.setState({
                            stage: 0,
                            fieldsArray: this.getAllRequiredFieldsDefinition(this.state.values.item_type),
                        });
                    },
                    // className: 'className',
                    // style: {style: 'property'},
                    icon: 'icon-addIcon',
                },
                button: {
                    // type: 'button',
                    label: sAction.translate('LBL_CREATE_NEW_ITEMS', 'acm_items_registry'),
                    onClick: (returnData) => {
                        this.onSecondStepConfirm(returnData);
                    },
                    // className: 'className',
                    // style: {style: 'property'},
                    icon: 'icon-addIcon',
                },
            };
        }
    }
}
