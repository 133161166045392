import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import SimpleFormFromArray from "../../formElements/SimpleFormFromArray";

export default class ItemForItemPopup extends PureComponent {
    constructor(props) {
        super(props);

        if (sAction.dataGet(this.props.prefix + '/fields/item_type/value') === 'pay_terminal') {
            this.state = {
                checkTid: true,
            };
        }
    }

    getFieldsArray() {
        const itemType = sAction.dataGet(this.props.prefix + '/fields/item_type/value');
        let optionsList = sAction.app_strings?.item_registry_stage_list;
        const options = [
            {
                value: 'not_available',
                label: optionsList['not_available'],
            },
            {
                value: 'free',
                label: optionsList['free'],
            },
            {
                value: 'discarded',
                label: optionsList['discarded'],
            },
            {
                value: 'service',
                label: optionsList['service'],
            },
        ];
        let fields = {
            item: {
                type: 'relate',
                module: 'acm_items_registry',
                label: sAction.translate('LBL_ITEM', 'acm_items_registry'),
                // defaultValue: '',
                required: true,
                defaultFilter: [
                    {
                        field: 'stage',
                        footer: false,
                        type: 'eq',
                        value: 'free',
                    },
                    {
                        field: 'item_type',
                        footer: false,
                        type: 'eq',
                        value: itemType,
                    },
                    {
                        field: 'id',
                        footer: false,
                        type: 'neq',
                        value: this.props.itemID,
                    },
                ],
            },
            stage: {
                type: 'enum',
                customOptions: options,
                required: true,
                label: sAction.translate('LBL_STAGE_OLD', 'acm_items_registry'),
            }
        };

        if (itemType === 'pay_terminal') {
            fields.checkTid = {
                type: 'checkbox',
                label: sAction.translate('LBL_CHECK_TID', 'acm_items_registry'),
                onChange: (value) => {this.setState({checkTid: value});},
                defaultValue: this.state.checkTid,
            };
            if (this.state.checkTid) {
                fields.item.defaultFilter.push({
                    field: 'tid',
                    footer: false,
                    type: 'eq',
                    value: sAction.dataGet(this.props.prefix + '/fields/tid/value'),
                });
            }
        }

        return fields;
    }

    getButtonsArray() {
        return {
            back: {
                label: 'LBL_CANCEL',
                onClick: (returnData) => {
                    sAction.popupHide();
                },
                icon: 'icon-undo',
            },
            change: {
                label: 'LBL_CHANGE_ITEM',
                onClick: (returnData) => {
                    sAction.load();
                    let paramData = this.props.params;
                    sAction.rest.post('itemForItem', {
                        oldItemID: this.props?.params?.id,
                        newItemID: returnData?.item?.id,
                        stage: returnData?.stage,
                    }, postData => {
                        sAction.unLoad();
                        sAction.routeDetailView(paramData, {});
                        sAction.popupHide();
                    });
                },
                icon: 'icon-forwardIcon',
            },
        }
    }

    render() {
        let form = (
            <SimpleFormFromArray
                fields={this.getFieldsArray()}
                buttons={this.getButtonsArray()}
            />
        );

        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_ITEM_FOR_ITEM')}</div>
                </div>
                <div className='itemForItem'>
                    {form}
                </div>
            </>
        );
    }
}
