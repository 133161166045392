export default function duplicateRecord(param) {
    let defaultReplaceFields = {};
    if (param.module === 'acm_items_registry') {
        let centralStorage = this.dataGet(param.prefix + '/customData/centralStorage', null, true);
        defaultReplaceFields.acm_items_registry = {
            imei: '',
            item_reg_no: '',
            serial_no: '',
            stage: 'not_available',
            phone_number: '',
            tid: '',
            date_borrowed_to: '',
            item_registry_loan_item_id: '',
            item_registry_loan_item_name: '',
            acm_items_registry_acm_storagesacm_storages_ida: '',
            acm_items_registry_acm_storages_name: {name: centralStorage.name, id: centralStorage.id},
            acm_items_registry_accountsaccounts_ida: '',
            acm_items_registry_accounts_name: '',
            acm_items_registry_contactscontacts_ida: '',
            acm_items_registry_contacts_name: '',
            acm_items_registry_acm_departmentsacm_departments_ida: '',
            acm_items_registry_acm_departments_name: '',
            item_for_item_id: '',
            item_for_item_name: '',
            item_for_item_2_id: '',
            item_for_item_2_name: '',
            region: centralStorage.region,
        };
    }

  let replaceFields = defaultReplaceFields[param.module] || [];

  const detail = this.dataGet(param.prefix);
  if (param.type === "rightPanel") {
    this.dataSet("rightPanel/closeOnSave", true);
  }
  this.dsClear();
  detail.fields.entrySeq().forEach((e) => {
    const name = e[0];
    const field = e[1];
    const value = field.value;

    const type = field.def.get("type");
    if (replaceFields[name] != null) {
        if (replaceFields[name]) {
            if (['relate', 'link', 'parent'].includes(type)) {
                this.dsAdd('set', param.prefix + '/fields/' + name + '/value', replaceFields[name].name);
                this.dsAdd('set', param.prefix + '/changes/fields/' + name, replaceFields[name].name);
                this.dsAdd('set', param.prefix + '/changes/fields/' + field.def.get("id_name"), replaceFields[name].id);
            } else {
                this.dsAdd('set', param.prefix + '/fields/' + name + '/value', replaceFields[name]);
                this.dsAdd('set', param.prefix + '/changes/fields/' + name, replaceFields[name]);
            }
        } else {
            this.dsAdd('set', param.prefix + '/fields/' + name + '/value', '');
        }
        return;
    }

    if (name !== "id") {
      if (type == "relate" || type == "link" || type == "parent") {
          let id_value = field.def.get("id_value");
          if (id_value) {
                this.dsAdd(
                  "set",
                  param.prefix + "/changes/fields/" + field.def.get("id_name"),
                  id_value
                );
          }

        if (type == "parent") {
          this.dsAdd(
            "set",
            param.prefix + "/changes/fields/parent_type",
            field.def.get("parent_type_value")
          );
        }
      } else {
        this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
      }
      this.dsAdd("set", param.prefix + "/changes/fields/" + name, value);
    }
  });
  this.dsAdd("set", param.prefix + "/id", null);

  if (this.dataGet(param.prefix + "/customData")) {
    this.dsAdd("set", param.prefix + "/customData/duplicateId", param.id);
  }
  if (
    detail.customData &&
    detail.customData.get &&
    detail.customData.get("customLines") &&
    detail.customData.get("customLines").get("lines")
  ) {
    detail.customData
      .get("customLines")
      .get("lines")
      .toJS()
      .forEachObject((line, key) => {
        this.dsAdd(
          "set",
          param.prefix + "/customData/customLines/lines/" + key + "/id",
          null
        );
      });
  }
  this.dsProcess();
}
