import React from "react";
import sAction from "../../action"
import {width} from "../charts/_globalSettings"
// import PropTypes from "prop-types";

const PUNode = ({ nodeData }) => {
  const selectNode = () => {
    // alert(sAction.toJSON(nodeData));
  };

  //const activeLbl = nodeData.active == '1' ? sAction.translate("LBL_ACTIVE", 'acm_portal_users') : sAction.translate("LBL_INACTIVE", 'acm_portal_users');

  const module = nodeData.module;
  console.log("module", nodeData.customClass);

  let contClass = "puNode";
  if(nodeData.customClass){
    contClass += " "+nodeData.customClass;
  }

  if(module == 'root'){
    return (
      <div className={contClass} onClick={selectNode}>
        <div className="puTitle"><a href={nodeData.node_url} target="_blank">{sAction.decodeHTMLEntities(nodeData.name)}</a></div>
      </div>
    );  
  }



  return (
    <div className={contClass} onClick={selectNode}>
      <div className="puTitle"><a href={nodeData.node_url} target="_blank">{sAction.decodeHTMLEntities(nodeData.name)}</a></div>
      <div className='puRow'>{sAction.app_strings.contact_role_list[nodeData.role]}</div>
      <div className='puRow'>{sAction.decodeHTMLEntities(nodeData.email_address)}</div>
    </div>
  );
};

// const propTypes = {
//   nodeData: PropTypes.object.isRequired
// };

// MyNode.propTypes = propTypes;

export default PUNode;
