export default function acceptRequirement(params) {
    this.rest.post('processPortalRequirement', {id: params.id, stage: 'accepted'}, returnData => {
        this.unLoad();
        if (returnData.status) {
            this.route();
        } else {
            this.error(this.translate(returnData.errorMessage?.text));
        }
    });
}
