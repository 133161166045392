import React from "react";
import moment from "moment";

import PureComponent from "../../pure";

import Button from "../../formElements/Button";
import InputText from "../../../components/formElements/InputText";
import AcmDate from "../../../components/formElements/AcmDate";
import Relate from "../../../components/formElements/Relate";
import Select from "../../../components/formElements/Select";
import MultiSelect from "../../../components/formElements/MultiSelect";



import sAction from "sAction";

export default class ItemServicePopup extends PureComponent {

    malfuncOPt = {
        "sensors" : [
            "nenabija",
            "laser nesnima",
            "nepripoji sa cez bluetooth",
            "slaba kapacita baterie",
            "neda sa zapnut",
            "pokazene tlacitko na snimanie",
            "preblikavaju diody",
            "nefunkcny USB port",
            "blika",
            "snimac bez laseroveho luca",
            "chybny rozmer laseroveho luca",
            "naskenuje 1 balik a dalsie uz nie",
            "pri skenovani sa vypne",
            "neda sa pripojit k PC",
            "Iné - uveďte nižšie - Samostatne text pole pre specificke zavady",
        ],
        "tablets" : [
            "rozbity display",
            "vybita bateria",
            "mrzne",
            "neda sa zapnut",
            "neustale sa vypina",
        ],
        "pay_terminal" : [
            "Baterka neustále vybitá",
            "Chýba tlačítko",
            "Enter Password",
            "Mrzne pri platbe",
            "Mrzne pri zapínaní",
            "Key Loading Mode",
            "Nedá sa platiť pretiahnutím karty",
            "Nedá sa platiť vložením karty",
            "Nedá sa spárovať s mobilom",
            "Nedá sa spojiť s platobnou bránou",
            "Nedá sa zapnúť",
            "Neustále sa vypína",
            "Neustále vypisuje \"Vyberte kartu\"",
            "Poškodený displej",
            "Poškodený konektor nabíjania",
            "Tamper detected",
            "Zamieta vsetky platby",
            "Zamrzne až do vybitia",
            "Iné - uveďte nižšie - Samostatne text pole pre specificke zavady",
        ],
        "phones" : [
            "rozbity display",
            "vybita bateria",
            "mrzne",
            "neda sa zapnut",
            "Iné - uveďte nižšie - Samostatne text pole pre specificke zavady",
        ],

    }

    constructor(props){
        super(props);

        const params = props.data.get("data").get("params");

        this.state = {
            ship_num: "",
            date_sent : moment().format("YYYY-MM-DD"),
            acm_storages : {
                value : "",
                id_value : "",
            },
            item_type : params.get("item_type"),
            malfunc_desc : "",
            imei : params.get("imei"),
            user_name : params.get("user_name"),
            account : params.get("account"),
            additional_info : "",
            configuration : "",
        };
    }

    handleSelectStorage(item){
        
        this.setState({acm_storages: {value: item.name, id_value: item.id}});

    }

    handleConfirm(){

        /*
        if(!this.state.ship_num){
            this.setState({errorClass: "error"});
            return;
        }*/

        //console.log("STATE", this.state);
        //return;

        const id = this.props.data.get("data").get("params").get("id");

        sAction.load();
        sAction.rest.post('itemService', {
            id : id,
            malfunc_desc : this.state.malfunc_desc,
            ship_num : this.state.ship_num,
            additional_info : this.state.additional_info,
            configuration : this.state.configuration,
        }, returnData => {
            sAction.unLoad();

            if(returnData.status){
                //sAction.hrefModuleList({relModule : "acm_items_registry"});
                location.reload();
            }
            else{
                sAction.error(returnData.errorMessage)
            }
        });

    }

    handleShipNum(val){

        let valPom = val.replace(/-/g, '');

        if(valPom.length >= 6){
            val = valPom.substring(0, 3)+"-"+valPom.substring(3, 6)+"-"+valPom.substring(6, 14);
        }

        this.setState({ship_num : val});
    }

    render(){

        const acm_storages = this.state.acm_storages;
        const item_type_list = sAction.app_strings.item_type_list;
        
        const typeOptions = [
            {
                value : "sensors",
                label : item_type_list.sensors,
            },
            {
                value : "tablets",
                label : item_type_list.tablets,
            },
            {
                value : "pay_terminal",
                label : item_type_list.pay_terminal,
            },
            {
                value : "phones",
                label : item_type_list.phones,
            }
        ];

        let mafuncOpt = [];
        if(this.malfuncOPt[this.state.item_type]){
            this.malfuncOPt[this.state.item_type].forEach(elem => {
                mafuncOpt.push({
                    value : elem,
                    label : elem,
                })
            });
        }
        
        let configComp = null;
        if(this.state.item_type == "sensors"){
            let configOptions = [];

            sAction.app_strings.servis_configuration_list.forEachObject((val, key) => {
                    configOptions.push({value: key, label: val});
                }
            );


            configComp = <tr className="realeaseTableRow">
                <td>
                    {sAction.translate("LBL_CONFIGURATION", "acm_servis")}
                    {this.state.item_type === 'sensors' ? <span className="required">*</span> : null}
                </td>
                <td>
                    <Select
                        open={false}
                        name="configuration"
                        options={configOptions}
                        value={this.state.configuration}
                        onChange={(ev) => this.setState({configuration : ev.target.value})}
                    />
                </td>
            </tr>;
        }

        return <div>
                <div className="acmPopupContent">
                    <table className="tranferTable">
                        <tbody>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_SHIPMENT_NUM", "acm_items_registry")}
                                </td>
                                <td>
                                <InputText
                                    defaultValue={this.state.ship_num}
                                    placeholder="xxx-xxx-xxxxxxxx"
                                    className={"withBorder"}
                                    type="text"
                                    onBlur={(ev) => this.handleShipNum(ev.target.value)}
                                    fullWidth
                                    key={this.state.ship_num}
                                />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_DATE_SENT", "acm_servis")}
                                </td>
                                <td>
                                <AcmDate
                                    value={this.state.date_sent}
                                    onChange={(date) => this.setState({ date_sent : date })}
                                />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_ITEM_TYPE", "acm_items_registry")}
                                </td>
                                <td>
                                    <Select 
                                        open={false} 
                                        name="item_type" 
                                        options={typeOptions}
                                        value={this.state.item_type} 
                                        onChange={(ev) => this.setState({item_type : ev.target.value})} 
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            {configComp}
                            <tr className="realeaseTableRow">
                                <td>
                                {sAction.translate("LBL_IMEI", "acm_items_registry")}
                                </td>
                                <td>
                                <InputText
                                    defaultValue={this.state.imei} 
                                    className={"withBorder"}
                                    type="text"
                                    onChange={(ev) => this.setState({imei : ev.target.value})}
                                    fullWidth
                                    disabled={true}
                                />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_ACM_PORTAL_USERS_ACCOUNTS_FROM_ACCOUNTS_TITLE", "acm_portal_users")}
                                </td>
                                <td>
                                <InputText
                                    defaultValue={this.state.account} 
                                    className={"withBorder"}
                                    type="text"
                                    onChange={(ev) => this.setState({account : ev.target.value})}
                                    fullWidth
                                    disabled={true}
                                />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_USER_NAME", "acm_portal_users")}
                                </td>
                                <td>
                                    <InputText
                                        defaultValue={this.state.user_name} 
                                        className={"withBorder"}
                                        type="text"
                                        onChange={(ev) => this.setState({user_name : ev.target.value})}
                                        fullWidth
                                        disabled={true}
                                    />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_MALFUNCTION_DESCRIPTION", "acm_servis")}
                                    <span className="required">*</span>
                                </td>
                                <td>
                                    <MultiSelect 
                                        open={false} 
                                        name="malfunc_desc" 
                                        id="malfunc_desc" 
                                        options={mafuncOpt}
                                        defaultValue={this.state.malfunc_desc} 
                                        onChange={(value) => this.setState({malfunc_desc : value})} 
                                    />
                                </td>
                            </tr>
                            <tr className="realeaseTableRow">
                                <td>
                                    {sAction.translate("LBL_ADDITIONAL_INFO", "acm_servis")}
                                </td>
                                <td>
                                    <textarea
                                        style={{height: '60px', width: '100%'}}
                                        onChange={(ev) => this.setState({additional_info : ev.target.value})}
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className="sfButtonRow sfButtons viewActionPanelButtons viewActionPopuBorromButtons">
                    <Button className="hoverGreen" onClick={() => {
                        if (!this.state.malfunc_desc) {
                            sAction.error("Nejprve prosím vyplňte všechny povinné položky.");
                        } else if (this.state.item_type === 'sensors' && !this.state.configuration) {
                            sAction.error("Nejprve prosím vyplňte všechny povinné položky.");
                        } else {
                            this.handleConfirm()
                        }
                    }}>
                        <div className={"icon-saveIcon calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CONFIRM")}
                    </Button>
                    <Button className="hoverRed" onClick={() => sAction.popupHide()}>
                        <div className={"icon-close calHeaderIcon"} ></div>
                        {sAction.translate("LBL_CANCEL_ACTION")}
                    </Button>
                </div>
        </div>;
    }
} 