import detailDefault from "../../detailDefault";
import moment from "moment";

export default class detailacm_cars extends detailDefault {
    centralStorage = 'c5190006-fa8e-47e3-da64-6108f997df10';

    load(sAction, data) {
        if (!sAction.dataGet(`${data.prefix}/id`)) {
            this.setCarDefaults(sAction, data);
        }
        this.updateDetailFieldsVisibility(sAction, data);
        sAction.dsClear();
        const accID = sAction.dataGet(data.prefix + '/fields/acm_items_registry_accounts_name/def/id_value');
        sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_contacts_name/def/defaultFilter/0/parentId', accID ? accID : ' ');
        sAction.dsAdd('set', data.prefix + '/fields/assigned_user_name/def/required', false);
        sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_acm_storages_name/def/required', false);
        sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_contacts_name/def/required', true);
        sAction.dsAdd('set', data.prefix + '/fields/description/def/readonly', false);
        sAction.dsProcess();

        data.value = sAction.dataGet(data.prefix + '/fields/stage/value');
        this.doChangeStageMagic(sAction, data);

        /** Nastavim ID skladu pro nastaveni filtru na firme a povinneho pole firmy */
        data.value = {
            id: sAction.dataGet(data.prefix + '/fields/acm_items_registry_acm_storages_name/def/id_value'),
        };
        this.setRequiredParnerField(sAction, data);
        // this.setRegion(sAction, data);

        let amountVisibility = sAction.dataGet(data.prefix + '/fields/card_type/value') === 'virtual';
        this.setFieldVisibility(sAction, data, amountVisibility, 'amount');
        this.setItemTypeReadonly(sAction, data);
        this.setEnum(sAction, data);
        this.setFieldVisibility(sAction, data, sAction.dataGet(`${data.prefix}/fields/car_color/value`) === 'INE...', 'car_color_note');
        this.eliminateVisible(sAction, data);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'item_type' :
                this.updateDetailFieldsVisibility(sAction, data)
                this.checkSerialDuplicity(sAction, data, this.getProducerFieldName(sAction, data, data.value));
                this.setName(sAction, data);
                break;
            case 'acm_items_registry_acm_storagesacm_storages_ida':
                this.setRequiredParnerField(sAction, data);
                this.setRegion(sAction, data);
                break;
            case 'acm_items_registry_accountsaccounts_ida':
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_contacts_name/def/defaultFilter/0/parentId', data.value.id ? data.value.id : ' ');
                sAction.dsProcess();
                this.checkIfOnlyOneContact(sAction, data);
                this.checkIfSIMAndNotify(sAction, data);
                break;
            case 'stage':
                this.doChangeStageMagic(sAction, data);
                this.eliminateVisible(sAction, data);
                break;
            case 'card_type':
                this.setFieldVisibility(sAction, data, data.value === 'virtual', 'amount');
                break;
            case 'serial_no':
            case 'imei':
            case 'producer':
            case 'sim_supplier':
            case 'tablet_producer':
            case 'sensor_producer':
            case 'pay_terminal_producer':
                this.checkSerialDuplicity(sAction, data, this.getProducerFieldName(sAction, data));
                break;
            case 'tid':
                this.checkTidDuplicity(sAction, data);
                break;
            case 'car_color':
                this.setFieldVisibility(sAction, data, data.value === 'INE...', 'car_color_note');
                break;
            case 'car_vin':
            case 'car_type_tp':
            case 'car_engine_no':
                this.setUppercase(sAction, data);
                break;
        }
    }

    setUppercase(sAction, data) {
        sAction.dsClear();
        sAction.dsAdd('set', `${data.prefix}/fields/${data.field}/value`, data.value.toUpperCase());
        sAction.dsAdd('set', `${data.prefix}/changes/fields/${data.field}`, data.value.toUpperCase());
        sAction.dsProcess();
    }

    /** Funkce, co se stara o skryvani / odkryvani spravnych policek podle typu predmetu */
    updateDetailFieldsVisibility(sAction, data) {
        if (!data.value) {
            data.value = sAction.dataGet(data.prefix + "/fields/item_type/value");
        }

        const updateTab = (visibleTabs) => {
            const defaultTabs = [
                'lbl_detailview_panel4',
                'lbl_detailview_panel5',
                'lbl_detailview_panel6',
                'lbl_detailview_panel7',
                'lbl_detailview_panel8',
                'lbl_detailview_panel9',
                'lbl_detailview_panel14',
                'lbl_detailview_panel15',
                'lbl_detailview_panel16',
            ];
            const tabs = sAction.dataGet(data.prefix + "/tabs");
            defaultTabs.forEach((tabName) => {
                const tabIndex = tabs.findIndex((i) => i.name === tabName);
                sAction.dsAdd("set", data.prefix + "/tabs/" + tabIndex + "/hidden", !visibleTabs.includes(tabName));
            });
        };

        const fromName = [
            'lbl_detailview_panel4',
            'lbl_detailview_panel14',
            'lbl_detailview_panel15',
            'lbl_detailview_panel16',
        ];
        const renameSubpanel = (fromName, toName) => {
            const tabs = sAction.dataGet(data.prefix + "/tabs");
            fromName.forEach((tabName) => {
                const tabIndex = tabs.findIndex((i) => i.name === tabName);
                sAction.dsAdd("set", data.prefix + "/tabs/" + tabIndex + "/name", toName);
            });
        };

        sAction.dsClear();
        switch (data.value) {
            case 'sim':
                updateTab(['lbl_detailview_panel9']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            case 'sensors':
                // renameSubpanel(fromName, 'lbl_detailview_panel15');
                updateTab(['lbl_detailview_panel15']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            case 'tablets':
                // renameSubpanel(fromName, 'lbl_detailview_panel14');
                updateTab(['lbl_detailview_panel14']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            case 'phones':
                // renameSubpanel(fromName, 'lbl_detailview_panel4');
                updateTab(['lbl_detailview_panel4']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            case 'pay_terminal':
                // renameSubpanel(fromName, 'lbl_detailview_panel16');
                updateTab(['lbl_detailview_panel16']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            case 'uniforms':
            case 'work_clothes':
                updateTab(['lbl_detailview_panel5']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['date_obtained']);
                break;
            case 'omv':
                updateTab(['lbl_detailview_panel6']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['date_obtained']);
                break;
            case 'cars':
                updateTab(['lbl_detailview_panel8']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            case 'cars_subprovider':
                updateTab(['lbl_detailview_panel7']);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, ['']);
                break;
            default:
                updateTab([]);
                this.setRequiredFields(sAction, data, data.value);
                this.setVisibleFields(sAction, data, []);
                break;
        }
        sAction.dsProcess();
    }

    /**
     * @param sAction
     * @param data
     * @param requiredFields
     *
     * Poznámka pro mé budoucí já nebo toho, kdo to bude upravovat:
     * fieldsToProcess je proměnná obsahujíci seznam všech polí, jejichž závislost je dána typem produktu.
     * requiredFields je array povinných polí, zbytek se nastaví jako nepovinný.
     */
    setRequiredFields(sAction, data, itemType) {
        let fieldName = sAction.dataGet(`${data.prefix}/fields/stage/value`) === 'not_available' ? 'fields_required' : 'fields_required_release';
        let fieldConfig = sAction.dataGet(data.prefix + '/customData/config');
        if (fieldConfig) fieldConfig = fieldConfig?.toJS?.();
        if (!fieldConfig) {
            return;
        }
        const fieldsToProcess = this.getFieldsToProcess(sAction, data, fieldConfig);
        fieldsToProcess.forEachObject((field) => {
            sAction.dsAdd('set', data.prefix + '/fields/' + field + '/def/required', fieldConfig[itemType]?.[fieldName]?.includes(field));
        });
    }

    setVisibleFields(sAction, data, fieldArray) {
        const fields = [
            'date_obtained',
        ];

        fields.forEach((field) => {
            let dom = document.querySelector('#' + field);
            if (dom) {
                dom.closest('.detailViwRow').style.display = fieldArray.includes(field) ? 'none' : 'flex';
            }
        });
    }

    setRequiredParnerField(sAction, data) {
        sAction.dsClear();
        const partnerRequired = data.value.id !== this.centralStorage;
        sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_accounts_name/def/required', partnerRequired);
        sAction.dsProcess();

    }

    setRegion(sAction, data) {
        let currentKodStredisko = sAction.dataGet(data.prefix + '/fields/kod_stredisko/value');

        const setRegion = (currentKodStredisko, regionData) => {
            sAction.dsClear();
            if (regionData.value != currentKodStredisko) {
                sAction.dsAdd('set', data.prefix + '/fields/region/value', regionData.region);
                sAction.dsAdd('set', data.prefix + '/changes/fields/region', regionData.region);

                sAction.dsAdd('set', data.prefix + '/fields/kod_stredisko/value', regionData.value);
                sAction.dsAdd('set', data.prefix + '/changes/fields/kod_stredisko', regionData.value);
            }
            sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_accounts_name/def/defaultFilter/0/value', regionData.value);
            sAction.dsProcess();
        };

        if (data.value.id) {
            sAction.rest.post('getStorageKodStredisko', {storageID: data.value.id}, returnData => {
                // sAction.dsClear();
                // sAction.dsAdd('set', data.prefix + '/fields/region/value', returnData?.message?.data?.value);
                // sAction.dsAdd('set', data.prefix + '/changes/fields/region', returnData?.message?.data?.value);
                // sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_accounts_name/def/defaultFilter/0/value', returnData?.message?.data?.value);
                // sAction.dsProcess();
                setRegion(currentKodStredisko, returnData?.message?.data);
            });
        } else {
            // sAction.dsClear();
            // sAction.dsAdd('set', data.prefix + '/fields/region/value', '');
            // sAction.dsAdd('set', data.prefix + '/changes/fields/region', '');
            // sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_accounts_name/def/defaultFilter/0/value', '');
            // sAction.dsProcess();
            setRegion(currentKodStredisko, {value : '', region: ''});
        }
    }

    doChangeStageMagic(sAction, data) {
        let descriptionReq = false;
        let desposalReq = false;
        switch (data.value) {
            case 'borrow':
                descriptionReq = true;
                if (!sAction.dataGet(data.prefix + '/fields/date_borrowed_to/value')) {
                    sAction.dsClear();
                    let dateBorrowedTo = moment();
                    dateBorrowedTo.add(30, 'day');
                    sAction.dsAdd('set', data.prefix + '/fields/date_borrowed_to/value', dateBorrowedTo.format('YYYY-MM-DD'));
                    sAction.dsAdd('set', data.prefix + '/changes/fields/date_borrowed_to', dateBorrowedTo.format('YYYY-MM-DD'));
                    sAction.dsProcess();
                }
                break;
            case 'steal':
                descriptionReq = true;
                break;
            case 'discarded':
                descriptionReq = true;
                desposalReq = true;
                break;
            case 'service':
                if (!sAction.dataGet(data.prefix + '/fields/date_service_to/value')) {
                    sAction.dsClear();
                    let dateServiceTo = moment();
                    dateServiceTo.add(30, 'day');
                    sAction.dsAdd('set', data.prefix + '/fields/date_service_to/value', dateServiceTo.format('YYYY-MM-DD'));
                    sAction.dsAdd('set', data.prefix + '/changes/fields/date_service_to', dateServiceTo.format('YYYY-MM-DD'));
                    sAction.dsProcess();
                }
                break;
        }
        
        sAction.dsClear();
        this.setRequiredFields(sAction, data, sAction.dataGet(`${data.prefix}/fields/item_type/value`));
        sAction.dsAdd('set', data.prefix + '/fields/disposal/def/required', desposalReq);
        sAction.dsAdd('set', data.prefix + '/fields/description/def/required', descriptionReq);
        sAction.dsProcess();
    }

    checkIfOnlyOneContact(sAction, data) {
        const searchData = {
            module: 'Contacts',
            fields: ['name'],
            getFields: ['name', 'id'],
            value: '%',
            defaultFilter: [{
                operandType: 'relate',
                parentId: data.value.id,
                module: 'Accounts',
                relationship: ['accounts'],
                type: 'eq',
                name: 'account_name',
                relName: 'contacts',
            }],
            limit: 10,
        };

        sAction.quickSearch(searchData, returnData => {
            if (returnData.length === 1) {
                sAction.dsClear();
                sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_contacts_name/value', returnData[0].name);
                sAction.dsAdd('set', data.prefix + '/fields/acm_items_registry_contacts_name/def/id_value', returnData[0].id);
                sAction.dsAdd('set', data.prefix + '/changes/fields/acm_items_registry_contactscontacts_ida', returnData[0].id);
                sAction.dsProcess();
            }
        });
    }

    checkIfSIMAndNotify(sAction, data) {
        const type = sAction.dataGet(data.prefix + '/fields/item_type/value');
        const partner = sAction.dataGet(data.prefix + '/fields/acm_items_registry_accounts_name/def/id_value');
        if (type === 'sim' && data.value.id) {
            setTimeout(() => {
                sAction.alert(sAction.translate('LBL_PARTNER_CHANGE_ONLY_ON_BEGGINING_OF_MONTH'));
                }, 0);
        }
    }

    setFieldVisibility(sAction, data, value, field) {
        sAction.dsClear();
        sAction.dsAdd('set', data.prefix + '/fields/' + field + '/def/visible', value);
        sAction.dsProcess();
    }

    setItemTypeReadonly(sAction, data) {
        if (sAction.dataGet(data.prefix + '/id') && sAction.dataGet(data.prefix + '/fields/item_type/value')) {
            sAction.dsClear();
            sAction.dsAdd('set', data.prefix + '/fields/item_type/def/readonly', true);
            sAction.dsProcess();
        }
    }

    checkSerialDuplicity(sAction, data, producerName) {
        let itemType = data.field === 'item_type' ? data.value : sAction.dataGet(data.prefix + '/fields/item_type/value');
        if (['sim', 'tablets', 'sensors', 'pay_terminal', 'phones'].includes(itemType)) {
            let producer = data.field === producerName ? data.value : sAction.dataGet(`${data.prefix}/fields/${producerName}/value`);
            let serialNo = data.field === 'serial_no' ? data.value : sAction.dataGet(data.prefix + '/fields/serial_no/value');
            let id = sAction.dataGet(data.prefix + '/id');

            if ((/*imei ||*/ serialNo) && itemType) {
                sAction.rest.post('checkUniqueSerial', {
                    serialNo: serialNo,
                    itemType: itemType,
                    producer: {field: producerName, value: producer},
                    id: id
                }, (returnData) => {
                    if (returnData.status === true) {
                        if (returnData.message?.data?.isDuplicate === true) {
                            sAction.error(sAction.translate(returnData.message?.text));
                            sAction.dsClear();
                            sAction.dsAdd('set', data.prefix + '/fields/serial_no/value', null);
                            sAction.dsAdd('set', data.prefix + '/changes/fields/serial_no', null);
                            sAction.dsProcess();
                        }
                    } else {
                        sAction.error(sAction.translate(returnData.errorMessage?.text));
                    }
                });
            }
        }
    }

    checkTidDuplicity(sAction, data) {
        if (data.value && ['not_available', 'free', 'assigned', 'accepted', 'borrow'].includes(sAction.dataGet(data.prefix + '/fields/stage/value')) && ['pay_terminal'].includes(sAction.dataGet(data.prefix + '/fields/item_type/value'))) {
            let id = sAction.dataGet(data.prefix + '/id');
            sAction.rest.post('checkTidDuplicity', {tid: data.value, id: id}, (returnData) => {
                if (returnData.status === true) {
                    if (returnData.message?.data?.isDuplicate === true) {
                        sAction.error(sAction.translate(returnData.message?.text));
                        sAction.dsClear();
                        sAction.dsAdd('set', data.prefix + '/fields/tid/value', null);
                        sAction.dsAdd('set', data.prefix + '/changes/fields/tid', null);
                        sAction.dsProcess();
                    }
                } else {
                    sAction.error(sAction.translate(returnData.errorMessage?.text));
                }
            });
        }
    }

    setName(sAction, data) {
        if (data.value) {
            sAction.dsClear();
            let name = sAction.translateEnum('item_type_list', data.value);
            sAction.dsAdd('set', data.prefix + '/fields/name/value', name);
            sAction.dsAdd('set', data.prefix + '/changes/fields/name', name);
            sAction.dsProcess();
        }
    }

    getFieldsToProcess(sAction, data, fieldConfig) {
        if (sAction.dataGet(data.prefix + 'customData/allFields')) {
            return sAction.dataGet(data.prefix + 'customData/allFields');
        } else {
            let allFields = [];
            fieldConfig.forEachObject((typeData, type) => {
                allFields.push(...typeData.fields);
            });
            sAction.dsAdd('set', data.prefix + '/customData/allFields', allFields);
            return allFields;
        }
    }

    setEnum(sAction, data) {
        let customEnum = sAction.dataGet(`${data.prefix}/customData/item_type_list`);
        if (customEnum) {
            sAction.dsClear();
            sAction.dsAdd('set', `${data.prefix}/fields/item_type/def/customOptions`, customEnum);
            sAction.dsProcess();
        }
    }

    getProducerFieldName(sAction, data, itemType = null) {
        itemType = itemType ?? sAction.dataGet(`${data.prefix}/fields/item_type/value`);
        switch (itemType) {
            case 'sim':
                return 'sim_supplier';
            case 'tablets':
                return 'tablet_producer';
            case 'sensors':
                return 'sensor_producer';
            case 'pay_terminal':
                return 'pay_terminal_producer';
        }
    }

    setCarDefaults(sAction, data) {
        sAction.dsClear();
        // Item_type
        sAction.dsAdd('set', `${data.prefix}/fields/item_type/value`, 'cars_subprovider');
        sAction.dsAdd('set', `${data.prefix}/changes/fields/item_type`, 'cars_subprovider');
        sAction.dsAdd('set', `${data.prefix}/fields/item_type/def/readonly`, true);
        // Stage
        sAction.dsAdd('set', `${data.prefix}/fields/stage/value`, 'accepted');
        sAction.dsAdd('set', `${data.prefix}/changes/fields/stage`, 'accepted');
        sAction.dsAdd('set', `${data.prefix}/fields/stage/def/readonly`, true);
        // Storage
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_acm_storagesacm_storages_ida/def/readonly`, true);
        // Account
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_accounts_name/value`, sAction.dataGet('conf/user/company'));
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_accounts_name/def/id_value`, sAction.dataGet('conf/user/company_id'));
        sAction.dsAdd('set', `${data.prefix}/changes/fields/acm_items_registry_accounts_name`, sAction.dataGet('conf/user/company'));
        sAction.dsAdd('set', `${data.prefix}/changes/fields/acm_items_registry_accountsaccounts_ida`, sAction.dataGet('conf/user/company_id'));
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_accountsaccounts_ida/value`, sAction.dataGet('conf/user/company_id'));
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_accounts_name/def/readonly`, true);
        // Contact
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_contacts_name/value`, sAction.dataGet('conf/user/contact'));
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_contacts_name/def/id_value`, sAction.dataGet('conf/user/contact_id'));
        sAction.dsAdd('set', `${data.prefix}/changes/fields/acm_items_registry_contacts_name`, sAction.dataGet('conf/user/contact'));
        sAction.dsAdd('set', `${data.prefix}/changes/fields/acm_items_registry_contactscontacts_ida`, sAction.dataGet('conf/user/contact_id'));
        sAction.dsAdd('set', `${data.prefix}/fields/acm_items_registry_contactscontacts_ida/def/readonly`, true);
        // NAME
        sAction.dsAdd('set', `${data.prefix}/fields/name/value`, sAction.translateEnum('item_type_list', 'cars_subprovider'));
        sAction.dsAdd('set', `${data.prefix}/changes/fields/name`, sAction.translateEnum('item_type_list', 'cars_subprovider'));
        sAction.dsProcess();
    }

    eliminateVisible(sAction, data) {
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix+'/fields/eliminate/def/visible', false);
        const stage = sAction.dataGet(data.prefix + '/fields/stage/value');
        const buttons = sAction.dataGet(data.prefix + '/buttons');
        let i = 0;
        for (let element of buttons) {
            if (element.id === 'discard' && stage !== 'accepted') {
                sAction.dsAdd("delete", data.prefix + "/buttons", i);
                break;
            }
        i++;
        }
        sAction.dsProcess();
    }
}
