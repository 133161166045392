import React, {PureComponent} from 'react';
import propTypes from 'prop-types';
import {Map} from 'immutable';
import DetailViewFieldsManager from 'ROOT/src/components/detailView/DetailViewFieldsManager/DetailViewFieldsManager';

export default class ReportCheckbox extends PureComponent {
    render() {
        const def = new Map({
            name: this.props.name,
            vname: this.props.vname,
            type: 'bool',
        });

        return (
            <DetailViewFieldsManager
                // data={data}
                way={'report'}
                index={0}
                mode={'report'}
                percent={this.props.width ?? 100}
                onChange={this.props.onChange}
                name={this.props.name}
                value={this.props.value}
                def={def}
                readonly={this.props.readonly}
            />
        );
    }
}

ReportCheckbox.propTypes = {
    onChange: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
    vname: propTypes.string.isRequired,
    value: propTypes.number.isRequired,
    width: propTypes.number,
    required: propTypes.bool,
    readonly: propTypes.bool,
};
