import { ListItemSecondaryAction } from "@material-ui/core";

export default function itemRegRelease(params){

    const stage = this.dataGet(params.prefix+"/fields/stage/value");

    if(stage !== 'free'){
        //pohyb partner -> centrala
        // je treba predvyplnit udaje z predchoziho pohybu

        this.rest.post('getInfoForRelease', {record: params.id, stage}, returnData => {
            if(returnData.status){
                this.popup("itemRegReleasePopup", 
                {
                    header: this.translate("LBL_ITEM_REALEASE_BTN", "acm_items_registry"),
                    data: { 
                        records:  [params.id],
                        disabled: true,
                        account: returnData.message.data.account,
                        contact: returnData.message.data.contact,
                        acm_departments: returnData.message.data.acm_departments,
                        acm_storages: returnData.message.data.acm_storages,
                        callback: () => {
                            window.location.hash = "#list/acm_items_registry";
                        }
                    }
                });
            }
            else{
                this.error(returnData.errorMessage);
            }
        });


        

    }
    else{
        //pohyb centrala -> partner
        this.popup("itemRegReleasePopup", 
        {
            header: this.translate("LBL_ITEM_REALEASE_BTN", "acm_items_registry"),
            data: {records:  [params.id], centralStorage: params.centralStorage}
        });
    }

    
}