export default function logOut() {
// ulozeni dashboardu - predevsim kvuli razeni sloupcu
  if (this.dataGet("conf/view") === "home") {
    this.homeLayoutSave();
  }

  this.rest.post("portalLogoutLog", {}, (resp) => {
    if (this.param.is_portal) {
      localStorage.removeItem('portalLoginAs');
    }
    this.routeLogin()
  }, false);
}