export default function openItemReceptionPopupBulk(params) {

    let filterData = null;
    if(params.selected === 'all'){
        const list = this.dataGet(params.prefix);
        const savedSearch = list.actSavedSearch;
        filterData = this.reloadListParams({
            list,
            prefix: params.prefix,
            savedSearch,
            columns: false,
        });
        filterData["selected"] = list.selected;
        filterData["module"] = list.modul;
        filterData["type"] = list.type;
    }

    this.load();
    this.rest.post("itemRegReceptionCheckState", {selected: params.selected, filter: filterData}, returnData => {
        this.unLoad();
        if(returnData.status){
            this.popup("itemReceptionPopup", 
        {
            records:  returnData.records,
            callback : () => {this.reloadList(0, 1, params.prefix);}
        });
        }
        else{
            this.error(returnData.errorMessage);
        }
    });
    

  };
