import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import SimpleFormFromArray from "../../formElements/SimpleFormFromArray";

export default class ItemMoveToStoragePopup extends PureComponent {
    constructor(props) {
        super(props);
    }

    getFieldsArray() {
        let optionsList = sAction.app_strings?.item_registry_stage_list;
        const options = [
            {
                value: 'not_available',
                label: optionsList['not_available'],
            },
            {
                value: 'free',
                label: optionsList['free'],
            },
        ];
        return {
            storage: {
                type: 'relate',
                module: 'acm_storages',
                label: sAction.translate('LBL_STORAGE', 'acm_items_registry'),
                defaultValue: sAction.dataGet(`${this.props.prefix}/customData/centralStorage`),
                required: true,
            },
            stage: {
                type: 'enum',
                customOptions: options,
                required: true,
                label: sAction.translate('LBL_STAGE_OLD', 'acm_items_registry'),
            }
        };
    }

    getButtonsArray() {
        return {
            back: {
                label: 'LBL_CANCEL',
                onClick: (returnData) => {
                    sAction.popupHide();
                },
                icon: 'icon-undo',
            },
            change: {
                label: 'LBL_MOVE_ITEM_BTN',
                onClick: (returnData) => {
                    sAction.load();
                    let paramData = this.props.params;
                    sAction.rest.post('itemMoveToStorage', {
                        itemID: paramData?.id,
                        storageID: returnData?.storage?.id,
                        stage: returnData?.stage,
                    }, returnData => {
                        sAction.unLoad();
                        if (returnData.status) {
                            sAction.routeDetailView(paramData, {});
                            sAction.popupHide();
                        } else {
                            sAction.error(sAction.translate(returnData.errorMessage?.text));
                        }
                    });
                },
                icon: 'icon-forwardIcon',
            },
        }
    }

    render () {
        let form = (
            <SimpleFormFromArray
                fields={this.getFieldsArray()}
                buttons={this.getButtonsArray()}
            />
        );

        return (
            <>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_RETURN_TO_STORAGE_HEADER')}</div>
                </div>
                <div className='itemForItem'>
                    {form}
                </div>
            </>
        );
    }
}
