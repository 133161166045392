import React from "react";
import PureComponent from "../pure";

import DetailViewField from "./DetailViewField";

import sAction from "sAction";
class DetailViewTabContentRow extends PureComponent {
  getFieldRender(field, key, index, percent) {
    const data = this.props.data;
    const module = this.props.module;
    const id = this.props.id;
    const way = this.props.way;
    const prefix = this.props.prefix;
    const fields = this.props.fields;
    const fieldData = fields.get(field);
    const readonly = this.props.readonly;
    const mode = this.props.mode;

    return (
      <DetailViewField
        prefix={prefix}
        module={module}
        way={prefix + "/fields/" + field}
        percent={percent}
        key={key}
        index={index}
        rowWay={way}
        data={fieldData}
        id={id}
        readonly={readonly}
        acl={this.props.acl}
        mode={mode}
      />
    );
  }

  render() {
    const data = this.props.data;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    const module = this.props.module;
    const fields = this.props.fields;
    const mode = this.props.mode;
    const count = data.size;
    var extraStyle = null;
    var percent = 100;
    if (count != 1) {
      extraStyle = {
        width: 100 / count + "%",
      };
      percent = 100 / count;
    } else {
      extraStyle = {
        width: "50%",
      };
      percent = 50;
    }

    var renderData = [];
    data.forEach((field, key) => {
      const fieldData = fields.get(field);
      if (fieldData != undefined && field != "empty_row") {
        if (fieldData.def.get("type") != "address") {
          const renderField = this.getFieldRender(field, key, key, percent);
          renderData.push(renderField);
        } else {
          const groupName = fieldData.def.get("group");
          const street = this.getFieldRender(
            groupName + "_street",
            key + "_street",
            key,
            100
          );
          const city = this.getFieldRender(
            groupName + "_city",
            key + "_city",
            key,
            100
          );
          const psc = this.getFieldRender(
            groupName + "_postalcode",
            key + "_postalcode",
            key,
            100
          );
          const state = this.getFieldRender(
            groupName + "_state",
            key + "_state",
            key,
            100
          );
          const kraj = this.getFieldRender(
            groupName + "_kraj",
            key + "_kraj",
            key,
            100
          );
          const country = this.getFieldRender(
            groupName + "_country",
            key + "_country",
            key,
            100
          );
          renderData.push(
            <div
              key={groupName}
              className="detailViwRowGroup"
              style={extraStyle}
            >
              <div className="detailViwRowGroupHeader">
                {sAction.translate("LBL_" + groupName.toUpperCase())}
              </div>
              {street}
              {city}
              {psc}
              {state}
              {kraj}
              {country}
            </div>
          );
        }
      } else {
        if (mode == "edit" && field == "empty_row") {
          renderData.push(
            <div className="detailViwRow" style={{ width: "50%" }} key={key}>
              <div className="editChangeContainer">
                <div
                  className="icon-editDetailFieldPosition editDetailFieldPosition"
                  onClick={() =>
                    sAction.editFieldPosition(
                      true,
                      this.props.prefix + "/fields/" + field,
                      this.props.way + "/" + key,
                      this.props.prefix
                    )
                  }
                ></div>
                <label dangerouslySetInnerHTML={{ __html: "" }} />
              </div>
            </div>
          );
        } else {
          renderData.push(
            <div className="detailViwRow" key={key} />
          );
        }
      }
    });
    return (
      <div className="detailViewTabContentRow">
        {renderData}
        {mode == "edit" && (
          <div className="detailViewEditRemoveLineContainer">
            <div
              className="icon-closeIconLight"
              onClick={() =>
                sAction.removeLineFromTab(data, prefix, way, index)
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default DetailViewTabContentRow;
