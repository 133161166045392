import React from "react";
import PureComponent from "../pure";

import FilterVarchar from "./filterFieldsAdvanced/filterVarchar";
import FilterEnum from "./filterFieldsAdvanced/filterEnum";
import FilterRelate from "./filterFieldsAdvanced/filterRelate";
import FilterNumber from "./filterFieldsAdvanced/filterNumber";
import FilterActivity from "./filterFieldsAdvanced/filterActivity";
import FilterDate from "./filterFieldsAdvanced/filterDate";
import FilterParent from "./filterFieldsAdvanced/filterParent";
class ListViewFilterContainer extends PureComponent {
  componentDidMount() {
    var actFilter = this.props.actFilter;
    if (actFilter == null || actFilter.actCustom == false) {
      this.normal();
    } else {
      this.advanced();
    }
  }
  componentDidUpdate() {
    const actFilter = this.props.actFilter;
    if (actFilter == null || actFilter.actCustom == false) {
      this.normal();
    } else {
      this.advanced();
    }
  }
  fixPosition() {
    var container = this.refs.container;
    var parent = this.refs.content.closest(".newListView");
    var parentOffset = parseInt(window.getComputedStyle(container.closest(".newListViewContainer"), null).getPropertyValue('padding-left')) || 0;
    let categoryOffset = 0;
    let categories = parent.parentNode.querySelector(".listViewTableTree");
    if (categories) {
      categoryOffset = parseInt(window.getComputedStyle(categories, null).getPropertyValue('width')) || 0
      parentOffset += categoryOffset;
    }
    var offset = this.props.offset + parentOffset;
    var newLeft = offset - container.clientWidth / 2;

    if (newLeft < 10) {
      newLeft = 10;
    }
    if (newLeft + container.clientWidth > parent.clientWidth) {
      newLeft = parent.clientWidth - container.clientWidth + categoryOffset;
    }

    container.style.left = newLeft + "px";

    const triangle = this.refs.triangle;
    const triangleLeft = offset - newLeft;
    triangle.style.left = triangleLeft - 13 + "px";
    
  }
  advanced() {
    var container = this.refs.container;
    container.style.width = "380px";
    this.fixPosition();
  }
  normal() {
    var container = this.refs.container;
    container.style.width = "200px";
    this.fixPosition();
  }
  render() {
    const def = this.props.def;
    const open = this.props.open;
    const dbType = def.get("dbType");
    const type = def.get("type");
    const actFilter = this.props.actFilter;
    const prefix = this.props.prefix;
    const way = this.props.way;
    const index = this.props.index;
    const fieldRel = this.props.fieldRel;
    var content = null;


    const params = {
      prefix: prefix,
      way: way,
      index: index,
      actFilter: actFilter,
      data: def,
      fieldRel : fieldRel,
      parent: this,
    };

    if (dbType == "varchar" || type == "varchar") {
      content = (
        <FilterVarchar
          {...params}
        />
      );
    } else if (type == "enum" || type == "multienum") {
      content = (
        <FilterEnum
          {...params}
        />
      );
    } else if (type == "relate") {
      content = (
        <FilterRelate
          {...params}
        />
      );
    } else if (type == "currency" || type ==  "int" || type ==  "decimal") {
      content = (
        <FilterNumber
          {...params}
        />
      );
    } else if (type == "icon") {
      content = <FilterActivity 
        {...params}
      />;
    } else if (type == "date" || type == "datetime" ||  type == "datetimecombo" ) {
      content = (
      <FilterDate
        {...params}
      />
      )
    } else if(type == "parent"){
      content = (
        <FilterParent
          {...params}
        />
      );
    }else {
      content = (
        <FilterVarchar
          {...params}
        />
      );
    }

    var containerClass = "listViewFilterContainer";
    if (open == false) {
      containerClass += " hidden";
    }

    return (
      <div ref="container" className={containerClass}>
        <div className="triangle" ref="triangle">
          <div className="triangleInner" />
        </div>
        <div ref="content" tabIndex="0" className="content">
          {content}
        </div>
      </div>
    );
  }
}
export default ListViewFilterContainer;
