import React from "react";
import PureComponent from "../../../pure";
import  sAction  from "sAction";
import SimpleFormFromArray from "../../../formElements/SimpleFormFromArray";

export default class DeclineRequirementPopup extends PureComponent {
    constructor(props) {
        super(props);
    }

    saveRecord(data) {
        sAction.load();
        sAction.rest.post('processPortalRequirement', {id: this.props.params.id, rejectReason: data.reason, stage: 'declined'}, (returnData) => {
            sAction.unLoad();
            sAction.popupHide();
            if (returnData.status) {
                sAction.route();
            } else {
                sAction.error(sAction.translate(returnData.errorMessage?.text));
            }
        });
    }

    render() {
        const buttons = {
            cancel: {
                checkRequired: false,
                onClick: () => {sAction.popupHide();},
                returnButtonName: false,
                label: sAction.translate('LBL_CANCEL'),
            },
            send: {
                checkRequired: true,
                onClick: (data) => {this.saveRecord(data);},
                returnButtonName: false,
                label: sAction.translate('LBL_DECLINE_REQUIREMENT', this.props.params?.module),
            },
        };

        const fields = {
            reason: {
                type: 'textarea',
                required: true,
                label: sAction.translate('LBL_DECLINE_REASON', this.props.params?.module),
            },
        };
        return (
            <SimpleFormFromArray
                buttons={buttons}
                fields={fields}
            />
        );
    }
}
