import $ from 'jquery';
import fullCalendar from 'fullcalendar'
import moment from 'moment';
import  sAction  from "sAction";



export default function calInit(cssSelector, language){
    this.calEvents.date_range.date_start = moment().subtract(40, 'days').format('YYYY-MM-DD');
    this.calEvents.date_range.date_end = moment().add(40, 'days').format('YYYY-MM-DD');
    this.calEvents.cssSelector = cssSelector;
    if(language != undefined){
        this.calEvents.language = language;
    }
    const params = {"start" : this.calEvents.date_range.date_start, "end" : this.calEvents.date_range.date_end, "prefix" : "calendar"};
    this.calGetInitData(params, displayCalContainer);
}

function reschedCallBack(self){
    self.calGetEvents(true);
}

function reschedCallBackDashlet(params){
    console.log("call dashlet refresh", params);
    //self.calActivityDashlet(attrs);
}

export function displayCalContainer(self, forced){

    //todo tady bude asi loader
    let fcPomDate = null;
    if(forced){
        fcPomDate = $(".acmFullCalendar").fullCalendar('getDate');
        self.calEvents.calendars = [];
    }
    
    const cssSelector = self.calEvents.cssSelector;
    $("."+cssSelector).remove();


    const params = self.dataGet("calendar/setting");
    const users = params.get("users");

    $("#calPageContainer").empty();
    var height = 0;

    if(self.calEvents.sharedView){
        self.calEvents.sharedIds.forEach((userId) => {
            const pomId = cssSelector+"_"+userId;
            const userName = users[userId];
            $("#calPageContainer").append($("<div>", {class: "fcCalendarTitle" ,text: userName})).append($("<div>", {class: cssSelector, id : pomId}));
            height = 450;
            displayFcCal(self, pomId, params, {height:height, prefix: "calendar"});       
        });
    }
    else{
        $("#calPageContainer").append($("<div>", {class: cssSelector, id : cssSelector}))
        height = "parent";
        displayFcCal(self, cssSelector, params, {height:height, prefix: "calendar"});
    }

    self.calGetEvents(forced);
    
    if(fcPomDate != null){
        $(".acmFullCalendar").fullCalendar('gotoDate', fcPomDate);
    }


}

export function displayFcCal(self, calId, params, attr){

    const height  = attr.height;
    const language = self.getLanguage("moment");
    const userDateFormat = self.getDateFormat("moment");
    let weeDateFomrmat = "ddd D.M";

    switch (userDateFormat){
        case "Y-M-D":
        case "M-D-Y":
            weeDateFomrmat = "ddd M-D";
            break;
        case "D-M-Y":
            weeDateFomrmat = "ddd D-M";
            break;
        case "Y/M/D":
        case "M/D/Y":
            weeDateFomrmat = "ddd M/D";
            break;
        case "D/M/Y":
            weeDateFomrmat = "ddd D/M";
            break;
        case "Y.M.D":
        case "M.D.Y":
            weeDateFomrmat = "ddd M.D.";
            break;
        case "D.M.Y":
            weeDateFomrmat = "ddd D.M.";
            break;

    }

    attr.self = self;
    let scrollPos = 0;

    $("#"+calId).fullCalendar({
        locale: language,
        height: height,
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'month,agendaWeek,agendaDay,listACM'
        },
        views: {
            month: {
                displayEventTime: true,
                columnHeaderFormat : 'dddd',
            },
            agendaWeek: {
                columnFormat: weeDateFomrmat,
                titleFormat:  "D.MMMM YYYY",
                slotDuration:  "00:15:00",
                slotLabelInterval :  "01:00",
                displayEventTime: true,
            },
            agendaDay: {
                columnFormat: weeDateFomrmat,
                titleFormat:  "D.MMMM YYYY",
                slotDuration:  "00:15:00",
                slotLabelInterval :  "01:00",
            },
            listACM: {
              type: 'list',
              duration: { days: 31 },
              buttonText: 'Agenda',
              titleFormat:  "D.MMMM YYYY"
            }
        },
        defaultView: params.get("initView"),
        editable: true,
        eventStartEditable: true,
        eventDurationEditable: true,
        displayEventTime: false,
        nowIndicator: true,
        weekNumberCalculation: "ISO",
        firstDay : params.get("fdow"),
        weekNumbers : params.get("weekNumbers"),
        weekNumberTitle: self.translate("FC_WEEK_NUMBERS", "Calendar"),
        allDayText: self.translate("FC_ALL_DAY", "Calendar"),
        weekends : params.get("showWeekends"),
        eventTextColor: '#000000',
        eventBorderColor: '#555555',
        timeFormat: 'H:mm',
        slotLabelFormat: 'H:mm',
        minTime: "00:00:00",
        maxTime: "24:00:00",
        scrollTime: params.get("startIntervalHours")+":"+params.get("startIntervalMinutes"),
        scrollToTime: params.get("startIntervalHours")+":"+params.get("startIntervalMinutes"),
        businessHours: {
            dow: [1, 2, 3, 4, 5], // daysOfWeek
            start: params.get("startIntervalHours")+":"+params.get("startIntervalMinutes"), // start time
            end: params.get("endIntervalHours")+":"+params.get("endIntervalMinutes") // end time
        },
        events : [],
        eventClick: function(event) {
          scrollPos = getScrollPos();
          self.displayQuickEdit({module : event.act_type, record : event.id, prefix : attr.prefix});
        },
        dayClick: function( date, jsEvent, view, resourceObj ) {
            scrollPos = getScrollPos();
            const params = {date : date, prefix: attr.prefix};

            if(view.type == "month"){
                const now = moment();
                date.set({hour: now.get('hour'), minute: now.get('minute')});
            }


            setActivityInitData(self, params);
            self.popup("calQuickCreate", {onClose : () => {self.clearCalDetailStore(attr.prefix)}, prefix : attr.prefix, header: sAction.translate("LBL_TITLE_CREATE_NEW_MEETING", "Calendar"), color: "MeetingsCalTitle", icon: "icon-Meetings"});
        },
        dayRender: function (date, element) {

        },
        eventResize: function(event, delta, revertFunc) {
            scrollPos = getScrollPos();
            var fields = {
                "date_start" : event.start.format('YYYY-MM-DD HH:mm:ss'),
                "date_end" : event.end.format('YYYY-MM-DD HH:mm:ss'),
            };
            var params = {
                "module" : event.act_type,
                "checkInvCollision" : true
            }
            if(attr.widget){
                const pomWay = attr.prefix.substr(0, attr.prefix.indexOf('/data/viewData'));
                const attrsDashlet = {
                    action : "refresh",
                    cssSelector : "#dashFC_"+attr.self.dataGet(pomWay+"/data/viewData/dashletId"),
                    way: pomWay
                  };
                self.calSaveActivity(event.id, fields, params, attr.self.calActivityDashlet.bind(attr.self, attrsDashlet), revertFunc);
            }
            else{
                self.calSaveActivity(event.id, fields, params, reschedCallBack, revertFunc);
            }
           
        },
        eventDragStart: function(info) {
            scrollPos = getScrollPos();
        },
        eventDrop: function(event, delta, revertFunc) {
            if(!event.end){
                event.end = event.start;
            }

            var fields = {
                "date_start" : event.start.format('YYYY-MM-DD HH:mm:ss'),
                "date_end" : event.end.format('YYYY-MM-DD HH:mm:ss'),
            };
            var params = {
                "module" : event.act_type,
                "checkInvCollision" : true
            }
            if(attr.widget){
                const pomWay = attr.prefix.substr(0, attr.prefix.indexOf('/data/viewData'));
                const attrsDashlet = {
                    action : "refresh",
                    cssSelector : "#dashFC_"+attr.self.dataGet(pomWay+"/data/viewData/dashletId"),
                    way: pomWay
                  };
                self.calSaveActivity(event.id, fields, params, attr.self.calActivityDashlet.bind(attr.self, attrsDashlet), revertFunc);
            }
            else{
                self.calSaveActivity(event.id, fields, params, reschedCallBack, revertFunc);
            }
            
        },
        eventRender: function (event, element, view) {
        if(event.act_type == "Meetings"){
            element.addClass('eventMeeting');
            element.find(".fc-title").before("<div class='calEventIcon icon-Meetings' />");
            
        }
        else if(event.act_type == "Calls"){
            element.addClass('eventCall');
            element.find(".fc-title").before("<div class='calEventIcon icon-Calls' />");
        }
        else if(event.act_type == "Tasks"){
            element.addClass('eventTask');
            element.find(".fc-title").before("<div class='calEventIcon icon-Tasks' />");
        }
        if (view.type !== "month" && event.description){
            element.find('.fc-title').append('<div class="hr-line-solid-no-margin"></div><span style="font-size: 0.9em">' + event.description + '</span></div>');
        }

        element.find('.fc-title').append(event.htmlBody);

        /*if(
            view.type == "agendaDay" ||
            view.type == "agendaWeek"
        ){
            if(event.start && event.end){
                const timeInfo = event.start.format('HH:mm')+" - " +event.end.format('HH:mm');
                element.find('.fc-title').append($("<div>", {class: "eventTimeFinfo", html: timeInfo}));
            }
        }*/
        if(
            view.type == "listACM"
        ) {
            if(event.start && event.end){
                const timeInfo = event.start.format('HH:mm')+" - " +event.end.format('HH:mm');
                element.find('.fc-list-item-marker').append($("<span>", {class: "eventListTimeFinfo", html: timeInfo}));
            }
        }

        let eventTitle = event.title;

        if(event.start){
            eventTitle += "\n" + event.start.format('DD.MM. HH:mm');
            if(event.end){
                eventTitle += " - "+ event.end.format('DD.MM. HH:mm');
            }
        }

        element[0].title = eventTitle;

        /** !!! Ocicko skryto, ale u nekterych zakazniku se jeste pouziva a u nekterzch budoucich se pouzivat bude, takze nemazat !!!  */
        // var adicon = $('<div />', {class: "icon-Eye", style: "width:15px; height: 15px; float:right; position:relative; z-index:10; margin-right: -2px; margin-top:4px;"})
        //
        // adicon.click(function(ev) {
        //     ev.stopPropagation();
        //     $("#adicon_box").remove();
        //     self.calGetAdditionalInfo(ev, event);
        // });

        if(view.type != "listACM"){
            // element.prepend(adicon);
        }
        else{
            // adicon.css('width', '24px');
            // adicon.css('height', '24px');
            // adicon.css('margin', '5px');
            // element.append(adicon);

            if(event.act_type == "Meetings"){
                element.find("a").prepend("<div class='calEventIcon agendaEvent icon-Meetings' />");
            }
            else if(event.act_type == "Calls"){
                element.find("a").prepend("<div class='calEventIcon agendaEvent icon-Calls' />");
            }
            else if(event.act_type == "Tasks"){
                element.find("a").prepend("<div class='calEventIcon agendaEvent icon-Tasks' />");
            }
        }
        
        element.css("min-height", "20px");

        if(view.type == "agendaDay" && !event.allDay){
            element.css("max-width", "450px");
        }
        else if(view.type == "agendaWeek" && !event.allDay){
            // element.css("max-width", "120px");
        }

        element.addClass("calNoBorder");
        var className = "";
        switch (event.status){
            case 'Planned':
            case 'Not Started':
                className = 'eventNotStarted';
                break;
            case 'Pending Input':
                className = 'eventPending';
                break;
            case 'Held':
            case 'Completed':
                className = 'eventSuccess';
                break;
            case 'Not Held':
            case 'Deferred':
                className = 'eventFailed';
                break;
            default:
                className = 'eventInProgress';
                break;
        }
        element.addClass(className);
        
        element.on("contextmenu",function(e){
            e.stopPropagation();
            const origin = {
               left: e.pageX,
               top: e.pageY
            };
            displayContextMenu("EVENTMENU", {origin: origin, event: event, prefix: attr.prefix, widget: attr.widget});
            return false;
         }); 
    },
    eventAfterRender: function(event, element, view){
        var asd = $('.fc-scroller');
        if (scrollPos !== 0) {
            asd.scrollTop(scrollPos)
        }
    },
    viewRender: function(view, element){
        self.setCalendarTitle(attr.prefix, "#"+calId);

        if(view.type == "listACM") {
            element.find('.fc-list-empty').empty().append(self.translate('LBL_NOTHING_TO_DISPLAY', "Calendar"));
        }
    }
  });

    function getScrollPos(){
        // console.log('-------------getScrollPos()')
        return document.getElementsByClassName('fc-scroller')[0].scrollTop;
    }
  const calElem = $("#"+calId);

    /*
    TTT
    calElem.on("contextmenu",function(e){
      const timeEl = $(e.target).closest('.fc-bg, .fc-slats, .fc-content-skeleton, ' + '.fc-bgevent-skeleton, .fc-highlight-skeleton');
      if(timeEl.length){
          const fcVIew = calElem.fullCalendar('getView');

          fcVIew.prepareHits();
          var hit = fcVIew.queryHit(e.pageX, e.pageY);
          if(hit != undefined){
              let cell = hit.component.getSafeHitFootprint(hit);
              if(cell != undefined){
                  e.stopPropagation();
                  const origin = {
                      left: e.pageX,
                      top: e.pageY
                   };
                  displayContextMenu("DAYMENU", {origin: origin, date: moment(cell.unzonedRange.startMs)});

              }
          }
      }
      return false;
   });
   */

 $("body").on("click", function () { $(".acmFcContextMenu").remove();});

    $( document ).ready(function() {
        calElem.find('.fc-list-empty').empty().append(self.translate('LBL_NOTHING_TO_DISPLAY', "Calendar"));
    });
}

function setActivityInitData(self, params){

    const start = params.date.format("YYYY-MM-DD HH:mm:ss");
    const end = params.date.add(15, 'minutes').format("YYYY-MM-DD HH:mm:ss");
    
    self.saveField({way: params.prefix+"/meetingView/fields/date_start", name: "date_start", value: start}, true);
    self.saveField({way: params.prefix+"/meetingView/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
    self.saveField({way: params.prefix+"/meetingView/fields/date_end", name: "date_end", value: end}, true);

    self.saveField({way: params.prefix+"/callView/fields/date_start", name: "date_start", value: start}, true);
    self.saveField({way: params.prefix+"/callView/fields/duration_hours", name: "duration_hours", value: "0", valueMinutes: 15, type:"hourseMinutes"}, true);
    self.saveField({way: params.prefix+"/callView/fields/date_end", name: "date_end", value: end}, true);

    self.saveField({way: params.prefix+"/taskView/fields/date_start", name: "date_start", value: start}, true);
    self.saveField({way: params.prefix+"/taskView/fields/date_due", name: "date_due", value: end}, true);

   self.dsClear();
    let action = {
        type: "CALENDARINITACTIVITY",
        content: {invitees : {Users: [], Contacts: [], Leads : []}},
        prefix: params.prefix+"/invitees/relatedInv"
    }
    self.dsAddCustom(action);
    self.dsProcess();

}

function contextHandle(params){

    switch(params.type){
        case "EDIT":
            this.displayQuickEdit(params);
            break;
        case "DELETE":
            this.calActivityHelper(params)
        case "CANCEL":
            break;
        case "CHANGESTATE":
            if(params.widget){
                const pomWay = params.prefix.substr(0, params.prefix.indexOf('/data/viewData'));
                const attrsDashlet = {
                    action : "refresh",
                    cssSelector : "#dashFC_"+this.dataGet(pomWay+"/data/viewData/dashletId"),
                    way: pomWay
                };
                this.calSaveActivity(params.id, {"status" : params.value}, {module: params.act_type}, this.calActivityDashlet.bind(this, attrsDashlet));
            }
            else{
                this.calSaveActivity(params.id, {"status" : params.value}, {module: params.act_type}, reschedCallBack);
            }
            break;
        case "CREATE":
            this.calActivityHelper({type:"CREATEACTIVITY", module:params.module, date: params.date});
            break;
        default:
            console.log("contextHandle : Unknown action.");
    }

    $(".acmFcContextMenu").remove();
}

function displayContextMenu(type, params){
    $(".acmFcContextMenu").remove();
    const origin = params.origin;
    const prefix = params.prefix;

    let menuOpt = $("<ul>", {class: "menu-options"});
    let menu = $("<div>", {class: "acmFcContextMenu", style: "top:"+origin.top+"px; left: "+origin.left+"px;"});
    /*menu.click(function(ev) {
        ev.stopPropagation()
    });*/
    let options = [];

    if(type == "DAYMENU"){

        const date = params.date;
        options = [{
            label : this.translate("LBL_TITLE_CREATE_NEW_MEETING", "Calendar"), 
            onClick : contextHandle, 
            params: {type : "CREATE", module:"Meetings", date:date},
            submenu : []
        },
        {
            label : this.translate("LBL_TITLE_CREATE_NEW_CALL", "Calendar"), 
            onClick : contextHandle, 
            params: {type : "CREATE", module:"Calls", date:date},
            submenu : []
        },
        {
            label : this.translate("LBL_TITLE_CREATE_NEW_TASK", "Calendar"), 
            onClick : contextHandle, 
            params: {type : "CREATE", module:"Tasks", date:date},
            submenu : []
        },
        {
            label: this.app_strings['LBL_ROUTING_BUTTON_CANCEL'],
            onClick : contextHandle, 
            params: {type : "CANCEL"},
            submenu : []
        }
        ];
    }
    else if(type == "EVENTMENU"){
        const event = params.event;
        let changeStateOptions = [];
        let changeStateArr = {};
        if(event.act_type == "Meetings"){
            changeStateArr = this.app_strings.meeting_status_dom;
        }
        else if(event.act_type == "Calls"){
            changeStateArr = this.app_strings.call_status_dom;
        }
        else if(event.act_type == "Tasks"){
            changeStateArr = this.app_strings.task_status_dom;
        }
    
        changeStateArr.forEachObject((lbl, key) => {
            changeStateOptions.push({label: lbl, onClick: contextHandle, params : {type: "CHANGESTATE", act_type:event.act_type, id:event.id, value : key, widget: params.widget, prefix: params.prefix}});
        });

        let pomDeletePrefix = params.prefix;

        if(params.widget){
            pomDeletePrefix = pomDeletePrefix.substr(0, pomDeletePrefix.indexOf('/data/viewData'));
        }

    
        options = [{
            label : this.app_strings["LBL_EDIT_BUTTON"], 
            onClick : contextHandle, 
            params: {type : "EDIT", module:event.act_type, record:event.id, prefix: prefix },
            submenu : []
        },
        {
            label: this.translate("LBL_CHANGE_ACTIVITY_STATE", "Calendar"),
            onClick : contextHandle, 
            params: {type : "", module:event.act_type, id:event.id},
            submenu : changeStateOptions
        },
        {
            label: this.app_strings['LBL_DELETE_RECORD'],
            onClick : contextHandle, 
            params: {type : "DELETE", module:event.act_type, id:event.id, prefix: pomDeletePrefix},
            submenu : []
        },
        {
            label: this.app_strings['LBL_ROUTING_BUTTON_CANCEL'],
            onClick : contextHandle, 
            params: {type : "CANCEL"},
            submenu : []
        }
        ];
    }

    options.forEach((opt, ind) => {
        let item = $("<li>", {class:"menu-option", html: opt.label});
        item.click(function (ev) {ev.stopPropagation(); opt.onClick(opt.params)});
        if(opt.submenu.length > 0){
            item.addClass("submenu");
            item.append($("<div>", {class: "icon-arrowRight submenuIcon"}));
            let submenu = $("<div>", {class: "acmFcContextMenu", style: "top:"+(origin.top + 10 + (ind * 24 ))+"px; left:"+(origin.left + 180)+"px;"});
            let submenuOptions = $("<ul>", {class: "menu-options"});
            opt.submenu.forEach((subOpt) => {
                let subItem = $("<li>", {class:"menu-option", html: subOpt.label});
                subItem.click(function (ev) {ev.stopPropagation(); subOpt.onClick(subOpt.params)});
                submenuOptions.append(subItem);
            });
            
            submenu.append(submenuOptions);
            submenu.append($("<ul>", {class: "menu-options"}));
            item.append(submenu);

        }
        menuOpt.append(item);
    });

    menu.append(menuOpt);


    $("body").append(menu);

}
