export default function displayAcmMessages(messages){

    if(messages.length > 0){
        let message = messages.pop();

        message.onClose = () => {this.displayAcmMessages(messages)};
        this.popup("portalMessage", {
            header: message.name, 
            data: message,
            closeFromBc : false,
            onClose: () => {this.displayAcmMessages(messages)}
        });

        //this.displayAcmMessages(messages);
    }

}
