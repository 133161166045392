import React from "react";
import PureComponent from "../../pure";

import InputText from "../../formElements/InputText";
import Select from "../../formElements/Select";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";
import AcmDateTime from "../../formElements/AcmDatetime";
import CheckBox from "../../formElements/CheckBox";

import sAction from "sAction";

export default class EditFieldsNewField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: props.data.get("name") ?? "",
      nameError: false,
      type: props.data.get("type") ?? "",
      typeError: false,
      required: props.data.get("required") ?? 0,
      requiredError: false,
      defaultValue: props.data.get("default") ?? "",
      help: props.data.get("help") ?? "",
      readonly: props.data.get("readonly") ?? 0,
      massupdate: props.data.get("massupdate") ?? 1,
      unified_search: props.data.get("unified_search") ?? 1,
      min: props.data.get("min") ?? 0,
      max: props.data.get("max") ?? "",
    };
  }
  getDefaultValueForm(type) {
    const data = this.props.data;
    if (type == "enum") {
      var selectOptions = sAction.getEnumForSelect(data.get("selectOption"));
      return (
        <Select
          containerClassName="withBorder"
          onChange={(e) => this.setState({ defaultValue: e.target.value })}
          value={this.state.defaultValue}
          options={selectOptions}
        />
      );
    }
    if (type == "date") {
      return (
        <AcmDate
          className="withBorder"
          value={this.state.defaultValue}
          onChange={(date) => this.setState({ defaultValue: date })}
        />
      );
    } else if (type == "datetime") {
      return (
        <AcmDateTime
          className="withBorder"
          value={this.state.defaultValue}
          onChange={(date) => this.setState({ defaultValue: date })}
        />
      );
    } else if (type == "currency_id") {
      var optionsToSelect = [];
      sAction.currencies.forEachObject((value, key) => {
        optionsToSelect.push({
          value: key,
          label: value["name"] + " (" + value["symbol"] + ")",
        });
      });
      return (
        <Select
          containerClassName="withBorder"
          onChange={(e) => this.setState({ defaultValue: e.target.value })}
          value={this.state.defaultValue}
          options={optionsToSelect}
        />
      );
    }else if(type == "bool"){
      return (<CheckBox defaultValue={this.state.defaultValue == "" ? 0 : this.state.defaultValue} 
      onChange={(e) => e == true ? this.setState({defaultValue: 1}) : this.setState({defaultValue: 0})} />)
    }else if(type == "image"){
      return (<div />)
    }

    return (
      <InputText
        className="withBorder"
        value={this.state.defaultValue}
        //  error={this.state.defaultValue}
        onChange={(e) => this.setState({ defaultValue: e.target.value })}
      />
    );
  }
  onCreate() {
    const data = this.props.data;
    const name = this.state.name;
    const type = this.state.type;
    const options = data.get("selectOption");
    const required = this.state.required;
    const newState = {};

    var state = true;

    if (name == "") {
      newState.nameError = true;
      state = false;
    } else {
      newState.nameError = false;
    }
    if (type == "") {
      newState.typeError = true;
      state = false;
    } else {
      newState.typeError = false;
    }
    if (type == "enum" || type == "multienum") {
      if (options == "") {
        state = false;
      }
    }

    if (required === "") {
      newState.requiredError = true;
      state = false;
    } else {
      newState.requiredError = false;
    }
    this.setState(newState);

    if (state) {
      const params = {
        name,
        type,
        required: required == 1 ? true : false,
        module: data.get("module"),
        default: this.state.defaultValue,
        readonly: this.state.readonly == 1 ? true : false,
        massupdate: this.state.massupdate == 1 ? true : false,
        unified_search: this.state.unified_search == 1 ? true : false,
        help: this.state.help,
      };

      if (type == "enum" || type == "multienum") {
        params["options"] = options;
      }
      if (type == "int" && this.state.min != null) {
        params["min"] = this.state.min;
      }
      if (type == "int" && this.state.max != null) {
        params["max"] = this.state.max;
      }

      if (data.get("new") === false) {
        params["new"] = false;
      }

      sAction.editFieldCreateField(data.get("way"), params);
    }
  }
  addNewEnum() {
    sAction.dsClear();
    sAction.dsAdd("set", "conf/popup/data/view", "enum");
    sAction.dsAdd("set", "conf/popup/data/selectOption", "");
    sAction.dsAdd("set", "conf/popup/data/lockOptionKey", false);
    sAction.dsAdd("set", "conf/popup/data/optionList", []);
    sAction.dsProcess();
  }
  editEnum() {
    const data = this.props.data;
    const selectOption = data.get("selectOption");

    const options = sAction.app_strings[selectOption];
    sAction.dataSet("conf/popup/data/optionList", []);
    sAction.dsClear();
    options.forEachObject((value, key) => {
      const line = {
        label: value,
        value: key,
      };
      sAction.dsAdd("add", "conf/popup/data/optionList", line);
    });
    sAction.dsAdd("set", "conf/popup/data/lockOptionKey", true);
    sAction.dsAdd("set", "conf/popup/data/view", "enum");
    sAction.dsProcess();
  }

  render() {
    const data = this.props.data;
    const way = data.get("way");

    const typeSelect = [
      { value: "enum", label: sAction.translate("LBL_NEW_FILED_TYPE_ENUM") },
      { value: "int", label: sAction.translate("LBL_NEW_FILED_TYPE_NUMBER") },
      { value: "date", label: sAction.translate("LBL_NEW_FILED_TYPE_DATE") },
      {
        value: "datetime",
        label: sAction.translate("LBL_NEW_FILED_TYPE_DATETIME"),
      },
      {
        value: "double",
        label: sAction.translate("LBL_NEW_FILED_TYPE_DECIMAL"),
      },
      {
        value: "currency",
        label: sAction.translate("LBL_NEW_FILED_TYPE_CURRENCY"),
      },
      {
        value: "currency_id",
        label: sAction.translate("LBL_NEW_FILED_TYPE_CURRENCY_ID"),
      },
      {
        value: "multienum",
        label: sAction.translate("LBL_NEW_FILED_TYPE_MULTIENUM"),
      },
      {
        value: "text",
        label: sAction.translate("LBL_NEW_FILED_TYPE_TEXTAREA"),
      },
      { value: "url", label: sAction.translate("LBL_NEW_FILED_TYPE_HREF") },
      { value: "phone", label: sAction.translate("LBL_NEW_FILED_TYPE_PHONE") },
      { value: "varchar", label: sAction.translate("LBL_NEW_FILED_TYPE_TEXT") },
      { value: "bool" , label: sAction.translate("LBL_NEW_FILED_TYPE_CHECKBOX")},
      { value: "image" , label: sAction.translate("LBL_NEW_FILED_TYPE_IMAGE")}

    ];
    const requiredSelect = [
      { value: 0, label: sAction.translate("LBL_NEW_FIELD_NO") },
      { value: 1, label: sAction.translate("LBL_NEW_FIELD_YES") },
    ];
    return (
      <div className="simpleForm">
        <table>
          <tbody>
            <tr>
              <td className="formlabel">
                {data.get("new") === false
                  ? sAction.translate("LBL_NEW_FIELD_KEY")
                  : sAction.translate("LBL_NEW_FIELD_NAME")}
              </td>
              <td>
                <InputText
                  className="withBorder"
                  error={this.state.nameError}
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  disabled={data.get("new") === false ? true : false}
                />
              </td>
            </tr>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_TYPE")}
              </td>
              <td>
                <Select
                  error={this.state.typeError}
                  onChange={(e) => this.setState({ type: e.target.value })}
                  options={typeSelect}
                  containerClassName="withBorder"
                  value={this.state.type}
                  disabled={data.get("new") === false ? true : false}
                />
              </td>
            </tr>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_REQUIRED")}
              </td>
              <td>
                <Select
                  error={this.state.requiredError}
                  onChange={(e) => this.setState({ required: e.target.value })}
                  value={this.state.required}
                  options={requiredSelect}
                  containerClassName="withBorder"
                />
              </td>
            </tr>
            {(this.state.type == "enum" || this.state.type == "multienum") && (
              <tr>
                <td className="formlabel">
                  {sAction.translate("LBL_NEW_FIELD_OPTION")}
                </td>
                <td>
                  <Select
                    error={this.state.typeError}
                    value={data.get("selectOption")}
                    onChange={(e) =>
                      sAction.dataSet(
                        "conf/popup/data/selectOption",
                        e.target.value
                      )
                    }
                    options={sAction.temporaryData.optionsList}
                    containerClassName="withBorder"
                  />
                  <div className="flexInLine flexRight">
                    <Button onClick={() => this.addNewEnum()}>{"Nový"}</Button>
                    {data.get("selectOption") && (
                      <Button onClick={() => this.editEnum()}>
                        {"Upravit"}
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            )}
            {this.state.type == "int" && (
              <>
                <tr>
                  <td className="formlabel">
                    {sAction.translate("LBL_NEW_FIELD_MIN")}
                  </td>
                  <td>
                    <InputText
                      className="withBorder"
                      onChange={(e) => this.setState({ min: e.target.value })}
                      value={this.state.min ?? 0}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="formlabel">
                    {sAction.translate("LBL_NEW_FIELD_MAX")}
                  </td>
                  <td>
                    <InputText
                      className="withBorder"
                      onChange={(e) => this.setState({ max: e.target.value })}
                      value={this.state.max}
                    />
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_DEFAULT_VALUE")}
              </td>
              <td>{this.getDefaultValueForm(this.state.type)}</td>
            </tr>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_HELP")}
              </td>
              <td>
                <InputText
                  className="withBorder"
                  //  error={this.state.defaultValue}
                  value={this.state.help}
                  onChange={(e) => this.setState({ help: e.target.value })}
                />
              </td>
            </tr>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_READONLY")}
              </td>
              <td>
                <Select
                  onChange={(e) => this.setState({ readonly: e.target.value })}
                  value={this.state.readonly}
                  options={requiredSelect}
                  containerClassName="withBorder"
                />
              </td>
            </tr>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_MASS_UPDATE")}
              </td>
              <td>
                <Select
                  onChange={(e) =>
                    this.setState({ massupdate: e.target.value })
                  }
                  value={this.state.massupdate}
                  options={requiredSelect}
                  containerClassName="withBorder"
                />
              </td>
            </tr>
            <tr>
              <td className="formlabel">
                {sAction.translate("LBL_NEW_FIELD_SEARCH")}
              </td>
              <td>
                <Select
                  error={this.state.searchFieldError}
                  onChange={(e) =>
                    this.setState({ unified_search: e.target.value })
                  }
                  value={this.state.unified_search}
                  options={requiredSelect}
                  containerClassName="withBorder"
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="viewActionPanelButtons flexRight">
                  <Button
                    onClick={() => sAction.popupHide()}
                    className="popupCloseButton"
                  >
                    <div
                      className={"actionPanelButtonIcon icon-closeIconLight"}
                    />
                    {sAction.translate("LBL_EXPORT_CLOSE")}
                  </Button>
                  <Button onClick={() => this.onCreate()}>
                    <div className={"actionPanelButtonIcon icon-download"} />
                    {data.get("new") === false
                      ? sAction.translate("LBL_CREATE_NEW_EDIT")
                      : sAction.translate("LBL_CREATE_NEW_CREATE")}
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
