import detailDefault from "../../detailDefault";

export default class detailacm_servis extends detailDefault {
    load(sAction, data) {
        let stage = sAction.dataGet(data.prefix+"/fields/stage/value");

        if(stage == "finished") {
            data.value = stage;
            this.setResultRequired(sAction, data);
        }
    }

    update(sAction, data) {

        switch (data.field){
            case "stage":
                this.setResultRequired(sAction, data);
        }

    }

    setResultRequired(sAction, data){
        sAction.dsClear();
        if(data.value == "finished"){
            sAction.dsAdd("set",  data.prefix+"/fields/service_result/def/required", true);
        }
        else{
            sAction.dsAdd("set",  data.prefix+"/fields/service_result/def/required", false);
        }
        sAction.dsProcess();

    }

}