import React from 'react';
import PropTypes from 'prop-types';
import PasswordStrengthBar from 'react-password-strength-bar';

import PureComponent from '../../pure';
import sAction from 'sAction';
import InputText from '../../formElements/InputText';
import Button from '../../formElements/Button';
import PasswordRequirementsPopover from '../../login/PasswordRequirementsPopover';
import PasswordRequirements from '../../login/PasswordRequirements';

export default class ChangePasswordPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            token: false,
            error: false,
            message: '',
            passOld: true,
            passNew: true,
            passAgain: true,
            passOldMessage: '',
            passNewMessage: '',
            passAgainMessage: '',
            minLength: 0,
            maxLength: 0,
            extraRequirements: [],
            showRequirements: false,
            password: '',
        };
        this.passOld = React.createRef();
        this.passNew = React.createRef();
        this.passAgain = React.createRef();
    }

    /**
     * handle send on enter
     * @param {KeyboardEvent} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13 && !this.state.message) {
            this.changePassword();
        }
    }

    /**
     * handle reset password
     * @param {boolean} force
     */
    changePassword(force = false) {
        const passNew = this.passNew.current.value;
        const passAgain = this.passAgain.current.value;
        const passOld = this.passOld.current.value;

        this.setState({
            passOld: Boolean(passOld),
            passNew: Boolean(passNew),
            passAgain: Boolean(passAgain),
            passMessage: '',
            passAgainMessage: '',
        });

        if (!passOld || !passNew) {
            return;
        }

        if (!sAction.checkPassword(passNew, this.state.minLength, this.state.maxLength, this.state.extraRequirements)) {
            this.setState({
                passNew: false,
                passNewMessage: <PasswordRequirements {...this.state}/>,
            });
            return;
        }

        if (passNew !== passAgain) {
            this.setState({
                passAgain: false,
                passAgainMessage: 'LBL_PASSWORDS_DO_NOT_MATCH',
            });
            return;
        }

        const urlArray = window.location.hash.split('/');
        const self = this;
        sAction.changePasswordRequest(passNew, passOld, urlArray[2], (data) => {
            if (data.status) {
                self.setState({message: data.message, error: false});
            } else if (data.field) {
                const returnData = {
                    passNew: Boolean(passNew),
                    passNewMessage: '',
                    passAgain: Boolean(passAgain),
                    passAgainMessage: '',
                    passOld: Boolean(passOld),
                    passOldMessage: '',
                };
                returnData[data.field] = false;
                returnData[data.field + 'Message'] = data.message;
                self.setState(returnData);
                // self.setState({ [data.field]: false, [data.field + 'Message']: data.message });
            } else if (data.errors) {
                self.setState({error: true, passNewMessage: data.errors.map((error) => sAction.translate(error)).join('\n')});
            } else if (data.ask) {
                sAction.confrim(data.message, () => {
                    self.changePassword(true);
                    sAction.popupHide();
                }, 'red');
            } else {
                self.setState({error: true, message: data.message});
            }
        }, force);
    }

    render() {
        let render = '';
        if (!this.state.message) {
            render = this.getForm();
        } else {
            render = this.getMessage();
        }

        if (!this.state.token) {
            sAction.rest.fetchData('passwordRequirements', 'GET').then((res) => {
                this.setState({
                    minLength: res.data.length.min,
                    maxLength: res.data.length.max,
                    extraRequirements: res.data.extra,
                });
            });
            this.setState({token: true});
        }

        return (
            <React.Fragment>
                <div style={{overflow: 'hidden', height: '0px'}}>
                    <InputText
                        type='text'
                        name='nickname'
                        style={{opacity: '0'}}
                    />
                    <InputText
                        type='password'
                        name='password'
                        style={{opacity: '0'}}
                    />
                </div>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_CHANGE_PASSWORD')}</div>
                </div>
                <div className='exportListPopupContent'>
                    <div className='simpleForm'>
                        {render}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    /**
     * render message from backend
     * @returns {JSX.Element}
     */
    getMessage() {
        return (
            <React.Fragment>
                <table onKeyDown={(e) => this.onKeyDown(e)}>
                    <tbody>
                        <tr>
                            <td colSpan="2" className='formlabel'>
                                <div className={this.state.error ? 'flexCenter error' : 'flexCenter'}>
                                    {sAction.translate(this.state.message)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    /**
     * render form body
     * @returns {JSX.Element}
     */
    getForm() {
        const {password, minLength, maxLength, extraRequirements} = this.state;
        return (
            <React.Fragment>
                <table onKeyDown={(e) => this.onKeyDown(e)}>
                    <tbody>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate('LBL_OLD_PASSWORD')}
                            </td>
                            <td>
                                {this.state.passOldMessage ? this.getErrorMessage(this.state.passOldMessage) : null}
                                <InputText
                                    type="password"
                                    error={!this.state.passOld}
                                    myRef={this.passOld}
                                    className="withBorder"
                                    autoFocus={true}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate('LBL_NEW_PASSWORD')}
                                <PasswordRequirementsPopover {...{minLength, maxLength, extraRequirements}}/>
                            </td>
                            <td>
                                {this.state.passNewMessage ? this.getErrorMessage(this.state.passNewMessage) : null}
                                <InputText
                                    type="password"
                                    error={!this.state.passNew}
                                    myRef={this.passNew}
                                    className="withBorder"
                                    onFocus={() => {this.setState({showRequirements: true})}}
                                    onBlur={() => {this.setState({showRequirements: false})}}
                                    onChange={(e) => {this.setState({password: e.target.value})}}
                                />
                                {this.state.showRequirements && !sAction.checkPassword(password, minLength, maxLength, extraRequirements) &&
                                    <div className='passwordRequirements' style={{width: '287px'}}>
                                        <PasswordRequirements {...{minLength, maxLength, extraRequirements, password}}/>
                                    </div>
                                }
                                <PasswordStrengthBar
                                    password={password}
                                    minLength={minLength}
                                    scoreWords={
                                        [
                                            'LBL_PASSWORD_VULNERABLE',
                                            'LBL_PASSWORD_WEAK',
                                            'LBL_PASSWORD_OKAY',
                                            'LBL_PASSWORD_GOOD',
                                            'LBL_PASSWORD_STRONG',
                                        ].map((word) => sAction.translate(word))
                                    }
                                    shortScoreWord={sAction.translate('LBL_PASSWORD_TOO_SHORT')}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="formlabel">
                                {sAction.translate('LBL_NEW_PASSWORD_AGAIN')}
                            </td>
                            <td>
                                {this.state.passAgainMessage ? this.getErrorMessage(this.state.passAgainMessage) : null}
                                <InputText
                                    type="password"
                                    error={!this.state.passAgain}
                                    myRef={this.passAgain}
                                    className="withBorder"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <div className="viewActionPanelButtons flexRight">
                                    <Button onClick={() => this.props.close()} className='popupCloseButton'>
                                        <div className={'actionPanelButtonIcon icon-closeIconLight'} />
                                        {sAction.translate('LBL_EXPORT_CLOSE')}
                                    </Button>
                                    <Button onClick={() => this.changePassword()}>
                                        <div className={'actionPanelButtonIcon icon-editDesc'} />
                                        {sAction.translate('LBL_SET_NEW_PASSWORD')}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }

    /**
     * render error message
     * @param {string} message error message
     * @returns {JSX.Element}
     */
    getErrorMessage(message) {
        return (
            <div className='error'>
                {sAction.translate(message)}
            </div>
        );
    }
}

ChangePasswordPopup.propTypes = {
    close: PropTypes.func.isRequired,
};
