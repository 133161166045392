import detailDefault from "../../detailDefault";

export default class detailDocuments extends detailDefault {
  load(sAction, data) {
    const id = sAction.dataGet(data.prefix + "/id");
    if (id) {
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/filename_helper/def/readonly", true);
      sAction.dsProcess();
    }
    
    let category = sAction.dataGet(data.prefix + "/fields/acm_documentscategory_documents_name/value");
    this.showHideFields(sAction, data, category);
  }
  
  update(sAction, data) {
    if (data.field == "filename_helper") {
      if (data.value.name) {
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/document_name/value", data.value.name);
        sAction.dsAdd("set", data.prefix + "/changes/fields/document_name", data.value.name);
        sAction.dsProcess();
      }
    } else if (data.field === "acm_documentscategory_documentsacm_documentscategory_ida") {
      this.showHideFields(sAction, data, data.value.name);
    }
  }
  
  showHideFields(sAction, data, category) {
    sAction.dsClear();
    
    sAction.dsAdd("set", data.prefix + "/fields/link/def/visible", category === "Videa");
    sAction.dsAdd("set", data.prefix + "/fields/link_youtube/def/visible", category === "Videa");
    
    sAction.dsAdd("set", data.prefix + "/fields/valid_from/def/visible", category === "Ceníky");
    sAction.dsAdd("set", data.prefix + "/fields/valid_from/def/required", category === "Ceníky");
    sAction.dsAdd("set", data.prefix + "/fields/valid_to/def/visible", category === "Ceníky");
    
    sAction.dsProcess();
  }
  
}
